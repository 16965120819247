import React, { memo, useCallback, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import {
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
} from "../../MissionDetails/styles";

import { closeAnyModal } from "Slices/modals";
import { fetchDeployMissionPlan } from "Slices/missionPlans";
import { getStopsByCurrentSemanticMap } from "Slices/stops";
import { getCurrentFleetId } from "Slices/missionPlanner";
import { DeployedMissionList } from "../../DeployedMissionList";
import { getCurrentSemanticMapId } from "Slices/appState";

export const DeployedMissionListModal = memo(() => {
  const [editData, setEditData] = useState(null);
  const dispatch = useDispatch();
  const stops = useSelector(getStopsByCurrentSemanticMap);
  const currentFleetId = useSelector(getCurrentFleetId);

  const semanticMapId = useSelector(getCurrentSemanticMapId);

  // Check currentFleetId existence before component rendered
  useLayoutEffect(() => {
    if (currentFleetId) return;

    toast.error("Please specify particular fleet before create new mission!");
    handleCloseModal();
  }, [currentFleetId]);

  const handleDeployPlanMissionAction = useCallback(
    (data: { id: string }) => {
      if (!currentFleetId) return;

      dispatch(
        fetchDeployMissionPlan({
          ...data,
          currentFleetId,
        })
      );
    },
    [currentFleetId]
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <MissionPlannerContainerStyled>
        <MissionPlannerContentStyled>
          <DeployedMissionList
            vehicleName={null}
            editData={editData}
            setEditData={setEditData}
            stops={stops}
            vehicleId={null}
            semanticMapId={semanticMapId}
            activeTab={"savedMission"}
            setActiveTab={null}
            deployPlanMissionAction={handleDeployPlanMissionAction}
            cancelAction={handleCloseModal}
            previousMode="Continue"
          />
        </MissionPlannerContentStyled>
      </MissionPlannerContainerStyled>
    </ModalStyled>
  );
});
