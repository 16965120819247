import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

import API, { showNetworkErrorToast } from "Utils/api";

import { RowData } from "Components/NewList/types";
import {
  reorderMissionsQueue,
  setIsMissionPlannerUpdating,
  setMissionCounts,
  setMissionsQueue,
} from ".";

let controller: AbortController | null = null;

export const fetchGetMissionQueue = createAsyncThunk<
  void,
  {
    currentFleetId: string;
    period: MissionPlannerPeriodFilter;
    limit: number;
    searchValue: string;
    activeTab: MissionPlannerTabName;
    selectedVehicleIds: Array<string>;
    countersOnly?: boolean;
  }
>(
  "missionQueue",
  async (
    data: {
      currentFleetId: string;
      period: MissionPlannerPeriodFilter;
      limit: number;
      searchValue: string;
      activeTab: MissionPlannerTabName;
      selectedVehicleIds: Array<string>;
      countersOnly?: boolean;
    },
    thunkAPI
  ) => {
    if (controller) {
      controller.abort();
      controller = null;
    }
    const queryStatus =
      data.activeTab === "All" ? "" : `&status=${data.activeTab.toLowerCase()}`;

    const vehicleIds =
      data.selectedVehicleIds.length > 0
        ? `&vehicle_id__in=${encodeURIComponent(
            data.selectedVehicleIds.toString()
          )}`
        : "";
    const querySearch = `&name=${data.searchValue}`;
    const startDate = `&created_start=${data.period.startDate}`;
    const endDate = `&created_end=${data.period.endDate}`;
    const limit = `&limit=${data.limit}`;
    if (!data.countersOnly) {
      thunkAPI.dispatch(setIsMissionPlannerUpdating(true));
    }
    try {
      const queryParameters = `?fleet_ids=${data.currentFleetId}${queryStatus}${vehicleIds}${startDate}${endDate}${querySearch}${limit}`;
      controller = new AbortController();
      const response = await API.get(
        `fleets/mission_queue/${queryParameters}`,
        {
          signal: controller.signal,
        }
      );
      controller = null;
      thunkAPI.dispatch(setMissionCounts(response.data));
      !data.countersOnly &&
        thunkAPI.dispatch(setMissionsQueue(response.data.results));
      await thunkAPI.dispatch(setIsMissionPlannerUpdating(false));
    } catch (error) {
      await thunkAPI.dispatch(setIsMissionPlannerUpdating(false));
      if (axios.isCancel(error)) return;
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const cancelMission = createAsyncThunk<
  void,
  { missionId: string; vehicleId: string }
>("missionQueue", async (data: { missionId: string; vehicleId: string }) => {
  try {
    await API.post(
      `vehicles/${data.vehicleId}/mission_queue/${data.missionId}/cancel/`
    );
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
});

export const changeMissionOrder = createAsyncThunk<
  void,
  {
    missionId: string;
    order: number;
    cancelAction: () => RowData[];
    newMissionsOrder: RowData[];
  }
>(
  "missionQueue",
  async (
    data: {
      missionId: string;
      order: number;
      cancelAction: () => RowData[];
      newMissionsOrder: RowData[];
    },
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      reorderMissionsQueue(data.newMissionsOrder.map((item) => item.id))
    );
    try {
      await API.patch(`fleets/mission_queue/${data.missionId}/`, {
        order: data.order,
      });
      toast.success("Mission reordered successfully");
    } catch (error) {
      const oldItems = data.cancelAction();
      thunkAPI.dispatch(reorderMissionsQueue(oldItems.map((item) => item.id)));

      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
