import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchGetSemanticMaps,
  getSemanticMaps,
  getIsSemanticMapsLoaded,
} from "Slices/semanticMaps";
import {
  getCurrentSemanticMapId,
  setCurrentSemanticMapId,
  setShowGlobalLoader,
} from "Slices/appState";
import { fetchGetRoles } from "Slices/roles";
import {
  fetchGetCurrentUser,
  getIsCurrentUserLoaded,
} from "Slices/currentUser";
import {
  getIsRolesLoaded,
  getIsRolesLoadingFailed,
} from "Slices/roles/selectors";
import { setErrorWarning } from "Slices/warning";

import { getServerErrorMessage } from "Utils";
import { ChildrenProps } from "Utils/types";
import { getLocalStorageItem, storageKeys } from "Utils/localStorageUtils";
import { getIsCurrentUserLoadingFailed } from "Slices/currentUser/selectors";
import { getIsSemanticMapsLoadingError } from "Slices/semanticMaps/selectors";
import {
  getIsFlagsLoaded,
  getIsFlagsLoadingFailed,
} from "Slices/flags/selectors";
import { fetchGetFlags } from "Slices/flags";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isRolesLoadingFailed = useSelector(getIsRolesLoadingFailed);
  const isCurrentUserLoadingFailed = useSelector(getIsCurrentUserLoadingFailed);
  const isSemanticMapsLoadingError = useSelector(getIsSemanticMapsLoadingError);
  const isFlagsLoadingError = useSelector(getIsFlagsLoadingFailed);

  useEffect(() => {
    if (
      isRolesLoadingFailed ||
      isCurrentUserLoadingFailed ||
      isSemanticMapsLoadingError ||
      isFlagsLoadingError
    ) {
      const problemData = `${isRolesLoadingFailed ? "ROLES" : ""} ${
        isCurrentUserLoadingFailed ? "CURRENT USER" : ""
      } ${isSemanticMapsLoadingError ? "SEMANTIC MAPS" : ""} ${
        isFlagsLoadingError ? "FLAGS" : ""
      }`.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [
    isRolesLoadingFailed,
    isCurrentUserLoadingFailed,
    isSemanticMapsLoadingError,
    isFlagsLoadingError,
  ]);
};

export const AuthorizedAppLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isSemanticMapsLoaded = useSelector(getIsSemanticMapsLoaded);
  const isRolesLoaded = useSelector(getIsRolesLoaded);
  const isCurrentUserLoaded = useSelector(getIsCurrentUserLoaded);
  const isFlagsLoaded = useSelector(getIsFlagsLoaded);

  const semanticMaps = useSelector(getSemanticMaps);
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const isAllInitialDataLoaded = useMemo(
    () =>
      isSemanticMapsLoaded &&
      isRolesLoaded &&
      isCurrentUserLoaded &&
      isFlagsLoaded,
    [isSemanticMapsLoaded, isRolesLoaded, isCurrentUserLoaded, isFlagsLoaded]
  );

  useHandleDataLoadingErrors();

  // GET /semantic_maps/?fields=id%2C%20name
  useEffect(() => {
    if (isSemanticMapsLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetSemanticMaps());
  }, [isSemanticMapsLoaded]);

  // set active semantic map on each page reload
  useEffect(() => {
    if (!currentSemanticMapId && semanticMaps && semanticMaps.length !== 0) {
      const storedSemanticMapId = getLocalStorageItem(
        storageKeys.CURRENT_SEMANTIC_MAP_ID
      );
      const savedSemanticMapId =
        storedSemanticMapId &&
        semanticMaps.find(
          (semanticMap) => semanticMap.id === storedSemanticMapId
        )?.id;
      dispatch(
        setCurrentSemanticMapId(savedSemanticMapId || semanticMaps[0].id)
      );
    }
  }, [semanticMaps, currentSemanticMapId]);

  // GET /roles
  useEffect(() => {
    if (isRolesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetRoles());
  }, [isRolesLoaded]);

  // GET /users/me
  useEffect(() => {
    if (isCurrentUserLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetCurrentUser());
  }, [isCurrentUserLoaded]);

  // GET /flags
  useEffect(() => {
    if (isFlagsLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetFlags());
  }, [isFlagsLoaded]);

  // SHOULD BE useLayoutEffect effect here for synchronize work!!!!
  useLayoutEffect(() => {
    if (isAllInitialDataLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isAllInitialDataLoaded]);

  return <>{isAllInitialDataLoaded ? children : null}</>;
};
