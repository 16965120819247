import * as Sentry from "@sentry/browser";
import { storageKeys } from "./localStorageUtils";

export const sendSentryEvent = (
  error: Error,
  errorType: string,
  userText = ""
) => {
  Sentry.withScope((scope) => {
    scope.setExtra("date", new Date());
    scope.setExtra("userText", userText);
    scope.setExtra("user", {
      id: localStorage.getItem("USER_ID"),
      firstname: localStorage.getItem(storageKeys.USER_FIRST_NAME),
      lastname: localStorage.getItem(storageKeys.USER_LAST_NAME),
      company: localStorage.getItem(storageKeys.USER_COMPANY),
      email: localStorage.getItem(storageKeys.USER_EMAIL),
      role: localStorage.getItem(storageKeys.USER_ROLE),
    });
    // scope.setExtra("store", JSON.stringify(store));
    Sentry.captureException(error || new Error(errorType));
  });
};
