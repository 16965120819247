import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getCurrentUserCompanyName,
  getCurrentUserId,
  getCurrentUserEmail,
  getCurrentUserFirstName,
  getCurrentUserImage,
  getCurrentUserLastName,
  getCurrentUserRole,
} from "Slices/currentUser";
import { getSelectedVehicleIds } from "Slices/missionPlanner";
import {
  useLocalStorage,
  resetLocalStorage,
  storageKeys,
} from "Utils/localStorageUtils";

export const LocalStorageCache = () => {
  const currentUserFirstName = useSelector(getCurrentUserFirstName);
  const currentUserLastName = useSelector(getCurrentUserLastName);
  const currentUserImage = useSelector(getCurrentUserImage);
  const currentUserCompany = useSelector(getCurrentUserCompanyName);
  const currentUserRole = useSelector(getCurrentUserRole);
  const currentUserId = useSelector(getCurrentUserId);
  const currentUserEmail = useSelector(getCurrentUserEmail);
  const selectedVehiclesIds = useSelector(getSelectedVehicleIds);

  useLocalStorage(storageKeys.USER_FIRST_NAME, currentUserFirstName);
  useLocalStorage(storageKeys.USER_LAST_NAME, currentUserLastName);
  useLocalStorage(storageKeys.USER_IMAGE_LINK, currentUserImage);
  useLocalStorage(storageKeys.USER_COMPANY, currentUserCompany);
  useLocalStorage(storageKeys.USER_ROLE, currentUserRole);
  useLocalStorage(storageKeys.USER_ID, currentUserId);
  useLocalStorage(storageKeys.USER_EMAIL, currentUserEmail);
  useLocalStorage(
    storageKeys.MISSION_OPERATOR_SAVED_VEHICLES,
    selectedVehiclesIds
  );

  useEffect(
    () => () => {
      resetLocalStorage();
    },
    []
  );

  return null;
};
