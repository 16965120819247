import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  fetchGetVehicleTypes,
  getIsVehicleTypesLoadingError,
} from "Slices/vehicleTypes";
import { getCurrentSemanticMapId, setShowGlobalLoader } from "Slices/appState";

import { ChildrenProps } from "Utils/types";
import { getIsVehicleTypesLoaded } from "Slices/vehicleTypes";
import { setErrorWarning } from "Slices/warning";
import { getServerErrorMessage } from "Utils";
import { fetchGetVehicles, getIsVehiclesLoaded } from "Slices/vehicles";
import { getIsVehiclesLoadingError } from "Slices/vehicles/selectors";
import { useStopsLoader } from "Utils/loaders";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isVehicleTypesLoadingError = useSelector(getIsVehicleTypesLoadingError);
  const isVehiclesLoadingError = useSelector(getIsVehiclesLoadingError);

  useEffect(() => {
    if (isVehicleTypesLoadingError || isVehiclesLoadingError) {
      const problemData = `
       ${isVehicleTypesLoadingError ? "VEHICLE TYPES" : ""}
       ${isVehiclesLoadingError ? "VEHICLES" : ""}`.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isVehicleTypesLoadingError, isVehiclesLoadingError]);
};

export const RealTimeMapLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const isVehicleTypesLoaded = useSelector(getIsVehicleTypesLoaded);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

  useHandleDataLoadingErrors();

  const { isStopsLoaded, isStopsLoadingError } =
    useStopsLoader(currentSemanticMapId);

  useEffect(() => {
    if (!isStopsLoadingError) return;

    dispatch(setShowGlobalLoader(false));
    dispatch(setErrorWarning(getServerErrorMessage("STOPS")));
  }, [isStopsLoadingError]);

  // stops loading
  useEffect(() => {
    if (isStopsLoaded) return;
    dispatch(setShowGlobalLoader(true));
  }, [isStopsLoaded]);

  // GET /vehicles
  useEffect(() => {
    if (isVehiclesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicles());
  }, [isVehiclesLoaded]);

  // GET /vehicleTypes
  useEffect(() => {
    if (!currentSemanticMapId || isVehicleTypesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicleTypes());
  }, [currentSemanticMapId, isVehicleTypesLoaded]);

  // turn the loader off
  useEffect(() => {
    if (isVehicleTypesLoaded && isStopsLoaded && isVehiclesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isVehicleTypesLoaded, isStopsLoaded, isVehiclesLoaded]);

  return <>{children}</>;
};
