import styled from "styled-components";

interface DropdownButtonProps {
  active: boolean;
}

export const AddMissionsDropdownStyled = styled.div`
  position: relative;
  cursor: pointer;
`;

export const DropdownButtonStyled = styled.div<DropdownButtonProps>`
  min-width: 198px;
  height: 48px;
  background: #3f7f7d;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  svg {
    transform: ${(props) =>
      props.active ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: 0.3s;
    path {
      fill: #ffffff;
    }
  }
`;

export const ButtonContentStyled = styled.div`
  padding: 6px 12px;
  font-family: "Readex Pro", sans-serif;
  color: #ffffff;
`;

export const DropdownContentStyled = styled.div`
  background-color: #ffffff;
  position: absolute;
  width: 198px;
  top: 58px;
  padding: 8px 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  z-index: 2;
  border-top: 2px solid #55b8b3;
`;

export const DropdownItemStyled = styled.div`
  display: flex;
  align-items: center;
  height: 44px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  padding-left: 16px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  &:hover {
    color: #55b8b3;
    border-bottom: 1px solid #55b8b3;
  }
`;
