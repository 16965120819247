type HandlerId = number;
type Callback = () => void;
type Handler = { workerTimeoutId?: number; callback?: Callback };
type Handlers = Record<HandlerId, Handler>;
type WorkerMessage = { id: HandlerId; timeoutId?: number };

export class TimeoutWorker {
  static _timeoutWorkerUrl = URL?.createObjectURL?.(
    new Blob([
      `
    onmessage = e => {
        const id = e.data?.id;
        if (e.data?.cancel) {
            clearTimeout(e.data.cancel);
            return;
        }

        const timeout = e.data?.timeout ?? 100;
        const timeoutId = setTimeout(
            () => postMessage({ id }), timeout
        );
        postMessage({ id, timeoutId });
    };
`,
    ])
  );

  private worker?: Worker;
  private handlers: WeakMap<Worker, Handlers>;
  private currentHandlerId: number;

  constructor() {
    this.worker = new Worker(TimeoutWorker._timeoutWorkerUrl);
    this.worker.onmessage = this.onMessage;
    this.handlers = new WeakMap([[this.worker, {}]]);
    this.currentHandlerId = 0;
  }

  onMessage = (e: { data: WorkerMessage }) => {
    if (!this.worker) return;

    const id = e.data.id;
    if (!id) throw Error("No id in worker message");

    const handler = this.getHandler(id);
    if (!handler) return;

    if (e.data?.timeoutId) {
      handler.workerTimeoutId = e.data.timeoutId;
    } else {
      handler.callback?.();
      this.deleteHandler(id);
    }
  };

  private getHandler(id: HandlerId) {
    if (!this.worker)
      throw new Error(
        "Timing class instance is destroyed, no worker found on getHandler()"
      );

    const handlers = this.handlers.get(this.worker);
    if (!handlers) throw new Error("No handlers found on getHandler()");

    if (handlers[id]) {
      return handlers[id];
    } else {
      const handler = { workerTimeoutId: undefined, callback: undefined };
      handlers[id] = handler;

      return handler;
    }
  }

  deleteHandler(id: HandlerId) {
    if (!this.worker)
      throw new Error(
        "Timing class instance is destroyed, no worker found on deleteHandler()"
      );

    const handlers = this.handlers.get(this.worker);
    if (handlers) {
      delete handlers[id];
    }
  }

  setTimeout(callback: () => void, timeout: number) {
    if (!this.worker)
      throw new Error(
        "Timing class instance is destroyed, no worker found on setTimeout()"
      );

    const id = ++this.currentHandlerId;
    const handler = this.getHandler(id);
    if (!handler) throw Error("No handler found on setTimeout()");

    handler.callback = callback;
    this.worker.postMessage({ id, timeout });

    return id;
  }

  clearTimeout(id: HandlerId) {
    if (!this.worker) return;

    const handler = this.getHandler(id);
    if (!handler) throw Error("No handler found on clearTimeout()");

    if (handler.workerTimeoutId) {
      this.worker.postMessage({ id, cancel: handler.workerTimeoutId });
    }
    this.deleteHandler(id);
  }

  destroy() {
    if (!this.worker) return;
    this.worker.terminate();
    this.handlers.delete(this.worker);
    this.worker = undefined;
  }
}
