import React, { memo } from "react";

import {
  VehicleStatusContainerStyled,
  VehicleStatusCountStyled,
  VehicleStatusTitleStyled,
  VehicleStatusWrapperStyled,
} from "./styles";

type MissionOperatorVehicleStatusProps = {
  title: string;
  count: number | "...";
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  countColor: string;
};

export const VehicleStatus = memo(
  ({ title, count, Icon, countColor }: MissionOperatorVehicleStatusProps) => {
    return (
      <VehicleStatusWrapperStyled>
        <Icon />
        <VehicleStatusContainerStyled>
          <VehicleStatusCountStyled countColor={countColor}>
            {count}
          </VehicleStatusCountStyled>
          <VehicleStatusTitleStyled>{title}</VehicleStatusTitleStyled>
        </VehicleStatusContainerStyled>
      </VehicleStatusWrapperStyled>
    );
  }
);
