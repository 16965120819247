import { fetchGetSemanticMaps } from "./actions";
import {
  getSemanticMaps,
  getIsSemanticMapsLoaded,
  getCurrentSemanticMap,
} from "./selectors";
import SemanticMapSlice from "./reducers";

//async actions
export { fetchGetSemanticMaps };

//actions
export const { resetSemanticMaps } = SemanticMapSlice.actions;

//selectors
export { getSemanticMaps, getIsSemanticMapsLoaded, getCurrentSemanticMap };

//reducer
export default SemanticMapSlice.reducer;
