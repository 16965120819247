import { fetchGetRoles } from "./actions";
import { getRoles, getRolesById } from "./selectors";
import RolesSlice from "./reducers";

// async actions
export { fetchGetRoles };

// actions
export const { resetRoles } = RolesSlice.actions;

// selectors
export { getRoles, getRolesById };

// reducer
export default RolesSlice.reducer;
