import React from "react";
import styled from "styled-components";
import { TableFieldBodyStyled } from "../NewList/styles";
import { vehicleDriveModesMap } from "./constants";

const VehicleDriveModeNameStyled = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 120%;
`;

const VehicleDriveModeWrapperStyled = styled.div`
  background: #e9e9e9;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  clip-path: polygon(94.5% 0%, 100% 14%, 100% 100%, 5.5% 100%, 0 86%, 0 0);
  padding: 4px 8px;
`;

export const getVehicleDriveModeField = (driveMode: VehicleDriveMode) => {
  const { icon, driveModeName } = vehicleDriveModesMap[driveMode] ?? {};

  return (
    <TableFieldBodyStyled>
      <VehicleDriveModeWrapperStyled>
        {icon ?? null}
        <VehicleDriveModeNameStyled>
          {driveModeName ?? driveMode}
        </VehicleDriveModeNameStyled>
      </VehicleDriveModeWrapperStyled>
    </TableFieldBodyStyled>
  );
};
