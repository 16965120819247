import styled from "styled-components";

export const MissionPlansLoadingErrorStyled = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  padding: 57% 0;

  svg {
    width: 46px;

    path {
      fill: #df2020;
    }
  }
`;

export const TextStyled = styled.div`
  font-size: 22px;
  font-weight: 300;
  color: #df2020;
  font-family: "Readex Pro", sans-serif;
`;
