import { getWarning } from "./selectors";
import WarningSlice from "./reducers";

// actions
export const { setWarning, setErrorWarning, closeWarning } =
  WarningSlice.actions;

// selectors
export { getWarning };

// reducer
export default WarningSlice.reducer;
