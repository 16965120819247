import styled from "styled-components";

export const AsideFiltersWrapperStyled = styled.div`
  min-width: 306px;
  height: 100%;
  background-color: #e7e8eb;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`;

export const SemanticMapPickerPaddingStyled = styled.div`
  padding: 32px 32px 21px;
`;
