import { getLocalStorageItem, storageKeys } from "Utils/localStorageUtils";

export const getSavedPeriod = (): MissionPlannerPeriodFilter | null => {
  const savedPeriod: MissionPlannerPeriodFilter = getLocalStorageItem(
    storageKeys.MISSION_OPERATOR_SAVED_PERIOD
  );
  return savedPeriod || null;
};

export const getSavedVehiclesIds = () => {
  const savedVehiclesIds: Array<string> = getLocalStorageItem(
    storageKeys.MISSION_OPERATOR_SAVED_VEHICLES
  );
  return savedVehiclesIds || [];
};
