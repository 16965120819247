import React from "react";

import { ReactComponent as InQueue } from "../../assets/MissionStatuses/inQueue.svg";
import { ReactComponent as InProgressMission } from "../../assets/MissionStatuses/inProgress.svg";
import { ReactComponent as CompletedMission } from "../../assets/MissionStatuses/completed.svg";
import { ReactComponent as FailedMission } from "../../assets/MissionStatuses/failed.svg";
import { ReactComponent as PausedMission } from "../../assets/MissionStatuses/paused.svg";
import { ReactComponent as CancelledMission } from "../../assets/MissionStatuses/canceled.svg";

type StatusMeta = {
  icon: JSX.Element;
  statusName: string;
};

export const missionStatusesMap: { [key in MissionStatusCode]: StatusMeta } = {
  NEW: { icon: <InQueue />, statusName: "In queue" },
  SENT: { icon: <InQueue />, statusName: "Sending…" },
  RECEIVED: { icon: <InQueue />, statusName: "Received!" },
  IN_PROGRESS: { icon: <InProgressMission />, statusName: "In progress" },
  FINISHED: { icon: <CompletedMission />, statusName: "Finished" },
  FAILURE: { icon: <FailedMission />, statusName: "Failure" },
  PAUSED: { icon: <PausedMission />, statusName: "Paused" },
  CANCEL_PENDING: {
    icon: <CancelledMission />,
    statusName: "Cancelling…",
  },
  CANCEL_SENT: { icon: <CancelledMission />, statusName: "Cancelling…" },
  CANCELED: { icon: <CancelledMission />, statusName: "Canceled" },
  BLOCKED_BY_MANUAL: {
    icon: <PausedMission />,
    statusName: "Paused",
  },
};
