import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

import { getTodayEndISO, getTodayStartISO } from "Utils";
import { setVehiclesPageMissions } from ".";

export const fetchGetActiveMissions = createAsyncThunk<void, Array<string>>(
  "missionQueue",
  async (currentFleetIds: string[], thunkAPI) => {
    const startDate = getTodayStartISO();
    const endDate = getTodayEndISO();
    const currentFleetIdsStringified = currentFleetIds.join(",");
    try {
      const response = await API.get(
        `fleets/mission_queue/?fleet_ids=${currentFleetIdsStringified}&created_start=${startDate}&created_end=${endDate}&limit=20`
      );
      thunkAPI.dispatch(setVehiclesPageMissions(response.data.results));
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
