import styled from "styled-components";

export const ContactSupportModalContentStyled = styled.div`
  overflow-y: auto;
  background-color: #ffffff;
  box-sizing: border-box;
  color: #0e152f;
  z-index: 1;
  border-radius: 4px;
  padding: 16px 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;

  strong {
    margin-left: 4px;
  }
`;
