import React, { FC, memo, useState } from "react";
import { useDispatch } from "react-redux";

import {
  fetchPostMissionPlan,
  fetchDeployMissionPlan,
} from "Slices/missionPlans";
import { FMSLogger } from "FMSLogger";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { EMPTY_ICON } from "Utils";
import { MissionDetails } from "../../../MissionDetails";
import { DeployedMissionList } from "../../../DeployedMissionList";

import {
  MissionDetailsWrapperStyled,
  MissionPlannerContainerStyled,
  MissionPlannerContentStyled,
  MissionPlannerHeaderStyled,
} from "../../../MissionDetails/styles";
import {
  MissionPlannerStyled,
  MissionPlannerBackgroundStyled,
  MissionPlannerLabelStyled,
  MissionPlannerRouteNameStyled,
  MissionPlannerTabBtnStyled,
  MissionPlannerTabsStyled,
  MissionPlannerHeadContentStyled,
  MissionPlannerHeadLeftStyled,
  MissionPlannerHeadInfoStyled,
  ImgStyled,
  DeployedMissionListBodyStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionPlannerProps = {
  missionPlannerData: any;
  setMissionPlannerData: (missionPlannerData: any) => void;
  stops: Array<Stop>;
};

export const MissionPlanner: FC<MissionPlannerProps> = memo(
  ({ missionPlannerData, setMissionPlannerData, stops }) => {
    const [editData, setEditData] = useState(null);
    const dispatch = useDispatch();
    const addPlanMissionAction = (data: MissionAsset) =>
      dispatch(fetchPostMissionPlan(data));
    const deployPlanMissionAction = (data: MissionAsset) =>
      dispatch(fetchDeployMissionPlan({ ...data }));
    const [activeTab, setActiveTab] = useState("savedMission");
    const { vehicleId, semanticMap, vehicleName, vehicleType } =
      missionPlannerData;

    return (
      <MissionPlannerStyled>
        <MissionPlannerBackgroundStyled
          onClick={() => setMissionPlannerData(null)}
        />
        <MissionPlannerContainerStyled>
          <MissionPlannerContentStyled withHeader>
            <MissionPlannerHeadContentStyled>
              <MissionPlannerHeaderStyled>
                Mission Planner
              </MissionPlannerHeaderStyled>
              <MissionPlannerHeadInfoStyled>
                <ImgStyled src={vehicleType?.image?.link || EMPTY_ICON} />
                <MissionPlannerHeadLeftStyled>
                  <MissionPlannerLabelStyled>
                    You are planning a mission for
                  </MissionPlannerLabelStyled>
                  <MissionPlannerRouteNameStyled>
                    {vehicleName}
                  </MissionPlannerRouteNameStyled>
                </MissionPlannerHeadLeftStyled>
              </MissionPlannerHeadInfoStyled>
            </MissionPlannerHeadContentStyled>
            <MissionPlannerTabsStyled>
              <MissionPlannerTabBtnStyled
                onClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "choose tab with mission list"
                  );
                  setActiveTab("savedMission");
                }}
                active={activeTab === "savedMission"}
              >
                {editData ? "Edit Mission" : "Mission List"}
              </MissionPlannerTabBtnStyled>
              <MissionPlannerTabBtnStyled
                onClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "choose tab with new mission"
                  );
                  setActiveTab("newMission");
                }}
                active={activeTab === "newMission"}
              >
                Create New Mission
              </MissionPlannerTabBtnStyled>
            </MissionPlannerTabsStyled>
            <DeployedMissionListBodyStyled>
              <DeployedMissionList
                vehicleName={vehicleName}
                editData={editData}
                setEditData={setEditData}
                stops={stops}
                vehicleId={vehicleId}
                semanticMapId={semanticMap}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                deployPlanMissionAction={deployPlanMissionAction}
                cancelAction={() => setMissionPlannerData(null)}
                previousMode="Cancel"
              />
              {activeTab === "newMission" && (
                <MissionDetailsWrapperStyled>
                  <MissionDetails
                    changeTabAction={() => setActiveTab("savedMission")}
                    editData={null}
                    cancelAction={() => setMissionPlannerData(null)}
                    stopsList={stops}
                    addPlanMissionAction={addPlanMissionAction}
                    deployPlanMissionAction={deployPlanMissionAction}
                    vehicleName={vehicleName}
                    vehicleId={vehicleId}
                    semanticMapId={semanticMap}
                    previousMode="Cancel"
                    patchPlanMissionAction={null}
                  />
                </MissionDetailsWrapperStyled>
              )}
            </DeployedMissionListBodyStyled>
          </MissionPlannerContentStyled>
        </MissionPlannerContainerStyled>
      </MissionPlannerStyled>
    );
  }
);
