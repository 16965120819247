import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchPatchMissionPlan } from "Slices/missionPlans";
import { getVehiclesUpdateData } from "Slices/vehiclesState";
import { MissionDetailsWrapperStyled } from "Components/MissionDetails/styles";
import { MissionDetails } from "Components/MissionDetails";
import { AssignModal } from "Components/MissionDetails/AssignModal";
import { MissionsModal } from "Components/MissionDetails/MissionsModal";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { FMSLogger } from "FMSLogger";

import { MissionList } from "./components/MissionList/MissionList";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

export const DeployedMissionList = ({
  vehicleName,
  setEditData,
  stops,
  vehicleId,
  semanticMapId,
  activeTab,
  editData,
  setActiveTab,
  deployPlanMissionAction,
  cancelAction,
  previousMode,
}) => {
  const dispatch = useDispatch();
  const [savedMissionsDisabled, setSavedMissionsDisabled] = useState(false);
  const [isShowAssignPicker, setShowAssignPicker] = useState(false);
  const [currentMissionData, setCurrentMissionData] = useState(null);
  const [confirmOverwriteMissionData, setConfirmOverwriteMissionData] =
    useState(null);

  const vehiclesUpdateData = useSelector(getVehiclesUpdateData);

  const patchPlanMissionAction = (data) =>
    dispatch(fetchPatchMissionPlan(data));

  const disableSavedMissions = () => {
    if (!savedMissionsDisabled) {
      setSavedMissionsDisabled(true);
      setTimeout(() => {
        setSavedMissionsDisabled(false);
      }, 6000);
    }
  };

  const deployAction = (missionData) => () => {
    if (savedMissionsDisabled) {
      return;
    }
    setCurrentMissionData(missionData);
    setShowAssignPicker(true);
  };

  const deploy = (missionData, assigneeData) => {
    deployPlanMissionAction({
      ...missionData,
      ...assigneeData,
      previous: previousMode,
    });
    patchPlanMissionAction({
      id: missionData.id,
      data: { ...missionData, previous: previousMode, timeStamp: new Date() },
      hideToast: true,
    });
    disableSavedMissions();
    setConfirmOverwriteMissionData(null);
  };

  const deployWithOverwrite = (missionData, assigneeData) => {
    if (
      previousMode !== "Continue" &&
      vehiclesUpdateData &&
      vehiclesUpdateData[assigneeData.vehicle] &&
      vehiclesUpdateData[assigneeData.vehicle].mission_status === "IN_PROGRESS"
    ) {
      setConfirmOverwriteMissionData({
        text: "This vehicle has a mission in progress. Would you like to cancel the current mission and redeploy this one?",
        confirmAction: () => {
          missionDeploymentActionsLogger.debug("deploy mission with overwrite");
          deploy(missionData, assigneeData);
        },
        cancelAction: () => {
          missionDeploymentActionsLogger.debug(
            "cancel deploy mission with overwrite"
          );
          setConfirmOverwriteMissionData(null);
        },
      });
      return;
    }
    missionDeploymentActionsLogger.debug("deploy mission");
    deploy(missionData, assigneeData);
  };

  return (
    <>
      {!confirmOverwriteMissionData && isShowAssignPicker && (
        <AssignModal
          vehicleId={vehicleId}
          vehicleName={vehicleName}
          currentMissionDataStops={currentMissionData.stops}
          handleVehiclePick={(id, selectedLoopsValue) => {
            deployWithOverwrite(currentMissionData, {
              vehicle: id,
              loops: Number(selectedLoopsValue),
            });
            setShowAssignPicker(false);
          }}
          handleFleetPick={(id, selectedLoopsValue) => {
            missionDeploymentActionsLogger.debug("deploy mission to fleet");
            deploy(currentMissionData, {
              fleet: id,
              loops: Number(selectedLoopsValue),
            });
            setShowAssignPicker(false);
          }}
          cancelAction={() => setShowAssignPicker(false)}
        />
      )}
      {confirmOverwriteMissionData && (
        <MissionsModal {...confirmOverwriteMissionData} />
      )}
      {activeTab === "savedMission" && !editData && (
        <MissionList
          isShowAssignPicker={isShowAssignPicker}
          setActiveTab={setActiveTab}
          cancelAction={cancelAction}
          setEditData={setEditData}
          deployAction={deployAction}
          savedMissionsDisabled={savedMissionsDisabled}
        />
      )}
      {activeTab === "savedMission" && editData && (
        <MissionDetailsWrapperStyled>
          <MissionDetails
            editData={editData}
            cancelAction={() => setEditData(null)}
            stopsList={stops}
            patchPlanMissionAction={patchPlanMissionAction}
            deployPlanMissionAction={deployPlanMissionAction}
            vehicleId={vehicleId}
            semanticMapId={semanticMapId}
            previousMode={previousMode}
          />
        </MissionDetailsWrapperStyled>
      )}
    </>
  );
};
