import React, {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { getUsersFilteredByCurrentCompany } from "Slices/users";
import { toggleModal, INVITE_USER_MODAL } from "Slices/modals";

import { ReactComponent as AddIcon } from "./assets/addIcon.svg";
import { ReactComponent as SearchIcon } from "./assets/searchIcon.svg";
import { ReactComponent as CountIcon } from "./assets/countIcon.svg";

import {
  ListPageHeaderStyled,
  HeaderLinksContainerStyled,
  HeaderTitleStyled,
  HeaderLinksAddButtonStyled,
  CountItemStyled,
  InputStyled,
  InputContainerStyled,
} from "./styles";

type PeopleHeaderProps = {
  setSearchValue: Dispatch<SetStateAction<string>>;
};

export const PeopleHeader: FC<PeopleHeaderProps> = memo(
  ({ setSearchValue }) => {
    const [inputValue, setInputValue] = useState<string>("");

    const dispatch = useDispatch();
    const users = useSelector(getUsersFilteredByCurrentCompany);

    useEffect(() => {
      const timer = setTimeout(() => {
        setSearchValue(inputValue);
      }, 1000);
      return () => clearTimeout(timer);
    }, [inputValue]);

    return (
      <ListPageHeaderStyled>
        <HeaderLinksContainerStyled>
          <HeaderTitleStyled>
            People
            <CountItemStyled>
              <CountIcon />
              {users.length}
            </CountItemStyled>
          </HeaderTitleStyled>
        </HeaderLinksContainerStyled>
        <HeaderLinksContainerStyled>
          <InputContainerStyled>
            <SearchIcon />
            <InputStyled
              placeholder="Search user"
              onChange={(event) => setInputValue(event.target.value)}
            />
          </InputContainerStyled>
          <HeaderLinksAddButtonStyled
            onClick={() => dispatch(toggleModal({ type: INVITE_USER_MODAL }))}
          >
            <AddIcon />
            Add User
          </HeaderLinksAddButtonStyled>
        </HeaderLinksContainerStyled>
      </ListPageHeaderStyled>
    );
  }
);
