import styled from "styled-components";

interface MissionPlannerProps {
  withHeader?: boolean;
}

interface StopsDropdownProps {
  isOpen: boolean;
}

interface InputProps {
  notFocusedItem?: boolean;
}

interface LabelProps {
  bold?: boolean;
  header?: boolean;
}

export const StopsDropdownStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 210px;
  height: 100%;
`;

export const StopsDropdownCurrentStyled = styled.div<StopsDropdownProps>`
  display: flex;
  align-items: center;
  box-shadow: ${(props) => (props.isOpen ? "0px 0px 3px #253F73" : "none")};
  padding: 6px 12px;
  box-sizing: border-box;
  font-weight: normal;
  font-size: 16px;
  color: #3b4256;
  background: #f8fafc;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    box-shadow: ${(props) =>
      props.isOpen ? "0px 0px 3px #253F73" : "0px 0px 3px rgba(0, 0, 0, 0.2)"};
  }
`;

export const StopsDropdownContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  box-sizing: border-box;
  padding: 6px 12px;
  top: 44px;
  width: 100%;
  left: 0;
  z-index: 1;
  background: #f8fafc;
  border-radius: 4px;
  box-shadow: 0 0 3px #253f73;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  > button {
    width: 48%;
  }
`;

export const ErrorMessageStyled = styled.div`
  display: flex;
  color: #a20000;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin: 4px;
`;

export const MissionDetailsTitleItemStyled = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const MissionDetailsItemContentStyled = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-left: 0;
`;

export const MissionDetailsListStyled = styled.div`
  width: 100%;
  height: 100%;
  max-height: 200px;
  box-sizing: border-box;
  padding: 4px;
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const MissionDetailsItemStyled = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 0 4px;
  margin-bottom: 8px;
  height: 36px;
`;

export const MissionDetailsWrapperStyled = styled.div`
  padding: 24px;
`;

export const MissionDetailsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const MissionDetailsNumberStyled = styled.div`
  font-weight: normal;
  font-size: 26pt;
  line-height: 120%;
  letter-spacing: 0.005em;
  margin-right: 12px;
`;

export const MissionDetailsModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: start;
  width: 100%;
`;

export const MissionDetailsLabelStyled = styled.div<LabelProps>`
  font-weight: ${(props) => (props.bold ? "800" : "normal")};
  display: flex;
  align-items: flex-end;
  font-size: ${(props) => (props.header ? "18pt" : "16pt")};
  line-height: 1.5;
  margin-bottom: 2px;
  letter-spacing: 0.005em;
`;

export const PlanItemsBodyStyled = styled.div`
  height: 39px;
  box-shadow: 0 0 2px #b8bcca;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
  margin-bottom: 4px;
  padding: 12px;
  border-radius: 4px;
`;

export const PlanItemsStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 100%;
  font-size: 14px;
`;

export const PlanItemNameStyled = styled.span`
  width: 147px;
`;

export const HitchTypeStyled = styled.div`
  text-align: end;
  height: 100%;
  width: 100px;
  color: #687684;

  svg {
    position: relative;
    bottom: 2px;
    right: 20px;
    height: 20px;

    path {
      fill: #df2020;
    }
  }
`;

export const TimePlanItemStyled = styled.div`
  font-weight: normal;
  width: 210px;
  font-size: 14px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 17px;
  color: #687684;
`;

export const RemovePlanItemStyled = styled.div`
  font-size: 14px;
  color: #c4c4c4;
  cursor: pointer;
`;

export const InputStyled = styled.input<InputProps>`
  font-size: 16px;
  padding: 10px 17px;
  min-width: 161px;
  margin-right: 30px;
  box-sizing: border-box;
  background: #f8fafc;
  border: 2px solid;
  border-color: transparent;
  border-radius: 4px;
  border-bottom: 1px solid #c4c4c4;
  border-bottom: ${(props) =>
    props.notFocusedItem ? "1px solid #c4c4c4" : "none"};

  ::placeholder {
    color: #c4c4c4;
  }

  &:focus {
    outline: none;
    box-shadow: ${(props) =>
      props.notFocusedItem ? "none" : "box-shadow: 0px 0px 3px #253f73;"};
  }
`;

export const MissionPlannerContainerStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  background: #ffffff;
  overflow-y: auto;
  display: flex;
`;

export const MissionPlannerContentStyled = styled.div<MissionPlannerProps>`
  height: ${(props) => (props.withHeader ? "calc(100% - 72px)" : "100% ")};
  background: #ffffff;
  box-sizing: border-box;
  width: 650px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MissionPlannerHeaderStyled = styled.div`
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.005em;
  margin-bottom: 16px;
`;

export const MissionDetailsModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 60px;
  width: 650px;
  height: 100%;
  position: fixed;
  background: rgba(37, 63, 115, 0.5);
  top: 0;
  left: 0;
  z-index: 1;
`;

export const MissionDetailsModalContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 40px;
  width: 455px;
  height: max-content;
  background: #ffffff;
  z-index: 1;
  border-radius: 4px;
`;

export const MissionDetailsModalTextStyled = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const MissionDetailsModalButtonsStyled = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  width: 90%;
`;

export const MissionDetailsModalDropdown = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const MissionDetailsModalBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

export const MissionDetailsHelperTextStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 120%;
  color: #004ecc;

  svg {
    margin-right: 4px;
  }
`;

export const CheckboxBodyStyled = styled.div`
  color: #c4c4c4;
  box-shadow: none;
  background: #f8fafc;
  height: 100%;
  border-radius: 4px;
  padding: 0 12px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease 0s;
`;
