import React from "react";

import {
  LoaderContainerStyled,
  LoaderStyled,
  MissionPlannerLoaderContainerStyled,
  AsideLoaderContainerStyled,
  WebsocetLoaderContainerStyled,
  WebsocetLoaderMainStyled,
} from "./styles";

export const Loader = () => {
  return (
    <LoaderContainerStyled data-test-id="loader">
      <LoaderStyled />
    </LoaderContainerStyled>
  );
};

export const MissionPlannerLoader = () => {
  return (
    <MissionPlannerLoaderContainerStyled>
      <LoaderStyled />
    </MissionPlannerLoaderContainerStyled>
  );
};

export const AsideLoader = () => {
  return (
    <AsideLoaderContainerStyled>
      <LoaderStyled />
    </AsideLoaderContainerStyled>
  );
};

export const WebsocetLoader = () => {
  return (
    <WebsocetLoaderMainStyled>
      {/*<WebsocetLoaderLabel>Connection is establishing</WebsocetLoaderLabel>*/}
      <WebsocetLoaderContainerStyled>
        <LoaderStyled />
      </WebsocetLoaderContainerStyled>
    </WebsocetLoaderMainStyled>
  );
};
