import styled from "styled-components";

export const CheckboxContainerStyled = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckboxStyled = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckboxStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 3px solid;
  opacity: ${(props) => (props.checked ? 1 : 0.8)};
  border-color: ${(props) => (props.checked ? "#253F73" : "#3b4256;")};
  background: ${(props) => (props.checked ? "#253F73" : "#F8FAFC")};
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 150ms;
  margin-right: 10px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const CheckboxLabelContainerStyled = styled.label`
  cursor: pointer;
  display: flex;
  line-height: 26px;
  align-items: center;
  opacity: ${(props) => (props.checked ? 1 : 0.8)};
  transition: opacity 0.2s;
  color: ${(props) => (props.checked ? "#253F73" : "#3b4256;")};
  font-size: 16px;

  &:hover {
    color: #253f73 div {
      border-color: #253f73;
    }
  }
`;
