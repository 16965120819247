import React, {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useState,
} from "react";
import { useSelector } from "react-redux";

import { getCurrentSemanticMap } from "Slices/semanticMaps";
import { OperatorSidePanel } from "../OperatorSidePanel";
import { MapComponent } from "../MapComponent";

import {
  MapWindowStyled,
  MissionOperatorSidebarStyled,
  MissionOperatorWrapperStyled,
  ToggleSatButtonStyled,
} from "./styles";

export type ColumnWidthContextType = {
  pressedStationId: string | null;
  setPressedStationId: Dispatch<SetStateAction<string | null>> | null;
};

export const PressedStationIdContext = createContext<ColumnWidthContextType>({
  pressedStationId: "",
  setPressedStationId: null,
});

export const MissionOperator = memo(() => {
  const [isShowSatelliteView, setShowSatelliteView] = useState(false);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);

  const [pressedStationId, setPressedStationId] = useState<string | null>(null);

  return (
    <PressedStationIdContext.Provider
      value={{ pressedStationId, setPressedStationId }}
    >
      <MissionOperatorWrapperStyled>
        <MissionOperatorSidebarStyled>
          <OperatorSidePanel />
        </MissionOperatorSidebarStyled>
        <MapWindowStyled>
          <MapComponent
            mapBounds={currentSemanticMap?.bbox ?? undefined}
            currentSemanticMap={currentSemanticMap}
            showSatView={isShowSatelliteView}
            pressedStopId={pressedStationId}
          />
          <ToggleSatButtonStyled
            onClick={() => setShowSatelliteView(!isShowSatelliteView)}
          />
        </MapWindowStyled>
      </MissionOperatorWrapperStyled>
    </PressedStationIdContext.Provider>
  );
});
