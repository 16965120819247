import styled from "styled-components";

// possibly move to common
export const MissionPlannerListContentStyled = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 24px 0;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 900px;
  overflow: hidden;
`;

export const MissionPlannerContainerStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow: scroll;
`;
