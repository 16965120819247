import React, { memo, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  fetchPostStation,
  getOperatorPickedStation,
  getOperatorStations,
} from "Slices/operatorStation";
import { FMSLogger } from "FMSLogger";
import { MISSION_OPERATOR_ACTIONS } from "Utils/constants";

import {
  SelectStationWrappeerStyled,
  SelectStationBodyStyled,
  SelectStationSidebar,
  HeaderSelectStationStyled,
  SelectStationCloseButtonStyled,
  StationsListStyled,
  ConfirmButtonStyled,
} from "./styles";
import { StationCheckbox } from "../StationCheckbox";
import { ReactComponent as CloseIcon } from "../../assets/close-icon.svg";
import { ReactComponent as ConfirmIcon } from "../../assets/confirm-icon.svg";
import { PressedStationIdContext } from "../../../MissionOperator/MissionOperator";

const missionOperatorLogger = FMSLogger.byPrefix(MISSION_OPERATOR_ACTIONS);

type SelectStationProps = {
  setSelectStationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SelectStation = memo(
  ({ setSelectStationModalVisible }: SelectStationProps) => {
    const dispatch = useDispatch();
    const { pressedStationId, setPressedStationId } = useContext(
      PressedStationIdContext
    );

    const stations = useSelector(getOperatorStations);
    const pickedStation = useSelector(getOperatorPickedStation);

    const handleSaveStation = async () => {
      missionOperatorLogger.debug(
        "Confirm station picker button has been pressed"
      );
      if (pressedStationId === pickedStation?.id) {
        setSelectStationModalVisible(false);
        return;
      }

      await dispatch(fetchPostStation({ stopId: pressedStationId ?? "" }));
      setSelectStationModalVisible(false);
    };

    useEffect(() => {
      setPressedStationId?.(pickedStation?.id ?? null);
      return () => {
        setPressedStationId?.(null);
      };
    }, []);

    return (
      <SelectStationSidebar>
        <SelectStationWrappeerStyled>
          <SelectStationBodyStyled>
            <HeaderSelectStationStyled>
              Select Station
              <SelectStationCloseButtonStyled
                onClick={() => {
                  missionOperatorLogger.debug(
                    "Close station picker button has been pressed"
                  );
                  setSelectStationModalVisible(false);
                }}
              >
                Close
                <CloseIcon />
              </SelectStationCloseButtonStyled>
            </HeaderSelectStationStyled>
            <StationsListStyled>
              {stations.map((station) => (
                <StationCheckbox
                  key={station?.name}
                  isPressed={pressedStationId === station?.id}
                  station={station}
                  stationPressedHandler={
                    setPressedStationId as React.Dispatch<
                      React.SetStateAction<string | null>
                    >
                  }
                />
              ))}
            </StationsListStyled>
            <ConfirmButtonStyled onClick={handleSaveStation}>
              Confirm
              <ConfirmIcon />
            </ConfirmButtonStyled>
          </SelectStationBodyStyled>
        </SelectStationWrappeerStyled>
      </SelectStationSidebar>
    );
  }
);
