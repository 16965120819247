import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { arrayToObjectByField } from "Utils";
import { getCurrentSemanticMapId } from "../appState";
import { DataStatus } from "../../types";

const getStops = (state: RootState) => state.stops.data;
const getStopsStatus = (state: RootState) => state.stops.status;

export const getStopsByCurrentSemanticMap = createSelector(
  getCurrentSemanticMapId,
  getStops,
  (currentSemanticMapId, stops) =>
    stops.filter((stop) => stop.semanticMap === currentSemanticMapId)
);

export const getStopsByCurrentSemanticMapDict = createSelector(
  getStopsByCurrentSemanticMap,
  (stops) => arrayToObjectByField(stops)
);
export const getIsStopsByCurrentSemanticMapEmpty = createSelector(
  getStopsByCurrentSemanticMap,
  getStopsStatus,
  (stops, status) => stops.length === 0 && status === DataStatus.succeeded
);
export const getIsStopsLoaded = createSelector(
  getStopsStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsStopsLoading = createSelector(
  getStopsStatus,
  (status) => status === DataStatus.loading
);
export const getIsStopsLoadingError = createSelector(
  getStopsStatus,
  (status) => status === DataStatus.failed
);
export const getLoadedStopsSemanticMapIds = (state: RootState) =>
  state.stops.loadedSemanticMapIds;
