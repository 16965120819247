import {
  ConfigurationData,
  Level,
  Prefix,
  PrefixData,
  OptionalConfigurationData,
  LoggerCore,
} from "./FMSLogger";
import { LoggedMessage } from "./PrefixedLogger";
import { ConsoleTransport } from "./ConsoleTransport";
import { IndexedDBTransport } from "./IndexedDBTransport";

export const LEVELS = {
  error: 4,
  warn: 3,
  info: 2,
  debug: 1,
};

interface ILogExecutor extends LoggerCore {
  log: (prefix: Prefix, level: Level, messages: LoggedMessage[]) => void;
  getConfig: () => ConfigurationData;
}

const savedLevel = localStorage.getItem("FMSLoggerLevel");
const savedPrefix = localStorage.getItem("FMSLoggerPrefix");

const defaultLoggerConfigData: ConfigurationData = {
  level: savedLevel ? JSON.parse(savedLevel) : "info",
  prefixData: savedPrefix ? JSON.parse(savedPrefix) : null,
};

export class LogExecutor implements ILogExecutor {
  private currentConfig: ConfigurationData = defaultLoggerConfigData;
  private consoleTransport: ConsoleTransport;
  private indexedDBTransport: IndexedDBTransport;
  private readonly sessionId: string;

  constructor(sessionId: string) {
    this.sessionId = sessionId;
    this.consoleTransport = new ConsoleTransport();
    this.indexedDBTransport = IndexedDBTransport.getInstance();
  }

  log(prefix: Prefix, level: Level, messages: LoggedMessage[]) {
    this.consoleTransport.log(
      prefix,
      level,
      messages,
      this.currentConfig.prefixData,
      this.currentConfig.level
    );
    this.indexedDBTransport.log(prefix, level, messages, this.sessionId);
  }

  setLevel(level: Level) {
    this.currentConfig.level = level;
    localStorage.setItem("FMSLoggerLevel", JSON.stringify(level));
  }

  setPrefix(prefixData: PrefixData) {
    this.currentConfig.prefixData = prefixData;
    localStorage.setItem("FMSLoggerPrefix", JSON.stringify(prefixData));
  }

  applyConfig(configData: OptionalConfigurationData) {
    const newLevelValue = configData.level ?? this.currentConfig.level;
    const newPrefixValue =
      configData.prefixData ?? this.currentConfig.prefixData;

    this.currentConfig = {
      level: newLevelValue,
      prefixData: newPrefixValue,
    };
    localStorage.setItem("FMSLoggerLevel", JSON.stringify(newLevelValue));
    localStorage.setItem("FMSLoggerPrefix", JSON.stringify(newPrefixValue));
  }

  getConfig() {
    return this.currentConfig;
  }

  getLogs() {
    return this.indexedDBTransport.getLogs();
  }

  clearLogs() {
    this.indexedDBTransport.clearLogs();
  }
}
