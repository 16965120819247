import { useEffect } from "react";

export const storageKeys = {
  USER_FIRST_NAME: "USER_FIRST_NAME",
  USER_LAST_NAME: "USER_LAST_NAME",
  USER_IMAGE_LINK: "USER_IMAGE_LINK",
  USER_COMPANY: "USER_COMPANY",
  USER_ROLE: "USER_ROLE",
  USER_ID: "USER_ID",
  USER_EMAIL: "USER_EMAIL",
  HIDE_CONFIRMATION_MODAL_WINDOWS: "HIDE_CONFIRMATION_MODAL_WINDOWS",
  MISSION_OPERATOR_SAVED_FLEET_ID: "MISSION_OPERATOR_SAVED_FLEET_ID",
  MISSION_OPERATOR_SAVED_PERIOD: "MISSION_OPERATOR_SAVED_PERIOD",
  MISSION_OPERATOR_SAVED_VEHICLES: "MISSION_OPERATOR_SAVED_VEHICLES",
  CURRENT_SEMANTIC_MAP_ID: "CURRENT_SEMANTIC_MAP_ID",
};

export const getLocalStorageItem = (key: string) => {
  const storedItem = localStorage.getItem(key);

  // handle case when undefined has been written in localStorage once upon a time
  if (storedItem === "undefined") return "";

  return storedItem ? JSON.parse(storedItem) : "";
};

export const resetLocalStorage = () => {
  Object.values(storageKeys).forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const useLocalStorage = (key: string, data: any) => {
  useEffect(() => {
    if (data === undefined) {
      localStorage.removeItem(key);
      return;
    }

    localStorage.setItem(key, JSON.stringify(data));
  }, [JSON.stringify(data)]);
};
