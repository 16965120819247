import {
  fetchGetMissionQueue,
  cancelMission,
  changeMissionOrder,
} from "./actions";
import {
  getCurrentFleetId,
  getMissionRowsLimit,
  getSearchValue,
  getHeaderData,
  getActiveTab,
  getMissionPlannerPeriod,
  getAllMissionsCount,
  getDeployedMissionsCount,
  getCompletedMissionsCount,
  getMissions,
  getSelectedVehicleIds,
  getIsMissionPlannerUpdating,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
} from "./selectors";
import MissionsQueueSlice from "./reducers";
import { getSavedPeriod, getSavedVehiclesIds } from "./utils";

//utils
export { getSavedPeriod, getSavedVehiclesIds };

// actions
export const {
  setMissionsQueue,
  setCurrentFleetPeriod,
  setMissionRowsLimit,
  setCurrentSearchValue,
  setCurrentFleetId,
  setActiveTab,
  setMissionCounts,
  updateMissionsQueue,
  resetMissionsQueue,
  setSelectedVehicleIds,
  setIsMissionPlannerUpdating,
  resetPagination,
  reorderMissionsQueue,
  pauseMissionsUpdates,
  continueMissionsUpdates,
  resetMissionsUpdatesStorage,
  resetMissionPlannerData,
} = MissionsQueueSlice.actions;

// async actions
export { fetchGetMissionQueue, cancelMission, changeMissionOrder };

// selectors
export {
  getCurrentFleetId,
  getMissionRowsLimit,
  getSearchValue,
  getHeaderData,
  getActiveTab,
  getMissionPlannerPeriod,
  getAllMissionsCount,
  getDeployedMissionsCount,
  getCompletedMissionsCount,
  getMissions,
  getSelectedVehicleIds,
  getIsMissionPlannerUpdating,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
};

// reducer
export default MissionsQueueSlice.reducer;
