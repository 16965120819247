import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setShowGlobalLoader } from "Slices/appState";
import {
  fetchGetVehicleTypes,
  getIsVehicleTypesLoadingError,
} from "Slices/vehicleTypes";
import { getIsVehicleTypesLoaded } from "Slices/vehicleTypes";
import { setErrorWarning } from "Slices/warning";
import { getServerErrorMessage } from "Utils";
import { ChildrenProps } from "Utils/types";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isVehicleTypesLoadingError = useSelector(getIsVehicleTypesLoadingError);

  useEffect(() => {
    if (isVehicleTypesLoadingError) {
      const problemData = `
       ${isVehicleTypesLoadingError ? "VEHICLE TYPES" : ""}
      `.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isVehicleTypesLoadingError]);
};

export const VehicleTypesListLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isVehicleTypesLoaded = useSelector(getIsVehicleTypesLoaded);

  useHandleDataLoadingErrors();

  // GET /vehicleTypes
  useEffect(() => {
    if (isVehicleTypesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicleTypes());
  }, [isVehicleTypesLoaded]);

  // turn the loader off
  useEffect(() => {
    if (isVehicleTypesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isVehicleTypesLoaded]);

  return <>{children}</>;
};
