import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { getDiffTime } from "Utils";
import { getStopsByCurrentSemanticMapDict } from "Slices/stops";
import { sortCollectionByNumberField } from "Utils/collectionUtils";
import { FMSLogger } from "FMSLogger";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";

import {
  MissionItemStyled,
  MissionItemContentStyled,
  MissionItemDeployBtnStyled,
  MissionItemColumnStyled,
  MissionItemColumnHeadStyled,
  MissionItemLastDeployStyled,
  MissionItemDetailsStyled,
  MissionItemAdditionHeadStyled,
  MissionItemAdditionTextStyled,
  MissionItemButtonsStyled,
  MissionItemButtonStyled,
} from "./styles";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

export const MissionItem = ({
  missionData,
  currentIndex,
  setEditData,
  deletePlanMissionAction,
  savedMissionsDisabled,
  deployAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const stopsObject = useSelector(getStopsByCurrentSemanticMapDict);
  const { name, data, timeStamp, id, stops } = missionData;

  const currentStops = stops.length ? stops : data;

  const sortedCurrentStops = useMemo(
    () => sortCollectionByNumberField(currentStops, "order"),
    [currentStops]
  );

  const handleDeployAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item deploy action for mission: ${id}`
    );
    deployAction();
  };

  const editAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item edit action for mission: ${id}`
    );
    setEditData({
      index: currentIndex,
      data: missionData,
    });
  };

  const deleteAction = () => {
    missionDeploymentActionsLogger.debug(
      `mission item delete action for mission: ${id}`
    );
    deletePlanMissionAction(id);
  };

  const getStopName = (stopId) => {
    if (!stopId) {
      return "No Data";
    }
    return stopsObject[stopId]?.name || "No Data";
  };

  return (
    <MissionItemStyled isOpen={isOpen}>
      <MissionItemContentStyled>
        <MissionItemColumnStyled>
          <MissionItemColumnHeadStyled isOpen={isOpen}>
            {name}
          </MissionItemColumnHeadStyled>
          {timeStamp && (
            <MissionItemLastDeployStyled>
              last deployed
              <strong>{getDiffTime(new Date(timeStamp))}</strong>
            </MissionItemLastDeployStyled>
          )}
          <MissionItemDetailsStyled
            onClick={() => {
              missionDeploymentActionsLogger.debug(
                `toggle mission details for mission ${id}`
              );
              setIsOpen(!isOpen);
            }}
            isOpen={isOpen}
          >
            Mission Details {">"}
          </MissionItemDetailsStyled>
        </MissionItemColumnStyled>
        <MissionItemDeployBtnStyled
          disabled={savedMissionsDisabled}
          onClick={handleDeployAction}
        >
          DEPLOY
        </MissionItemDeployBtnStyled>
      </MissionItemContentStyled>
      {isOpen && (
        <MissionItemContentStyled>
          <MissionItemColumnStyled>
            <MissionItemAdditionHeadStyled>
              Stops Planned
            </MissionItemAdditionHeadStyled>
            {sortedCurrentStops?.map((dataItem, index) => (
              <MissionItemAdditionTextStyled key={`${dataItem.name}-${index}`}>
                {dataItem.name || getStopName(dataItem.stopPk)} -{" "}
                {dataItem.duration === -1
                  ? "Manual Release"
                  : `${dataItem.duration}s`}
              </MissionItemAdditionTextStyled>
            ))}
            <MissionItemButtonsStyled>
              <MissionItemButtonStyled onClick={editAction} isEdit={true}>
                Edit Mission
              </MissionItemButtonStyled>
              <MissionItemButtonStyled onClick={deleteAction}>
                Delete Mission
              </MissionItemButtonStyled>
            </MissionItemButtonsStyled>
          </MissionItemColumnStyled>
        </MissionItemContentStyled>
      )}
    </MissionItemStyled>
  );
};
