import React, { memo } from "react";
import { useSelector } from "react-redux";

import { MissionPlannerLoader } from "Components/common/Loader";
import { getIsMissionPlannerUpdating } from "Slices/missionPlanner";

import {
  MissionPlannerContainerStyled,
  MissionPlannerListContentStyled,
} from "./styles";
import { Header } from "./components/Header";
import { MissionPlannerLists } from "./components/MissionPlannerLists";
import { MissionListControls } from "./components/MissionListControls";
import { AsideFilters } from "./components/AsideFilters";

export const MissionPlanner = memo(() => {
  const missionPlannerUpdating = useSelector(getIsMissionPlannerUpdating);

  return (
    <MissionPlannerContainerStyled>
      <AsideFilters />
      <MissionPlannerListContentStyled>
        {missionPlannerUpdating && <MissionPlannerLoader />}
        <Header />
        <MissionListControls />
        <MissionPlannerLists />
      </MissionPlannerListContentStyled>
    </MissionPlannerContainerStyled>
  );
});
