import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as ChangeAvatar } from "../../assets/changeAvatar.svg";
import { UserAvatarContainerStyled, SpinnerUserImageStyled } from "./styles";

import { getIsImageLoading, postCurrentUserImage } from "Slices/currentUser";

import { UserAvatarComponent } from "../../../../common/UserAvatarComponent";
import { Spinner } from "../../../../Spinner/Spinner";

export const UserAvatar = memo(() => {
  const dispatch = useDispatch();
  const isImageLoading = useSelector(getIsImageLoading);

  const handleImageChange = useCallback((event) => {
    const file = event.target.files[0];
    dispatch(postCurrentUserImage(file));
    event.target.value = "";
  }, []);

  return (
    <UserAvatarContainerStyled>
      <UserAvatarComponent />
      <input
        type="file"
        id="file"
        accept="image/png,image/jpeg"
        onChange={handleImageChange}
        disabled={isImageLoading}
      />
      <label htmlFor="file">
        <ChangeAvatar data-test-id="edit_picture" />
      </label>
      {isImageLoading && (
        <SpinnerUserImageStyled>
          <Spinner />
        </SpinnerUserImageStyled>
      )}
    </UserAvatarContainerStyled>
  );
});
