import React, { FC, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getOperatorPickedStation } from "Slices/operatorStation";
import {
  getCurrentMissionAsset,
  getIsMissionAssetsEmpty,
} from "Slices/operatorMissionAssets";
import { fetchDeployMissionPlan } from "Slices/missionPlans";
import {
  getIsPickedFleetHasVehicles,
  getMissionOperatorPickedFleetId,
  getOperatorMissionStatus,
} from "Slices/operatorData";
import { FMSLogger } from "FMSLogger";
import { MISSION_OPERATOR_ACTIONS } from "Utils/constants";

import {
  InteractionPaneWrapper,
  CallVehicleButtonStyled,
  EmptyErrorStyled,
} from "./styles";
import { ReactComponent as HornIcon } from "../../assets/horn-icon.svg";
import { SelectedStation } from "../SelectedStation";
import { getIsMissionAssetsLoaded } from "Slices/operatorMissionAssets/selectors";

const missionOperatorLogger = FMSLogger.byPrefix(MISSION_OPERATOR_ACTIONS);

type InteractionPaneProps = {
  isSelectStationModalOpen: boolean;
  setSelectStationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StationPicker: FC<InteractionPaneProps> = memo(
  ({ isSelectStationModalOpen, setSelectStationModalVisible }) => {
    const dispatch = useDispatch();

    const pickedStation = useSelector(getOperatorPickedStation);
    const currentMissionAsset = useSelector(getCurrentMissionAsset);
    const isMissionAssetsLoaded = useSelector(getIsMissionAssetsLoaded);
    const isMissionAssetsEmpty = useSelector(getIsMissionAssetsEmpty);
    const missionOperatorPickedFleetId = useSelector(
      getMissionOperatorPickedFleetId
    );
    const operatorMissionStatus = useSelector(getOperatorMissionStatus);
    const isPickedFleetHasVehicles = useSelector(getIsPickedFleetHasVehicles);

    const isCallVehicleButtonDisabled =
      !currentMissionAsset ||
      !missionOperatorPickedFleetId ||
      operatorMissionStatus !== "NO_MISSION" ||
      !isPickedFleetHasVehicles;

    const handleDeployMissionAsset = useCallback(() => {
      missionOperatorLogger.debug("Call Vehicle button pressed");
      if (!currentMissionAsset || !missionOperatorPickedFleetId) {
        missionOperatorLogger.error(
          "No currentMissionAsset or missionOperatorPickedFleetId when Call Vehicle button pressed"
        );
        return;
      }

      dispatch(
        fetchDeployMissionPlan({
          ...currentMissionAsset,
          fleet: missionOperatorPickedFleetId,
          stationId: pickedStation?.stationId,
        })
      );
    }, [currentMissionAsset, missionOperatorPickedFleetId, pickedStation]);

    const ErrorMessage = useMemo(() => {
      if (isMissionAssetsEmpty)
        return <EmptyErrorStyled>Error: no mission plans!</EmptyErrorStyled>;

      if (!currentMissionAsset && isMissionAssetsLoaded)
        return (
          <EmptyErrorStyled>
            Error: No appropriate mission plan!
          </EmptyErrorStyled>
        );
      return null;
    }, [isMissionAssetsEmpty, currentMissionAsset, isMissionAssetsLoaded]);

    return (
      <InteractionPaneWrapper>
        <SelectedStation
          pickedStation={pickedStation}
          isSelectStationModalOpen={isSelectStationModalOpen}
          setSelectStationModalVisible={setSelectStationModalVisible}
        />
        <CallVehicleButtonStyled
          disabled={isCallVehicleButtonDisabled}
          onClick={handleDeployMissionAsset}
        >
          <HornIcon />
          Call Vehicle
        </CallVehicleButtonStyled>
        {ErrorMessage}
      </InteractionPaneWrapper>
    );
  }
);
