import React from "react";
import { TableFieldBodyStyled } from "../../../NewList/styles";
import {
  CounterBodyStyled,
  CounterStopsStyled,
  CounterLoopsStyled,
  DividerStopsCountersStyled,
  DividerLoopsCountersStyled,
  StatusNameStyled,
} from "./styles";

import { ReactComponent as PolygonIcon } from "../../assets/polygonIcon.svg";
import { ReactComponent as RectangleItem } from "../../assets/rectangleIcon.svg";
import { ReactComponent as NoMission } from "../../assets/MissionStatuses/noMission.svg";

import { missionStatusesMap } from "./constants";

export const getStopsField = (passedStops: string, totalStops: string) => {
  const zeroPassedStops = Number(passedStops) === 0;

  return (
    <TableFieldBodyStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled zeroCounter={zeroPassedStops}>
          {passedStops}
        </CounterStopsStyled>
      </CounterBodyStyled>
      <DividerStopsCountersStyled>Out of</DividerStopsCountersStyled>
      <CounterBodyStyled>
        <PolygonIcon />
        <CounterStopsStyled>{totalStops}</CounterStopsStyled>
      </CounterBodyStyled>
    </TableFieldBodyStyled>
  );
};

export const getLoopsField = (passedLoops: string, totalLoops: string) => {
  const isInfinity = Number(totalLoops) === -1;
  const zeroPassedLoops = Number(passedLoops) === 0;

  return isInfinity ? (
    <CounterLoopsStyled zeroCounter={zeroPassedLoops} isInfinity={isInfinity}>
      {passedLoops}
    </CounterLoopsStyled>
  ) : (
    <TableFieldBodyStyled>
      <CounterLoopsStyled zeroCounter={zeroPassedLoops}>
        {passedLoops}
      </CounterLoopsStyled>
      <DividerLoopsCountersStyled>
        <RectangleItem />
      </DividerLoopsCountersStyled>
      <CounterLoopsStyled>{totalLoops}</CounterLoopsStyled>
    </TableFieldBodyStyled>
  );
};

export const getMissionStatusField = (missionStatusCode: MissionStatusCode) => {
  const { icon, statusName } = missionStatusesMap[missionStatusCode] ?? {};

  return (
    <TableFieldBodyStyled>
      {icon ?? <NoMission />}
      <StatusNameStyled>{statusName ?? missionStatusCode}</StatusNameStyled>
    </TableFieldBodyStyled>
  );
};
