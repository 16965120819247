import { LogExecutor } from "./LogExecutor";
import { Prefix } from "./FMSLogger";

export type LoggedMessage = string | number | Record<string, any>;

type LogFunction = (messages: LoggedMessage[]) => void;

interface IPrefixedLogger {
  debug: LogFunction;
  info: LogFunction;
  warn: LogFunction;
  error: LogFunction;
}

export class PrefixedLogger implements IPrefixedLogger {
  private logExecutor: LogExecutor;
  private readonly prefix: Prefix;
  constructor(logExecutor: LogExecutor, prefix: Prefix) {
    this.logExecutor = logExecutor;
    this.prefix = prefix;
  }
  debug(...messages: LoggedMessage[]) {
    this.logExecutor.log(this.prefix, "debug", messages);
  }
  info(...messages: LoggedMessage[]) {
    this.logExecutor.log(this.prefix, "info", messages);
  }
  warn(...messages: LoggedMessage[]) {
    this.logExecutor.log(this.prefix, "warn", messages);
  }
  error(...messages: LoggedMessage[]) {
    this.logExecutor.log(this.prefix, "error", messages);
  }
}
