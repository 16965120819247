import axios from "axios";
import { toast } from "react-toastify";

import { FMSLogger } from "FMSLogger";
import { sendSentryEvent } from "./sentryUtils";

const networkLogger = FMSLogger.byPrefix("network-logs");

const isDevelop = window._env_.REACT_APP_ENV === "development";
export const API_URL = !isDevelop
  ? `https://${window.location.host}/`
  : window._env_.REACT_APP_API_URL || "";
const REACT_APP_WSS_URL = !isDevelop
  ? `wss://${window.location.host}/`
  : window._env_.REACT_APP_WSS_URL || "";
const API_VERSION = window._env_.REACT_APP_API_VERSION || "v1";
export const REACT_APP_WSS_URL_API = `${REACT_APP_WSS_URL}api/${API_VERSION}/ws`;

export const BASE = `${API_URL}api/${API_VERSION}`;

const instance = axios.create({
  baseURL: BASE,
});

instance.defaults.headers.common["Authorization"] =
  window.localStorage.getItem("JWT");

instance.interceptors.response.use(
  (response) => {
    networkLogger.debug(
      "API response",
      response.config.url as string,
      response.data
    );
    return response;
  },
  async (error) => {
    networkLogger.error(
      "API error",
      error.message,
      error.code,
      error.config.url,
      error.response?.data ?? ""
    );
    if (!axios.isCancel(error)) {
      sendSentryEvent(error, "API error");
    }
    throw error;
  }
);

instance.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem("JWT");
  config.headers.Authorization = token;
  networkLogger.debug(
    "API request",
    config.url as string,
    config.method as string,
    config.data ?? ""
  );
  return config;
});

export default instance;

export const showNetworkErrorToast = (error: unknown) => {
  const baseError = error as BaseError;
  const errorDetail = baseError?.response?.data?.detail;
  const errorMessage = `API request failed!
      URL: ${baseError?.config?.url}
      Method: ${baseError?.config?.method.toUpperCase()}
      Error code: ${baseError?.response?.status}
      ${errorDetail ? `Details: ${errorDetail}` : ""}`;
  toast.error(errorMessage);
};
