import { createSelector } from "@reduxjs/toolkit";
import { EMPTY_ARRAY } from "Utils";
import { RootState } from "../../index";
import { getStopsByCurrentSemanticMap } from "../stops";
import { DataStatus } from "../../types";

const getStationStatus = (state: RootState) => state.operatorStation.status;
const isSucceededStatus = createSelector(
  getStationStatus,
  (stationStatus) => stationStatus === DataStatus.succeeded
);
const getStation = (state: RootState) => state.operatorStation.station;
export const getLoadedStationId = createSelector(
  getStation,
  isSucceededStatus,
  (station, succeededStatus) => (succeededStatus ? station?.id ?? null : null)
);
export const getIsStationEmpty = createSelector(
  getStation,
  isSucceededStatus,
  (station, succeededStatus) => succeededStatus && station === null
);
export const getOperatorPickedStation = createSelector(
  getStation,
  isSucceededStatus,
  getStopsByCurrentSemanticMap,
  (station, succeededStatus, stops) => {
    if (!succeededStatus) return null;

    const pickedStop = stops.find((stop) => stop.id === station?.stopId);
    return pickedStop ? { ...pickedStop, stationId: station?.id } : null;
  }
);
export const getOperatorStations = createSelector(
  getStation,
  isSucceededStatus,
  getStopsByCurrentSemanticMap,
  (station, succeededStatus, stops) => {
    if (stops.length === 0 || !succeededStatus) return EMPTY_ARRAY;

    return stops.map((stop) => {
      return {
        id: stop.id,
        name: stop.name,
        isPicked: stop.id === station?.stopId,
      };
    });
  }
);
