import React, { memo } from "react";

import { OnlineLabelStyled } from "./styled";

interface OnlineLabelProps {
  online: boolean;
}

export const OnlineLabel = memo(({ online }: OnlineLabelProps) => {
  return (
    <OnlineLabelStyled online={online}>
      {online ? "Online" : "Offline"}
    </OnlineLabelStyled>
  );
});
