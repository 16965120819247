import React, { memo } from "react";
import ReactDOM from "react-dom";

import { ModalBackgroundStyled } from "../common/ModalComponent/styles";
import { OptionsDropdownProps, RowData } from "./types";
import { DropdownElementStyled, DropdownStyled } from "./styles";

type OptionsDropdownComponent = <T extends RowData>(
  props: OptionsDropdownProps<T>
) => JSX.Element | null;

export const OptionsDropdown: OptionsDropdownComponent = memo(
  ({
    toggleDropdown,
    optionsButtonRef,
    options,
    item,
    optionsDropdownOffset = { top: 0, right: 0 },
  }) => {
    const boundingOptionsButtonRect =
      optionsButtonRef.current?.getBoundingClientRect();
    const dropdownTopOffset = boundingOptionsButtonRect?.top;
    const dropdownRightOffset =
      document.body.clientWidth - (boundingOptionsButtonRect?.right || 0);
    const handleClick = (callback: (param: typeof item) => void) => {
      callback(item);
      toggleDropdown();
    };

    return ReactDOM.createPortal(
      Array.isArray(options) && (
        <>
          <ModalBackgroundStyled
            onClick={toggleDropdown}
            isTransparent={true}
          />
          <DropdownStyled
            topOffset={dropdownTopOffset}
            rightOffset={dropdownRightOffset}
            optionsDropdownOffset={optionsDropdownOffset}
          >
            {options
              .filter(({ isHide }) => !isHide?.(item))
              .map(({ name, callback }) => (
                <DropdownElementStyled
                  key={name}
                  onClick={() => handleClick(callback)}
                >
                  {name}
                </DropdownElementStyled>
              ))}
          </DropdownStyled>
        </>
      ),
      document.getElementById("ListWrapper") as Element
    );
  }
);
