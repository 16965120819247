import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getIsMissionPlansLoading,
  getIsMissionPlansLoadingError,
  getLoadedMissionPlansSemanticMapIds,
} from "Slices/missionPlans/selectors";
import { fetchGetMissionPlans } from "Slices/missionPlans";
import {
  getIsStopsLoaded,
  getIsStopsLoading,
  getIsStopsLoadingError,
  getLoadedStopsSemanticMapIds,
} from "Slices/stops/selectors";
import { fetchGetStops } from "Slices/stops";

export const useMissionPlansLoader = (semanticMapId?: string | null) => {
  const dispatch = useDispatch();

  const loadedMissionPlansSemanticMapIds = useSelector(
    getLoadedMissionPlansSemanticMapIds
  );
  const isMissionPlansLoading = useSelector(getIsMissionPlansLoading);
  const isMissionPlansLoadingError = useSelector(getIsMissionPlansLoadingError);

  useEffect(() => {
    if (
      !semanticMapId ||
      loadedMissionPlansSemanticMapIds.includes(semanticMapId)
    )
      return;

    dispatch(fetchGetMissionPlans(semanticMapId));
  }, [semanticMapId, loadedMissionPlansSemanticMapIds]);

  return { isMissionPlansLoading, isMissionPlansLoadingError };
};

export const useStopsLoader = (semanticMapId?: string | null) => {
  const dispatch = useDispatch();

  const loadedStopsSemanticMapIds = useSelector(getLoadedStopsSemanticMapIds);
  const isStopsLoading = useSelector(getIsStopsLoading);
  const isStopsLoaded = useSelector(getIsStopsLoaded);
  const isStopsLoadingError = useSelector(getIsStopsLoadingError);

  useEffect(() => {
    if (!semanticMapId || loadedStopsSemanticMapIds.includes(semanticMapId))
      return;

    dispatch(fetchGetStops(semanticMapId));
  }, [semanticMapId, loadedStopsSemanticMapIds]);

  return { isStopsLoading, isStopsLoadingError, isStopsLoaded };
};
