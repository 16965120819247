import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../../common/ModalComponent/styles";
import { getModalData, closeAnyModal } from "Slices/modals";
import { Input } from "../components/Input";
import { ModalWrapper } from "../components/ModalWrapper";
import { IconLoaderController } from "../components/IconLoaderController";
import { LabelField } from "../components/LabelField";
import { WeightInput } from "../components/WeightInput";
import {
  addVehicleTypeImage,
  fetchPostVehicleTypes,
  fetchPutVehicleTypesId,
  getVehicleTypesById,
} from "Slices/vehicleTypes";
import { ImageFileType } from "../types";
import { capitalizeFirstLetter, EMPTY_ICON } from "Utils";

export enum UnitsEnum {
  Lbs = "Lbs",
  Kg = "Kg",
}

export const VehicleTypeModal = memo(() => {
  const modalData = useSelector(getModalData);
  const vehicleTypesById = useSelector(getVehicleTypesById);
  const editedVehicleType = vehicleTypesById[modalData?.vehicleTypeId ?? ""];
  const [manufacturer, setManufacturer] = useState<string>(
    editedVehicleType?.manufacturer || ""
  );
  const [imageFile, setImageFile] = useState<ImageFileType>(null);
  const [model, setModel] = useState<string>(editedVehicleType?.model || "");
  const [details, setDetails] = useState<string>(
    editedVehicleType?.details || ""
  );
  const [weight, setWeight] = useState<string>(editedVehicleType?.weight || "");
  const [units, setUnits] = useState<UnitsEnum>(
    (capitalizeFirstLetter(editedVehicleType?.weightUnit) as UnitsEnum) ||
      UnitsEnum.Lbs
  );
  const [isNewImageLoading, setImageLoadingStatus] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const imageData = useMemo(
    () => ({
      ...editedVehicleType?.image,
      link: editedVehicleType?.image?.link || EMPTY_ICON,
      meta: editedVehicleType?.image?.meta || undefined,
    }),
    [vehicleTypesById]
  );

  const isImageEmpty = !imageFile && !imageData;

  const isDisabled =
    !manufacturer ||
    !model ||
    !weight ||
    !units ||
    isImageEmpty ||
    isNewImageLoading;

  const addNewVehicleType = useCallback(async () => {
    if (isDisabled) return;
    let loadedImageId = null;
    if (imageFile) {
      try {
        setImageLoadingStatus(true);
        loadedImageId = await addVehicleTypeImage(imageFile);
      } catch (error) {
        setImageLoadingStatus(false);
      }
      if (!loadedImageId) {
        setImageFile(null);
        return;
      }
    }

    const data = {
      image: { id: loadedImageId ?? imageData.id },
      manufacturer,
      model,
      weight,
      weightUnit: units.toLowerCase(),
      details,
    };

    dispatch(
      modalData
        ? fetchPutVehicleTypesId({
            id: modalData.vehicleTypeId as string,
            data,
          })
        : fetchPostVehicleTypes(data)
    );
    handleCloseAction();
  }, [imageFile, manufacturer, model, weight, units, details]);

  const vehicleTypeModalText = `${modalData ? "Edit" : "Add"} Vehicle Type`;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ModalWrapper
        successAction={addNewVehicleType}
        headerText={vehicleTypeModalText}
        actionButtonText={vehicleTypeModalText}
        isActionButtonDisabled={isDisabled}
        isActionButtonLoading={isNewImageLoading}
      >
        <LabelField isRequired={true} labelText="Image" />
        <IconLoaderController
          imageData={imageData}
          imageFile={imageFile}
          setImageFile={setImageFile}
        />
        <Input
          isRequired={true}
          value={manufacturer}
          labelText="Manufacturer"
          additionalInfo="Please enter vehicle manufacturer"
          changeAction={setManufacturer}
        />
        <Input
          isRequired={true}
          value={model}
          labelText="Model"
          additionalInfo="Please enter vehicle model"
          changeAction={setModel}
        />
        <LabelField labelText="Payload" isRequired={true} />
        <WeightInput
          inputValue={weight}
          checkboxValue={units}
          changeInputAction={setWeight}
          changeCheckboxAction={setUnits}
        />
        <Input
          value={details}
          labelText="Details"
          additionalInfo="Please enter vehicle details link"
          changeAction={setDetails}
        />
      </ModalWrapper>
    </ModalStyled>
  );
});
