import styled from "styled-components";
import SearchIcon from "../../assets/searchIcon.svg";

export const MissionListSearchStyled = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #f4f4f4;
`;

export const SearchInputStyled = styled.input`
  border: none;
  width: 100%;
  height: 48px;
  background-color: #f4f4f4;
  color: #606060;
  font-size: 14px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  background-image: url(${SearchIcon});
  background-position: 15px 15px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 45px;
`;

export const ClearButtonStyled = styled.div`
  height: 16px;
  cursor: pointer;
  margin-right: 24px;
`;
