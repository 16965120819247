import React, { memo } from "react";
import { useSelector } from "react-redux";
import { TransportFacilityHeader } from "Components/ListPageHeader/TransportFacilityHeader";
import { getVehicleTypes } from "Slices/vehicleTypes";
// import { VEHICLE_TYPE_MODAL } from "Slices/modals";

export const VehicleTypesListHeader = memo(() => {
  const vehicleTypes = useSelector(getVehicleTypes);
  return (
    <TransportFacilityHeader
      text={"Types"}
      // modalType={VEHICLE_TYPE_MODAL}
      addText={"Add Vehicle Type"}
      count={vehicleTypes.length}
    />
  );
});
