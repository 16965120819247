import React, { memo } from "react";
import { HeaderStyled, TitleStyled } from "./styles";
import { HeaderControls } from "../HeaderControls";

export const Header = memo(() => {
  return (
    <HeaderStyled>
      <TitleStyled>Missions</TitleStyled>
      <HeaderControls />
    </HeaderStyled>
  );
});
