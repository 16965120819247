import { memo, useContext, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getActiveTab,
  getCurrentFleetId,
  getMissionPlannerPeriod,
  fetchGetMissionQueue,
  getSelectedVehicleIds,
  updateMissionsQueue,
  getSearchValue,
  getMissionRowsLimit,
  getIsMissionsUpdatesPaused,
  getMissionsUpdatesStorage,
  resetMissionsUpdatesStorage,
} from "Slices/missionPlanner";
import { MISSION_PANNER_WS, WS_SPINNER_LOGS } from "Utils/constants";
import { useFMSWebsocket } from "Utils/webSocketUtils";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:MissionPlanner`);

export const MissionPlannerController = memo(() => {
  const currentFleetId = useSelector(getCurrentFleetId);
  const selectedVehicleIds = useSelector(getSelectedVehicleIds);
  const period = useSelector(getMissionPlannerPeriod);
  const limit = useSelector(getMissionRowsLimit);
  const searchValue = useSelector(getSearchValue);
  const activeTab = useSelector(getActiveTab);
  const isMissionsUpdatesPaused = useSelector(getIsMissionsUpdatesPaused);
  const missionsUpdatesStorage = useSelector(getMissionsUpdatesStorage);
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  useEffect(() => {
    if (currentFleetId) {
      wsSpinnerLogs.debug("new fleet id spinner on");
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug("no fleet id spinner off");
      setIsWSConnecting?.(false);
    }
  }, [currentFleetId]);

  useFMSWebsocket({
    url: "/fleets/mission_queue/",
    subscriptionData: currentFleetId,
    loggerFlag: MISSION_PANNER_WS,
    getDataForSubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      fleet_ids: [subscriptionId],
    }),
    handleInitialMessage: () => {
      wsSpinnerLogs.debug("create message spinner off");
      setIsWSConnecting?.(false);
    },
    handleMessage: (data) => {
      const shouldIgnoreUpdate = data?.mission?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      if (typeof data?.missions === "object") {
        dispatch(updateMissionsQueue(data.missions));
      } else {
        dispatch(updateMissionsQueue(data));
      }
    },
  });

  // get mission queue if filters has been changed
  useEffect(() => {
    if (!currentFleetId || !period) return;
    dispatch(
      fetchGetMissionQueue({
        currentFleetId,
        activeTab,
        selectedVehicleIds,
        period,
        limit,
        searchValue,
      })
    );
  }, [
    currentFleetId,
    activeTab,
    selectedVehicleIds.length,
    period,
    limit,
    searchValue,
  ]);

  // update missions from storage
  useEffect(() => {
    if (
      isMissionsUpdatesPaused !== false ||
      missionsUpdatesStorage.length === 0
    )
      return;

    missionsUpdatesStorage.forEach((update) => {
      dispatch(updateMissionsQueue({ data: update }));
    });
    dispatch(resetMissionsUpdatesStorage());
  }, [isMissionsUpdatesPaused, missionsUpdatesStorage]);

  return null;
});
