import styled from "styled-components";

export const SelectStationSidebar = styled.div`
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 544px;
  top: 0;
  z-index: 10;
`;

export const SelectStationWrappeerStyled = styled.div`
  width: 372px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #d1d1d1;
`;

export const SelectStationBodyStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 32px 12px;
  ffont-family: "Readex Pro";
`;

export const HeaderSelectStationStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  font-size: 20px;
  line-height: 120%;
  color: #172a3f;
`;

export const SelectStationCloseButtonStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 4px;
  width: 66px;
  font-size: 16px;
  color: #606060;
  cursor: pointer;
`;

export const StationsListStyled = styled.div`
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;
  width: 308px;
  gap: 16px;
`;

export const ConfirmButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding: 16px 24px;
  gap: 4px;
  background: #3f7f7d;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  cursor: pointer;
`;
