import styled from "styled-components";
import ChangeStationImage from "../../assets/change-station-background.svg";

interface SelectStationButtonProps {
  isPickedStation: boolean;
}
export const InteractionPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 480px;
  height: 350px;
  clip-path: polygon(97.5% 0, 100% 3%, 100% 100%, 2.5% 100%, 0 97%, 0 0);
  background: #f4f4f4;
  background-image: url(${ChangeStationImage});
  border: 1px solid #e9e9e9;
  padding: 32px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  color: #606060;
`;

export const SelectedStationStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SelectedStationNameStyled = styled.div`
  font-size: 48px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #172a3f;
`;

export const SelectStationButtonStyled = styled.button<SelectStationButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.isPickedStation ? "239px" : "100%")};
  border: ${(props) => (props.isPickedStation ? "2px solid #3f7f7d" : "none")};
  height: 56px;
  background: #3f7f7d;
  background: ${(props) => (props.isPickedStation ? "#f4f4f4" : "#3f7f7d")};
  font-weight: 400;
  font-size: 20px;
  color: ${(props) => (props.isPickedStation ? "#3f7f7d" : "#ffffff")};
  cursor: pointer;
  gap: 15px;

  &:disabled {
    border: 2px solid #e9e9e9;
    cursor: default;
    background: none;
    color: #a4a4a4;

    svg {
      path {
        fill: #a4a4a4;
      }
    }
  }
`;

export const CallVehicleButtonStyled = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 32px;
  gap: 8px;
  width: 414px;
  height: 168px;
  background: #3f7f7d;
  font-size: 20px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-family: "Readex Pro", sans-serif;

  &:disabled {
    border: none;
    cursor: default;
    background: #d6d8de;
    color: #606060;

    svg {
      path {
        stroke: #606060;
      }
    }
  }
`;

export const EmptyErrorStyled = styled.div`
  color: red;
`;
