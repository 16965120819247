import { fetchGetFlags } from "./actions";
import { getIsFlagsLoaded, isUnhitchEnable } from "./selectors";
import FlagsSlice from "./reducers";

// actions
export const { resetFlags } = FlagsSlice.actions;

// async actions
export { fetchGetFlags };

// selectors
export { getIsFlagsLoaded, isUnhitchEnable };

// reducer
export default FlagsSlice.reducer;
