import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import { addNewVehicle, updateVehicle } from ".";

export const fetchGetVehicles = createAsyncThunk(
  "vehicles/fetchGetVehicles",
  async () => {
    try {
      const response = await API.get("/vehicles/");

      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostVehicles = createAsyncThunk<void, VehicleCore>(
  "vehicles/fetchPostVehicles",
  async (data, thunkAPI) => {
    try {
      const response = await API.post("/vehicles/", {
        ...data,
      });
      thunkAPI.dispatch(addNewVehicle(response.data));
      toast.success("Vehicle added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPutVehiclesId = createAsyncThunk<
  void,
  { id: string; data: VehicleCore }
>(
  "vehicles/fetchPutVehiclesId",
  async ({ id, data }: { id: string; data: VehicleCore }, thunkAPI) => {
    try {
      const response = await API.patch(`/vehicles/${id}/`, data);
      await thunkAPI.dispatch(updateVehicle(response.data));
      toast.success(`Vehicle ${data.name} edited successfully`);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
