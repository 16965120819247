import { IDBPDatabase, openDB } from "idb";

import { Level, Prefix } from "./FMSLogger";
import { LoggedMessage } from "./PrefixedLogger";

const dbName = "FMSLogs";
const dbStoreName = "FMSLogsStore";
const dbVersion = 1;
const dbKey = Symbol.for("IndexedDBTransport");
const entriesLimit = 10000;

const DELETE_DEBOUNCE_TIME = 500;

declare global {
  interface Window {
    [dbKey]?: IndexedDBTransport;
  }
}

export class IndexedDBTransport {
  private dbPromise;
  private prevTime: number;

  static getInstance() {
    if (!window[dbKey]) {
      window[dbKey] = new IndexedDBTransport();
    }

    return window[dbKey];
  }

  constructor() {
    this.dbPromise = this.initializeDb();

    this.prevTime = 0;
  }

  private initializeDb() {
    return openDB(dbName, dbVersion, {
      upgrade: this.upgradeDB,
      blocked: (currentVersion, blockedVersion) => {
        console.error(
          `Older versions of the database open on the origin, so this version
           cannot open. Current version: ${currentVersion}, blocked version: ${blockedVersion}`
        );
        this.dbPromise = Promise.reject("DB old version is open");
      },
    });
  }

  private upgradeDB = (database: IDBPDatabase) => {
    const dbStoresLength = database.objectStoreNames.length;
    for (let i = 0; i < dbStoresLength; i++) {
      const storeName = database.objectStoreNames[0];
      database.deleteObjectStore(storeName);
    }

    database.createObjectStore(dbStoreName, { autoIncrement: true });
  };

  async log(
    prefix: Prefix,
    level: Level,
    messages: LoggedMessage[],
    sessionId: string
  ) {
    try {
      const db = await this.dbPromise;
      await this.debouncedLogsDeletion();
      await db.put(dbStoreName, {
        time: new Date().toISOString(),
        level,
        prefix,
        sessionId,
        ...messages,
      });
    } catch (error) {
      console.error(
        "Can't perform log action for DB with following error:",
        error
      );
    }
  }

  async debouncedLogsDeletion() {
    const nowTime = Date.now();
    const shouldStartDeletionProcedure =
      nowTime - this.prevTime > DELETE_DEBOUNCE_TIME;

    this.prevTime = nowTime;

    if (!shouldStartDeletionProcedure) return;

    await this.deleteOutOfLimitEntries();
  }

  async deleteOutOfLimitEntries() {
    const db = await this.dbPromise;
    const dbLength = await db.count(dbStoreName);

    const excessAmount = dbLength - entriesLimit;
    if (excessAmount <= 0) return;

    const dbTransaction = db.transaction(dbStoreName, "readwrite");
    let cursor = await dbTransaction.store.openCursor();

    for (let count = excessAmount; count > 0; count--) {
      if (!cursor) break;

      dbTransaction.store.delete(cursor.key);
      cursor = await cursor.continue();
    }

    await dbTransaction.done;
  }

  async getLogs() {
    try {
      const db = await this.dbPromise;
      return await db.getAll(dbStoreName);
    } catch (error) {
      console.error("Can't get logs from db with following error", error);
      return Promise.reject(error);
    }
  }

  async clearLogs() {
    try {
      const db = await this.dbPromise;
      await db.clear(dbStoreName);
    } catch (error) {
      console.error("Can't clear logs from db with following error", error);
    }
  }
}
