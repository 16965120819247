import styled, { keyframes } from "styled-components";

const spinAnimations = keyframes`
  0% { transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
`;

export const ImageStyled = styled.div`
  width: 100%;
  height: 100%;
  animation: ${spinAnimations} 2s infinite linear;
  border-radius: 50%;
`;
