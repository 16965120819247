import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

export const forgotPass = createAsyncThunk(
  "users/fetchForgotPass",
  async ({ email, callback }: { email: string; callback: () => void }) => {
    try {
      await API.post("/users/password_reset/", {
        email,
      });
      toast.success("Please check your email for a password reset link.");
      callback();
    } catch (error) {
      showNetworkErrorToast(error);
    }
  }
);

export const checkForgotPassToken = createAsyncThunk(
  "users/fetchForgotPass",
  async ({
    token,
    callback,
  }: {
    token: string | null;
    callback: () => void;
  }) => {
    try {
      await API.post("/users/password_reset/validate_token/", {
        token,
      });
    } catch (error) {
      showNetworkErrorToast(error);
      callback();
    }
  }
);

export const confirmNewPassword = createAsyncThunk(
  "users/fetchForgotPass",
  async ({
    token,
    password,
    callback,
  }: {
    token: string;
    password: string;
    callback: () => void;
  }) => {
    try {
      await API.post("/users/password_reset/confirm/", {
        password,
        token,
      });
      toast.success("Password was changed");
      callback();
    } catch (error) {
      showNetworkErrorToast(error);
    }
  }
);
