import { LayerProps } from "react-map-gl";

export const RouteLayer: LayerProps = {
  id: "routeLayer",
  type: "line",
  source: "routeSrc",
  filter: ["==", "type", "ROUTE"],
  paint: {
    "line-color": "#5990dc",
    "line-width": 1,
  },
  layout: {
    visibility: "visible",
    "line-join": "round",
    "line-cap": "round",
  },
};
