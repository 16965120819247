import { createSlice } from "@reduxjs/toolkit";
import { fetchMissionAssets } from ".";
import { DataStatus } from "../../types";

interface MissionStop {
  duration: number;
  id: string;
  order: number;
  stopPk: string;
}

export interface MissionAsset {
  id: string;
  loops: number;
  name: string;
  previous: string;
  semanticMap: string;
  stops: Array<MissionStop>;
  timeStamp: string;
  updatedAt: string;
}

type OperatorMissionAssetsState = {
  missionAssetsByStopId: Record<string, MissionAsset> | null;
  status: DataStatus;
  error: string | null;
};

const initialState: OperatorMissionAssetsState = {
  missionAssetsByStopId: null,
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "operatorMissionAssets",
  initialState,
  reducers: {
    resetMissionOperatorAssets: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMissionAssets.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchMissionAssets.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.missionAssetsByStopId = action.payload.reduce(
          (acc: Record<string, MissionAsset>, curr: MissionAsset) => {
            acc[curr?.stops[0].stopPk] = curr;
            return acc;
          },
          {}
        );
      })
      .addCase(fetchMissionAssets.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
