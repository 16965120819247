import styled from "styled-components";
import CloseIcon from "./assets/closeIcon.svg";

export const InvitationSentModalStyled = styled.div`
  position: relative;
  width: 480px;
  height: 278px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
  padding: 65px 40px;
  box-sizing: border-box;
  z-index: 1;

  svg {
    margin-bottom: 24px;
  }
`;

export const CloseButtonStyled = styled.div`
  background-image: url(${CloseIcon});
  position: absolute;
  width: 16px;
  height: 16px;
  right: 16px;
  top: 16px;
  cursor: pointer;
  z-index: 1;
`;

export const HeaderStyled = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: #1b1b1b;
  margin-bottom: 8px;
`;

export const TextStyled = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #767676;

  span {
    color: #1b1b1b;
  }
`;
