import styled from "styled-components";

export const VehicleStatusesWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VehicleStatusesStyled = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0;
`;
