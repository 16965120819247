import React, { memo, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getFleets } from "Slices/fleets";
import { toggleModal } from "Slices/modals";
import { OrderType, createReorderHandler } from "Utils";

import { List } from "../NewList";
import { RowData } from "../NewList/types";
import { ReactComponent as Edit } from "../common/assets/edit.svg";

const useFleetListReordering = (
  preparedFleetsData: FleetListRowData[],
  setPreparedFleetsData: React.Dispatch<
    React.SetStateAction<FleetListRowData[]>
  >
) => {
  const [nameOrder, setNameOrder] = useState<OrderType>(1);
  const [vehiclesOrder, setVehiclesOrder] = useState<OrderType>(1);

  const reorderOptions = useMemo(
    () => ({
      name: createReorderHandler(
        "name",
        preparedFleetsData,
        setPreparedFleetsData,
        nameOrder,
        setNameOrder
      ),
      vehicles: createReorderHandler(
        "vehicles",
        preparedFleetsData,
        setPreparedFleetsData,
        vehiclesOrder,
        setVehiclesOrder
      ),
    }),
    [preparedFleetsData]
  );

  return reorderOptions;
};

type FleetListRowData = RowData & {
  name: string;
  vehicles: string;
};

export const FleetsList = memo(() => {
  const dispatch = useDispatch();
  const fleets: Array<Fleet> = useSelector(getFleets);
  const [preparedFleetsData, setPreparedFleetsData] = useState<
    FleetListRowData[]
  >([]);

  const reorderOptions = useFleetListReordering(
    preparedFleetsData,
    setPreparedFleetsData
  );

  const headerData = useMemo(
    () => ({
      name: "Fleet Name",
      vehicles: "Vehicles",
    }),
    []
  );

  const options = useMemo(
    () => ({
      icon: <Edit />,
      callback: (data: FleetListRowData) => {
        dispatch(toggleModal({ type: "FLEET_MODAL", data }));
      },
    }),
    []
  );

  useEffect(() => {
    if (fleets.length === 0) return;

    const fleetsData = fleets.map((fleet) => ({
      id: fleet.id,
      name: fleet.name,
      vehicles: String(fleet.vehiclesId?.length) ?? "0",
    }));
    setPreparedFleetsData(fleetsData);
  }, [fleets]);

  return (
    <List
      data={preparedFleetsData}
      headerData={headerData}
      options={options}
      reorderOptions={reorderOptions}
    />
  );
});
