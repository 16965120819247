import { createSlice } from "@reduxjs/toolkit";

import { fetchGetStops } from "./actions";
import { DataStatus } from "../../types";

type StopsState = {
  data: Array<Stop>;
  loadedSemanticMapIds: Array<string>;
  status: DataStatus;
  error: string | null;
};

const initialState: StopsState = {
  data: [],
  loadedSemanticMapIds: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "stops",
  initialState,
  reducers: {
    resetStops: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetStops.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetStops.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = state.data.concat(action.payload.stops);
        state.loadedSemanticMapIds = state.loadedSemanticMapIds.concat(
          action.payload.semanticMapId as string
        );
      })
      .addCase(fetchGetStops.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
