import styled from "styled-components";

export const DropdownAreaStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const DropdownContentStyled = styled.div<{
  top: string;
  left: string;
}>`
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: 198px;
  padding: 8px 0;
  z-index: 2;
  border-top: 2px solid #55b8b3;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
`;

export const DropdownItemStyled = styled.div<{
  isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  height: 44px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: ${({ isSelected }) => (isSelected ? "#e9e9e9" : "none")};
  color: ${({ isSelected }) => (isSelected ? "#0f625f" : "#606060")};
  padding-left: 16px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background: #e9e9e9;
    color: ${({ isSelected }) => (isSelected ? "none" : "#1b1b1b")};
  }
`;

export const UserRoleContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

export const StatusNameStyled = styled.div`
  font-size: 14px;
  color: #1b1b1b;
`;

export const MessageBodyStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const MessageStyled = styled.div`
  color: #1b1b1b;
`;
