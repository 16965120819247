import styled, { keyframes } from "styled-components";

const spin = keyframes`
  0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
`;

const fadeIn = keyframes`
  0%   {
    opacity: 0;
    }
    100% {
    opacity: 1;
    }
`;

export const LoaderContainerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100vh;
  background: rgba(23, 42, 63, 0.7);
  z-index: 1900;
  visibility: visible;
  animation: ${fadeIn} 0.5s linear;
  transition: visibility 0.5s linear;
`;

export const MissionPlannerLoaderContainerStyled = styled(
  LoaderContainerStyled
)`
  position: absolute;
  top: 207px;
  min-height: auto;
  background: transparent;
  z-index: 1;
`;

export const AsideLoaderContainerStyled = styled(LoaderContainerStyled)`
  position: absolute;
  background: transparent;
`;

export const WebsocetLoaderContainerStyled = styled.div`
  > div {
    position: absolute;
    left: auto;
    top: auto;
    right: 16px;
    bottom: 16px;
    width: 50px;
    height: 50px;
  }
`;

export const WebsocetLoaderLabel = styled.div`
  position: absolute;
  right: 75px;
  bottom: 30px;
  font-family: "Readex Pro", sans-serif;
  color: #172a3f;
`;

export const WebsocetLoaderMainStyled = styled.div`
  z-index: 1;
`;

export const LoaderStyled = styled.div`
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -50px 0 0 -50px;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: #172a3f;
  animation-name: ${spin};
  animation-duration: 2s;
  animation-iteration-count: infinite;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #172a3f;
    animation-name: ${spin};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  &::after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: #172a3f;
    animation-name: ${spin};
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
`;
