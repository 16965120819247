import styled from "styled-components";

export const ErrorTextStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 120%;
  font-family: "Readex Pro", sans-serif;
  color: #df2020;

  svg {
    margin-right: 4px;

    path {
      fill: #df2020;
    }
  }
`;
