import React, { memo, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getVehicleTypes } from "Slices/vehicleTypes";
import { toggleModal } from "Slices/modals";
import { createReorderHandler, EMPTY_ICON, OrderType } from "Utils";

import { List } from "../NewList";
import { RowData } from "../NewList/types";
import { ReactComponent as EditIcon } from "../common/assets/edit.svg";

const ListRowImgIcon = styled.img`
  width: 48px;
  height: 32px;
  object-fit: contain;
`;

const useVehicleTypeListReordering = (
  preparedVehicleTypesData: VehicleTypeListRowData[],
  setPreparedVehicleTypesData: React.Dispatch<
    React.SetStateAction<VehicleTypeListRowData[]>
  >
) => {
  const [manufacturerOrder, setManufacturerOrder] = useState<OrderType>(1);
  const [modelOrder, setModelOrder] = useState<OrderType>(1);

  const reorderOptions = useMemo(
    () => ({
      manufacturer: createReorderHandler(
        "manufacturer",
        preparedVehicleTypesData,
        setPreparedVehicleTypesData,
        manufacturerOrder,
        setManufacturerOrder
      ),
      model: createReorderHandler(
        "model",
        preparedVehicleTypesData,
        setPreparedVehicleTypesData,
        modelOrder,
        setModelOrder
      ),
    }),
    [preparedVehicleTypesData]
  );

  return reorderOptions;
};

type VehicleTypeListRowData = RowData & {
  image: JSX.Element;
  manufacturer: string;
  model: string;
};

export const VehicleTypesList = memo(() => {
  const dispatch = useDispatch();
  const vehicleTypes: Array<VehicleType> = useSelector(getVehicleTypes);
  const [preparedVehicleTypesData, setPreparedVehicleTypesData] = useState<
    VehicleTypeListRowData[]
  >([]);

  const reorderOptions = useVehicleTypeListReordering(
    preparedVehicleTypesData,
    setPreparedVehicleTypesData
  );

  const headerData = useMemo(
    () => ({
      image: "Image",
      manufacturer: "Manufacturer",
      model: "Model",
    }),
    []
  );

  const options = useMemo(
    () => ({
      icon: <EditIcon />,
      callback: (data: VehicleTypeListRowData) => {
        dispatch(
          toggleModal({
            type: "VEHICLE_TYPE_MODAL",
            data: { vehicleTypeId: data.id },
          })
        );
      },
    }),
    []
  );

  useEffect(() => {
    if (vehicleTypes.length === 0) return;

    const vehicleTypesData = vehicleTypes.map((vehicleType) => ({
      id: vehicleType.id,
      image: <ListRowImgIcon src={vehicleType?.image?.link || EMPTY_ICON} />,
      manufacturer: vehicleType.manufacturer,
      model: vehicleType.model,
    }));

    setPreparedVehicleTypesData(vehicleTypesData);
  }, [vehicleTypes]);

  return (
    <List
      data={preparedVehicleTypesData}
      headerData={headerData}
      options={options}
      reorderOptions={reorderOptions}
    />
  );
});
