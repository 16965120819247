import { updateVehicle } from "./actions";
import { getVehiclesUpdateData, getVehiclesUpdateDataArray } from "./selectors";
import VehiclesStateSlice from "./reducers";

//async actions
export { updateVehicle };

// actions
export const {
  resetVehicleStateData,
  updateVehiclesStateData,
  setVehiclesStateData,
} = VehiclesStateSlice.actions;

//selectors
export { getVehiclesUpdateData, getVehiclesUpdateDataArray };

//reducer
export default VehiclesStateSlice.reducer;
