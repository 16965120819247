import React, { memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { closeWarning, getWarning } from "Slices/warning";
import { storageKeys } from "Utils/localStorageUtils";
import { AbstractWarning } from "Components/Warning/AbstractWarning";

import {
  ActionButtonStyled,
  WarningButtonsContainerStyled,
  LogoutButtonStyled,
  ReloadPageButtonStyled,
} from "./styles";

export const Warning = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message, type } = useSelector(getWarning);

  const actionHandler = useMemo(() => {
    if (type === "primary") {
      return (
        <ActionButtonStyled
          onClick={() => {
            dispatch(closeWarning());
          }}
        >
          I acknowledge
        </ActionButtonStyled>
      );
    } else if (type === "error") {
      return (
        <WarningButtonsContainerStyled>
          <LogoutButtonStyled
            onClick={() => {
              window.localStorage.removeItem("JWT");
              window.localStorage.removeItem("JWTrefresh");
              navigate("/login");
            }}
          >
            Logout
          </LogoutButtonStyled>
          <ReloadPageButtonStyled
            onClick={() => {
              // try to prevent stuck in some 'bad' semantic map with wrong data
              localStorage.removeItem(storageKeys.CURRENT_SEMANTIC_MAP_ID);
              location.reload();
            }}
          >
            Reload page
          </ReloadPageButtonStyled>
        </WarningButtonsContainerStyled>
      );
    } else return null;
  }, [type]);

  if (!message) return <></>;

  return <AbstractWarning message={message} actionHandler={actionHandler} />;
});
