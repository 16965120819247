import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  getOperatorMissionOrder,
  getOperatorMissionStatus,
} from "Slices/operatorData";

import { StyledVehicleOnWayProps } from "../../types";
import {
  // EstimatedTimeBlockStyled,
  // TimeStyled,
  StatusNameStyled,
  VehicleOnWayBodyStyled,
  PlaceInQueueStyled,
  PlaceStyled,
} from "./styles";

declare global {
  interface Window {
    missionOperatorMockUpdates: boolean;
  }
}

const getSuffix = (digit: number) => {
  const tensRemainder = digit % 10;
  const thousandsRemainder = digit % 100;
  if (tensRemainder == 1 && thousandsRemainder != 11) {
    return "st";
  }
  if (tensRemainder == 2 && thousandsRemainder != 12) {
    return "nd";
  }
  if (tensRemainder == 3 && thousandsRemainder != 13) {
    return "rd";
  }
  return "th";
};

export const VehicleWayStatus = memo(() => {
  const operatorMissionStatus = useSelector(getOperatorMissionStatus);
  const operatorMissionOrder = useSelector(getOperatorMissionOrder);

  const vehiclePropsStyled: StyledVehicleOnWayProps = useMemo(() => {
    if (operatorMissionStatus === "IN_QUEUE") {
      return {
        background: "#e0c2a3",
        message: "Mission place in queue",
      };
    } else if (operatorMissionStatus === "IN_PROGRESS") {
      return {
        background: "#94d4e3",
        message: "Vehicle on its way!",
      };
    } else if (operatorMissionStatus === "NO_MISSION") {
      return {
        background: "#e9e9e9",
        message: "No Vehicles Called",
      };
    }
    return {
      background: "#e9e9e9",
      message: "Unsupported status",
    };
  }, [operatorMissionStatus]);

  const { background, message } = vehiclePropsStyled;

  return (
    <VehicleOnWayBodyStyled background={background}>
      <StatusNameStyled>{message}</StatusNameStyled>
      {operatorMissionStatus === "IN_QUEUE" &&
        Boolean(operatorMissionOrder) && (
          <PlaceInQueueStyled>
            <PlaceStyled>{operatorMissionOrder}</PlaceStyled>
            {getSuffix(operatorMissionOrder as number)}
          </PlaceInQueueStyled>
        )}
      {/*TODO: uncomment when backend will be done*/}
      {/*{vehicleOnWay && (*/}
      {/*  <EstimatedTimeBlockStyled>*/}
      {/*    Estimated time to pick up*/}
      {/*    <TimeStyled>{estimatedTime} sec</TimeStyled>*/}
      {/*  </EstimatedTimeBlockStyled>*/}
      {/*)}*/}
    </VehicleOnWayBodyStyled>
  );
});
