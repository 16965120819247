import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";

export const getVehiclesPageVehicles = (state: RootState) =>
  state.vehiclesPage.vehicles;
export const getFleetIdsRelatesToVehiclePage = createSelector(
  getVehiclesPageVehicles,
  (vehicles) => {
    if (vehicles.length === 0) return null;
    const fleetIds = vehicles.map(
      (vehicle) => vehicle.fleetId
    ) as Array<string>;
    return [...new Set(fleetIds)];
  }
);
