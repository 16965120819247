import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { ButtonComponent } from "../../common/ButtonComponent/Button.component";
import { DropdownComponent } from "../../common/DropdownComponent";
import { useSelector } from "react-redux";
import { getVehiclesByCurrentSemanticMap } from "Slices/vehicles";
import { getCurrentFleetId } from "Slices/missionPlanner";
import { Checkbox } from "../../common/CheckBoxComponent";

import { ReactComponent as AdditionalInfoIcon } from "../../common/assets/additionalInfo.svg";
import {
  MissionDetailsHelperTextStyled,
  InputStyled,
  MissionDetailsItemContentStyled,
  MissionDetailsItemStyled,
  MissionDetailsLabelStyled,
  MissionDetailsModalBodyWrapper,
  MissionDetailsModalButtonsStyled,
  MissionDetailsModalContentStyled,
  MissionDetailsModalDropdown,
  MissionDetailsModalStyled,
  MissionDetailsModalTextStyled,
  MissionDetailsTitleItemStyled,
  MissionDetailsModalHeaderWrapper,
} from "../styles";

type MissionsModalProps = {
  handleVehiclePick: (vehicleId: string, selectedLoopsValue: string) => void;
  handleFleetPick: (currentFleetId: string, selectedLoopsValue: string) => void;
  cancelAction: () => void;
  currentMissionDataStops: Array<string>;
  vehicleName: string;
  vehicleId: string | null;
};

export const AssignModal: FC<MissionsModalProps> = ({
  vehicleId,
  vehicleName,
  handleVehiclePick,
  handleFleetPick,
  cancelAction,
  currentMissionDataStops,
}) => {
  const [infinitelyLoop, setInfinitelyLoop] = useState<boolean>(false);
  const [loopsNumber, setLoopsNumber] = useState<string>("1");
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [selectedFleet, setSelectedFleet] = useState<string>();
  const vehiclesByCurrentSemanticMap: Vehicle[] = useSelector(
    getVehiclesByCurrentSemanticMap
  );
  const currentFleetId = useSelector(getCurrentFleetId);

  const currentVehicles = useMemo(
    () =>
      vehiclesByCurrentSemanticMap.filter(
        (vehicle) => vehicle.fleetId === currentFleetId
      ),
    [vehiclesByCurrentSemanticMap, currentFleetId]
  );
  const isOnlyOneStop = currentMissionDataStops.length === 1;

  useEffect(() => {
    if (isOnlyOneStop) {
      setLoopsNumber("1");
    }
  }, [isOnlyOneStop]);

  const selectVehicle = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
    setSelectedFleet("");
  };

  const deployMission = useCallback(() => {
    const selectedLoopsValue = infinitelyLoop ? "-1" : loopsNumber;

    if (vehicleId) {
      handleVehiclePick(vehicleId, selectedLoopsValue);
    }
    if (selectedVehicle) {
      handleVehiclePick(selectedVehicle.id, selectedLoopsValue);
    }
    if (selectedFleet && currentFleetId) {
      handleFleetPick(currentFleetId, selectedLoopsValue);
    }
  }, [
    selectedVehicle,
    currentFleetId,
    vehicleId,
    selectedFleet,
    loopsNumber,
    infinitelyLoop,
  ]);

  const isDeployButtonDisabled = useMemo(() => {
    const isLoopsFilled = loopsNumber || infinitelyLoop;
    const isMissionPlannerAssignedDataFilled = selectedVehicle || selectedFleet;
    const isRealTimeMapAssignedDataFilled = Boolean(vehicleId);
    const isAssignedDataFilled =
      isMissionPlannerAssignedDataFilled || isRealTimeMapAssignedDataFilled;

    return !isLoopsFilled || !isAssignedDataFilled;
  }, [loopsNumber, infinitelyLoop, selectedVehicle, selectedFleet, vehicleId]);

  return (
    <MissionDetailsModalStyled>
      <MissionDetailsModalContentStyled>
        {vehicleName && (
          <MissionDetailsModalHeaderWrapper>
            <MissionDetailsLabelStyled>
              You are planning a mission for
            </MissionDetailsLabelStyled>
            <MissionDetailsLabelStyled header bold>
              {vehicleName}
            </MissionDetailsLabelStyled>
          </MissionDetailsModalHeaderWrapper>
        )}
        <MissionDetailsModalBodyWrapper>
          <MissionDetailsTitleItemStyled>
            <MissionDetailsLabelStyled>
              Number of Times to Run Mission
            </MissionDetailsLabelStyled>
          </MissionDetailsTitleItemStyled>
          <MissionDetailsItemContentStyled>
            <MissionDetailsItemStyled>
              <InputStyled
                type="number"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
                min="1"
                disabled={isOnlyOneStop || infinitelyLoop}
                placeholder="No."
                value={isOnlyOneStop ? 1 : loopsNumber}
                onChange={(e) => setLoopsNumber(e.target.value)}
              />
              <Checkbox
                disabled={isOnlyOneStop}
                checked={infinitelyLoop}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setLoopsNumber("");
                  setInfinitelyLoop(event.target.checked);
                }}
                labelText="Infinitely Loop"
              />
            </MissionDetailsItemStyled>
          </MissionDetailsItemContentStyled>
          {isOnlyOneStop && (
            <MissionDetailsHelperTextStyled>
              <AdditionalInfoIcon />
              Single-stop missions can run only once
            </MissionDetailsHelperTextStyled>
          )}
          {!vehicleName && (
            <>
              <MissionDetailsModalTextStyled>
                Assign to vehicle
              </MissionDetailsModalTextStyled>
              <MissionDetailsModalDropdown>
                <DropdownComponent
                  placeholderValue={"Select a Vehicle"}
                  listData={currentVehicles}
                  currentItem={selectedFleet || selectedVehicle?.name || ""}
                  changeItemAction={selectVehicle}
                />
              </MissionDetailsModalDropdown>
            </>
          )}
        </MissionDetailsModalBodyWrapper>
        <MissionDetailsModalButtonsStyled>
          <ButtonComponent
            disabled={isDeployButtonDisabled}
            fontSize={14}
            marginBottom={0}
            width={120}
            clickAction={deployMission}
            type="primary"
          >
            Deploy Mission
          </ButtonComponent>
          <ButtonComponent
            fontSize={14}
            marginBottom={0}
            width={120}
            clickAction={cancelAction}
            type="cancel"
          >
            Cancel
          </ButtonComponent>
        </MissionDetailsModalButtonsStyled>
      </MissionDetailsModalContentStyled>
    </MissionDetailsModalStyled>
  );
};
