import styled from "styled-components";

export const VehiclesListStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 350px;
  overflow-y: auto;
`;

export const VehiclesListContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const VehiclesListEmptyImageStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  flex-direction: column;
`;

export const VehiclesListEmptyMessageStyled = styled.div`
  padding-top: 16px;
`;

export const SemanticMapPickerBodyStyled = styled.div`
  padding: 16px 18px;
  border-bottom: 1px solid #e9e9e9;
`;
