import styled from "styled-components";

export const DropDownContainerStyled = styled.div`
  flex: 1;
  margin: 0 auto;
  position: relative;
  height: 100%;
  min-width: 128px;
`;

export const DropDownHeaderStyled = styled.div<{
  isOpen: boolean;
  currentItem: string;
}>`
  padding: 6px 12px;
  font-weight: normal;
  font-size: 16px;
  color: ${(props) => (props.currentItem ? "#3b4256" : "#c4c4c4")};
  box-shadow: ${(props) =>
    props.isOpen ? "0px 0px 3px #253F73 !important;" : "none"};
  background: #f8fafc;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  }
`;

export const DropDownListContainerStyled = styled.div`
  position: absolute;
  width: 100%;
  top: 44px;
  z-index: 1;
`;

export const DropDownListStyled = styled.ul`
  margin: 0;
  background: #f8fafc;
  box-shadow: 0 0 3px #253f73;
  box-sizing: border-box;
  color: #3b4256;
  font-weight: normal;
  font-size: 16px;
  border-radius: 4px;
  height: 100%;
  padding: 8px 0;
  max-height: 400px;
  overflow: scroll;
`;

export const ListItemStyled = styled.li`
  list-style: none;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: #f8fafc;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s;
  &:hover {
    opacity: 0.8;
    background-color: rgba(167, 178, 199, 0.1);
  }
`;
