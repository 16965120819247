import styled from "styled-components";

export const InputStyled = styled.input`
  background: rgba(233, 233, 233, 0.5);
  height: 56px;
  width: 144px;
  font-size: 16px;
  color: #1b1b1b;
  padding: 24px 48px 24px 24px;
  border: none;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const GroupButtonStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 6px 0 -50px;
  width: 32px;
  height: 56px;
`;

export const AddValueButtonStyled = styled.div`
  height: 28px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }

  svg {
    position: relative;
    top: 10px;
    transform: rotate(180deg);
    height: 18px;
  }
`;

export const SubtractValueButtonStyled = styled.div`
  height: 28px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }

  svg {
    height: 18px;
  }
`;
