import React, { FC, memo } from "react";
import { useSelector } from "react-redux";

import { getIsStopsByCurrentSemanticMapEmpty } from "Slices/stops";
import { getOperatorMissionStatus } from "Slices/operatorData";
import { getIsStationEmpty } from "Slices/operatorStation";
import { FMSLogger } from "FMSLogger";
import { MISSION_OPERATOR_ACTIONS } from "Utils/constants";

import {
  EmptyErrorStyled,
  SelectedStationNameStyled,
  SelectedStationStyled,
  SelectStationButtonStyled,
} from "../StationPicker/styles";
import { ReactComponent as ChangeStationIcon } from "../../assets/change-station-icon.svg";

const missionOperatorLogger = FMSLogger.byPrefix(MISSION_OPERATOR_ACTIONS);

type PickedStation = Stop & { stationId: string | undefined };

type SelectedStationProps = {
  pickedStation: PickedStation | null;
  isSelectStationModalOpen: boolean;
  setSelectStationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SelectedStation: FC<SelectedStationProps> = memo(
  ({
    pickedStation,
    setSelectStationModalVisible,
    isSelectStationModalOpen,
  }) => {
    const isStopsEmpty = useSelector(getIsStopsByCurrentSemanticMapEmpty);
    const operatorMissionStatus = useSelector(getOperatorMissionStatus);
    const isStationEmpty = useSelector(getIsStationEmpty);

    const isOperatorMissionInWork = operatorMissionStatus !== "NO_MISSION";

    const buttonSelectStationTitle = pickedStation
      ? "Change Station"
      : "Select Station";

    return (
      <>
        Selected station:
        <SelectedStationStyled>
          {pickedStation && (
            <SelectedStationNameStyled>
              {pickedStation.name}
            </SelectedStationNameStyled>
          )}
          <SelectStationButtonStyled
            onClick={() => {
              missionOperatorLogger.debug("Stations picker has been opened");
              setSelectStationModalVisible(true);
            }}
            disabled={
              isSelectStationModalOpen ||
              isStopsEmpty ||
              (isOperatorMissionInWork && !isStationEmpty)
            }
            isPickedStation={Boolean(pickedStation)}
          >
            {buttonSelectStationTitle}
            {pickedStation && <ChangeStationIcon />}
          </SelectStationButtonStyled>
        </SelectedStationStyled>
        {isStopsEmpty && (
          <EmptyErrorStyled>Error: no mission stations!</EmptyErrorStyled>
        )}
      </>
    );
  }
);
