import React from "react";

import { ReactComponent as ErrorInfoIcon } from "Components/common/assets/errorInfoIcon.svg";
import { MissionPlansLoadingErrorStyled, TextStyled } from "./styles";

export const MissionPlansLoadingError = () => (
  <MissionPlansLoadingErrorStyled>
    <ErrorInfoIcon />
    <TextStyled>Error loading missions</TextStyled>
  </MissionPlansLoadingErrorStyled>
);
