import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  UserInfoContainerStyled,
  UserInfoImageContainerStyled,
  UserInfoMetaNameStyled,
  UserInfoDataContainerStyled,
} from "../../styles";
import {
  UserInfoMetaEditButtonStyled,
  UserInfoMetaValueContainerStyled,
  UserInfoPhoneInputStyled,
  UserInfoPhoneNumberValueStyled,
} from "./styles";
import { ReactComponent as PhoneNumberImage } from "../../assets/phoneNumberInfo.svg";

import {
  getCurrentUserPhoneNumber,
  getIsPhoneNumberUpdating,
  patchPhoneNumber,
} from "Slices/currentUser";

const editButtonStates = {
  edit: "edit",
  save: "save",
  saving: "saving...",
};

export const UserPhoneNumber = memo(() => {
  const dispatch = useDispatch();

  const phoneNumber = useSelector(getCurrentUserPhoneNumber);
  const isPhoneNumberUpdating = useSelector(getIsPhoneNumberUpdating);

  const [phoneNumberInputValue, setPhoneNumberInputValue] =
    useState(phoneNumber);
  const [editButtonContent, setEditButtonContent] = useState(
    editButtonStates.edit
  );

  useEffect(() => {
    if (isPhoneNumberUpdating) {
      setEditButtonContent(editButtonStates.saving);
    } else if (editButtonContent === editButtonStates.saving) {
      setPhoneNumberInputValue(phoneNumber);
      setEditButtonContent(editButtonStates.edit);
    }
  }, [isPhoneNumberUpdating]);

  const handleEditPhoneNumber = useCallback(() => {
    if (editButtonContent === editButtonStates.saving) return;

    if (editButtonContent === editButtonStates.edit) {
      setEditButtonContent(editButtonStates.save);
    } else {
      dispatch(patchPhoneNumber(phoneNumberInputValue));
    }
  }, [editButtonContent, setEditButtonContent, phoneNumberInputValue]);

  const handlePhoneInputChange = useCallback(
    (event) => {
      setPhoneNumberInputValue(event.target.value.replace(/[^0-9\-()+]/g, ""));
    },
    [setPhoneNumberInputValue]
  );

  const isUnderlinedEditButton = useMemo(
    () => !editButtonContent.includes(editButtonStates.saving),
    [editButtonContent]
  );

  return (
    <UserInfoContainerStyled>
      <UserInfoImageContainerStyled>
        <PhoneNumberImage />
      </UserInfoImageContainerStyled>
      <UserInfoDataContainerStyled>
        <UserInfoMetaNameStyled>Phone Number</UserInfoMetaNameStyled>
        <UserInfoMetaValueContainerStyled>
          {editButtonContent === editButtonStates.edit ? (
            phoneNumber && (
              <UserInfoPhoneNumberValueStyled>
                {phoneNumber}
              </UserInfoPhoneNumberValueStyled>
            )
          ) : (
            <UserInfoPhoneInputStyled
              maxLength={20}
              value={phoneNumberInputValue}
              onChange={handlePhoneInputChange}
            />
          )}
          <UserInfoMetaEditButtonStyled
            isUnderlined={isUnderlinedEditButton}
            onClick={handleEditPhoneNumber}
          >
            {editButtonContent}
          </UserInfoMetaEditButtonStyled>
        </UserInfoMetaValueContainerStyled>
      </UserInfoDataContainerStyled>
    </UserInfoContainerStyled>
  );
});
