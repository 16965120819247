import { LogExecutor } from "./LogExecutor";
import { PrefixedLogger } from "./PrefixedLogger";
import { downloadFile } from "./utils";

const levels = ["error", "warn", "info", "debug"] as const;

export type Level = (typeof levels)[number];
export type Prefix = string;
export type PrefixData = Prefix | Array<Prefix> | null;
export type ConfigurationData = {
  level: Level;
  prefixData: PrefixData;
};

export type OptionalConfigurationData = Partial<ConfigurationData>;

export interface LoggerCore {
  setLevel: (level: Level) => void;
  applyConfig: (configData: OptionalConfigurationData) => void;
  setPrefix: (prefixData: PrefixData) => void;
}

interface IFMSLogger extends LoggerCore {
  byPrefix: (prefix: Prefix) => PrefixedLogger;
  getConfig: () => void;
  downloadLogs: () => void;
  clearLogs: () => void;
}

export class FMSLogger implements IFMSLogger {
  logExecutor;

  constructor(sessionId: string) {
    this.logExecutor = new LogExecutor(sessionId);
  }

  setLevel(level: Level) {
    if (!levels.includes(level))
      throw Error(
        `Unsupported log level. Level can be only one of following values: ${levels}`
      );

    this.logExecutor.setLevel(level);
  }

  setPrefix(prefixData: PrefixData) {
    if (typeof prefixData === "undefined")
      throw Error(
        "Specify correct prefixData, it can be string or array of strings"
      );

    this.logExecutor.setPrefix(prefixData);
  }

  applyConfig(configData: OptionalConfigurationData) {
    if (configData.level && !levels.includes(configData.level))
      throw Error(
        `Unsupported log level. Level can be only one of following values: ${levels}`
      );

    this.logExecutor.applyConfig(configData);
  }

  getConfig() {
    console.log(this.logExecutor.getConfig());
  }

  byPrefix(prefix: Prefix) {
    return new PrefixedLogger(this.logExecutor, prefix);
  }

  downloadLogs() {
    const downloadLogs = (logs: Record<string, any>) => {
      const url = URL.createObjectURL(
        new Blob([JSON.stringify(logs)], {
          type: "application/json",
        })
      );
      downloadFile(url, `fms-logs-${new Date().toISOString()}.json`);
      URL.revokeObjectURL(url);
    };

    this.logExecutor.getLogs().then(downloadLogs);
  }

  clearLogs() {
    this.logExecutor.clearLogs();
  }
}
