import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { AuthenticationPage } from "Pages/Authentication/Authentication.page";
import { NewPasswordPage } from "Pages/NewPassword/NewPassword.page";
import { App } from "Pages/App/App";
import { AuthorizedApp } from "Pages/AuthorizedApp/AuthorizedApp";
import { TokenValidator } from "Pages/AuthorizedApp/TokenValidator";

import { AuthorizedRouter } from "./AuthorizedRouter";
// import RemoteControl from "Pages/RemoteControl";
// import CynemaComponent from "Pages/RemoteControl/components/CynemaComponent";

export const router = createBrowserRouter([
  {
    path: "",
    element: <App />,
    children: [
      {
        path: "",
        element: (
          <TokenValidator>
            <AuthorizedApp />
          </TokenValidator>
        ),
        children: AuthorizedRouter,
      },
      // {
      //   path: "/rc",
      //   element: <RemoteControl />,
      // },
      // {
      //   path: "/cynema",
      //   element: <CynemaComponent fullScreen={true} />,
      // },
      {
        path: "/login",
        element: <AuthenticationPage />,
      },
      {
        path: "/password_reset",
        element: <NewPasswordPage />,
      },
    ],
  },
]);
