import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Provider } from "react-redux";
import { store } from "./store";
import "./index.css";
import { router } from "./routers/MainRouter";
import { RouterProvider } from "react-router-dom";
import "FMSLogger";

const SENTRY_URL = window._env_.REACT_APP_SENTRY_URL;

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window._env_.REACT_APP_ENV || "development",
  });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);
