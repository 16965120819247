import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../common/ModalComponent/styles";
import { closeAnyModal } from "Slices/modals";

import { ContactSupportModalContentStyled } from "./styles";

const HUBSPOT_PORTAL_ID = window._env_.HUBSPOT_PORTAL_ID;
const HUBSPOT_FORM_ID = window._env_.HUBSPOT_FORM_ID;

export const ContactSupportModal = memo(() => {
  const dispatch = useDispatch();
  const handleCloseAction = () => dispatch(closeAnyModal());

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: HUBSPOT_PORTAL_ID,
          formId: HUBSPOT_FORM_ID,
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ContactSupportModalContentStyled id="hubspotForm"></ContactSupportModalContentStyled>
    </ModalStyled>
  );
});
