import { createAsyncThunk } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

import API from "Utils/api";
import { RootState } from "../..";

import {
  // setBackUrl,
  setExpirationDate,
  setLoginErrorText,
  setShowGlobalLoader,
} from ".";

type JwtDecode = {
  name: string;
  exp: number;
};

export const getLogin = createAsyncThunk(
  "appState/getLogin",
  async (
    userData: {
      email: string;
      password: string;
      navigateCallBack: NavigateFunction;
    },
    thunkAPI
  ) => {
    const { email, password, navigateCallBack } = userData;
    const state = thunkAPI.getState() as RootState;
    thunkAPI.dispatch(setShowGlobalLoader(true));
    await thunkAPI.dispatch(setLoginErrorText(""));
    try {
      const response = await API.post("/login/", {
        email,
        password,
      });
      window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
      window.localStorage.setItem(
        "JWTrefresh",
        `Bearer ${response.data.refresh}`
      );
      thunkAPI.dispatch(setShowGlobalLoader(false));
      navigateCallBack(state?.appState.backUrl || "/");
    } catch (error) {
      const baseError = error as BaseError;
      let errorMessage = "There was an error logging in.";
      if (baseError?.response?.status === 401) {
        errorMessage = "email or password is not correct";
      } else {
        try {
          const message = baseError.response.data.detail;
          if (message) {
            errorMessage = message;
          }
        } catch (e) {
          toast.error("There was an error in the response message.");
        }
      }
      await thunkAPI.dispatch(setLoginErrorText(errorMessage));
      thunkAPI.dispatch(setShowGlobalLoader(false));
    }
  }
);

export const refreshTokenAction = createAsyncThunk(
  "appState/fetchRefreshToken",
  async (navigateCallBack: () => void, thunkAPI) => {
    try {
      const currentRefreshToken = (
        window.localStorage.getItem("JWTrefresh") as string
      ).replace("Bearer ", "");
      const response = await API.post("/login/refresh/", {
        refresh: currentRefreshToken,
      });
      const newAccessToken = response.data.access;
      await thunkAPI.dispatch(
        setExpirationDate(
          newAccessToken
            ? jwt_decode<JwtDecode>(newAccessToken).exp * 1000
            : null
        )
      );
      window.localStorage.setItem("JWT", `Bearer ${newAccessToken}`);
    } catch (errorMessage) {
      setExpirationDate(null);
      window.localStorage.removeItem("JWT");
      window.localStorage.removeItem("JWTrefresh");
      navigateCallBack();
    }
  }
);

export const fetchCheckAccessToken = createAsyncThunk(
  "appState/fetchCheckAccessToken",
  async (navigateCallBack: () => void, thunkAPI) => {
    try {
      const currentAccessToken = (
        window.localStorage.getItem("JWT") as string
      ).replace("Bearer ", "");
      await API.post("/login/verify/", {
        token: currentAccessToken,
      });
      await thunkAPI.dispatch(
        setExpirationDate(
          currentAccessToken
            ? jwt_decode<JwtDecode>(currentAccessToken).exp * 1000
            : null
        )
      );
    } catch (err) {
      try {
        window.localStorage.removeItem("JWT");
        const currentRefreshToken = (
          window.localStorage.getItem("JWTrefresh") as string
        ).replace("Bearer ", "");
        const response = await API.post("/login/refresh/", {
          refresh: currentRefreshToken,
        });
        window.localStorage.setItem("JWT", `Bearer ${response.data.access}`);
        await thunkAPI.dispatch(
          setExpirationDate(
            response.data.access
              ? jwt_decode<JwtDecode>(response.data.access).exp * 1000
              : null
          )
        );
      } catch (errorMessage) {
        // uncomment if PMs or QAs will want to move this logic back
        // thunkAPI.dispatch(setBackUrl(window.location.pathname));
        navigateCallBack();
      }
    }
  }
);
