import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { arrayToObjectByField } from "Utils";
import { getCurrentSemanticMapId } from "../appState";
import { DataStatus } from "../../types";

export const getFleets = (state: RootState): Fleet[] => state.fleets.data;
export const getFleetsTransportFacilityModals = createSelector(
  getFleets,
  (fleets) => fleets.map(({ id, name }) => ({ id, text: name }))
);
export const getFleetsById = createSelector(getFleets, (fleets) =>
  arrayToObjectByField(fleets)
);
export const getFleetsByCurrentSemanticMap = createSelector(
  getFleets,
  getCurrentSemanticMapId,
  (fleets, currentSemanticMapId) =>
    fleets.filter(({ semanticMapId }) => semanticMapId === currentSemanticMapId)
);
const getFleetsStatus = (state: RootState) => state.fleets.status;

export const getIsFleetsLoaded = createSelector(
  getFleetsStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsFleetsLoadingError = createSelector(
  getFleetsStatus,
  (status) => status === DataStatus.failed
);
