import styled from "styled-components";

export const NavigationBarStyled = styled.div`
  height: 72px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: #172a3f;
  color: #bbbbbb;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid #5e6a79;
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  user-select: none;
  z-index: 14;
  position: relative;
`;

export const NavigationBarContainerStyled = styled.div`
  position: relative;
`;

type LogoContainerStyledProps = {
  isActive: boolean;
};

export const LogoContainerStyled = styled.div<LogoContainerStyledProps>`
  height: 100%;
  display: flex;
  width: 100px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: ${({ isActive }) => (isActive ? "pointer" : "default")};
`;

export const ContentContainerStyled = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
`;
