import React, { memo } from "react";

import { MissionOperator } from "Components/MissionOperator/MissionOperator";

import { MissionOperatorController } from "./MissionOperator.controller";
import { MissionOperatorLoader } from "./MissionOperator.loader";

export const MissionOperatorPage = memo(() => {
  return (
    <MissionOperatorLoader>
      <MissionOperatorController />
      <MissionOperator />
    </MissionOperatorLoader>
  );
});
