// the functionality is hidden until the implementation of the super admin role
import React, { useState, useCallback, useRef, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { ReactComponent as ChangeCompanyIcon } from "../../assets/changeCompany.svg";
import { ReactComponent as AboutIcon } from "../../assets/about.svg";
// import { ReactComponent as DjangoAdminIcon } from "../../assets/djangoAdmin.svg";
import { ReactComponent as LogOutIcon } from "../../assets/logOut.svg";
import {
  toggleModal,
  USER_MODAL,
  VERSION_MODAL,
  // CHANGE_COMPANY_MODAL,
} from "Slices/modals";
import { getCurrentUserName, getIsAdmin } from "Slices/currentUser";
import { useOnClickOutside } from "Utils/hooks";
import { UserAvatarComponent } from "../../../common/UserAvatarComponent";

import {
  UserInfoStyled,
  UserNameStyled,
  UserImageStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  UserInfoIconStyled,
} from "./styles";

interface DropdownConfigStyles {
  id: string;
  text: string;
  clickAction: () => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  onlyAdmin: boolean;
}

export const UserInfo = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userName = useSelector(getCurrentUserName);

  const isAdmin = useSelector(getIsAdmin);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setShowDropdown(false), []);
  useOnClickOutside(ref, handleClickOutside);

  const handleLogout = useCallback(() => {
    window.localStorage.removeItem("JWT");
    window.localStorage.removeItem("JWTrefresh");
    navigate("/login");
  }, []);

  const toggleVersionsModalAction = useCallback(() => {
    dispatch(toggleModal({ type: VERSION_MODAL }));
    setShowDropdown(false);
  }, []);

  const toggleUserModalAction = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      dispatch(toggleModal({ type: USER_MODAL }));
      setShowDropdown(false);
    },
    []
  );

  // const toggleChangeCompanyModalAction = useCallback(() => {
  //   dispatch(toggleModal({ type: CHANGE_COMPANY_MODAL }));
  //   setShowDropdown(false);
  // }, []);

  const dropdownConfig: Array<DropdownConfigStyles> = [
    // {
    //   id: "change_company",
    //   text: "Change Company",
    //   clickAction: toggleChangeCompanyModalAction,
    //   Icon: ChangeCompanyIcon,
    //   onlyAdmin: true,
    // },
    {
      id: "about",
      text: "About",
      clickAction: toggleVersionsModalAction,
      Icon: AboutIcon,
      onlyAdmin: false,
    },
    // {
    //   id: "django_admin",
    //   text: "Django Admin",
    //   clickAction: () =>
    //     window.open("/api/v1/admin/login/?next=/api/v1/admin/"),
    //   Icon: DjangoAdminIcon,
    //   onlyAdmin: true,
    // },
    {
      id: "logout",
      text: "Logout",
      clickAction: handleLogout,
      Icon: LogOutIcon,
      onlyAdmin: false,
    },
  ];

  return (
    <UserInfoStyled ref={ref}>
      <UserImageStyled onClick={toggleUserModalAction}>
        <UserAvatarComponent />
      </UserImageStyled>
      <UserNameStyled data-test-id="profile" onClick={toggleUserModalAction}>
        {userName}
      </UserNameStyled>
      <UserInfoIconStyled
        data-test-id="menu"
        active={showDropdown}
        onClick={() => setShowDropdown(!showDropdown)}
      />
      {showDropdown && (
        <DropdownContentStyled>
          {dropdownConfig.map(({ text, clickAction, Icon, onlyAdmin, id }) => {
            const onlyForAdmin = !isAdmin && onlyAdmin;

            if (onlyForAdmin) {
              return null;
            }

            return (
              <DropdownItemStyled
                data-test-id={id}
                key={id}
                onClick={clickAction}
              >
                <Icon />
                {text}
              </DropdownItemStyled>
            );
          })}
        </DropdownContentStyled>
      )}
    </UserInfoStyled>
  );
});
