import { RootState } from "../..";

export const getVehiclesUpdateData = (state: RootState) =>
  state.vehiclesState.data;

export const getVehiclesUpdateDataArray = (state: RootState) => {
  const dataArray = state.vehiclesState.data
    ? Object.values(state.vehiclesState.data)
    : null;
  return dataArray;
};
