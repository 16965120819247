import React, { memo, FC } from "react";
import {
  InputStyled,
  GroupButtonStyled,
  AddValueButtonStyled,
  SubtractValueButtonStyled,
} from "./styles";
import { ReactComponent as ArrowIcon } from "../../assets/arrowIcon.svg";

const stepCounter = 1000;

interface DigitalStepInputProps {
  inputValue: string;
  changeInputAction: (number: string) => void;
}

export const DigitalStepInput: FC<DigitalStepInputProps> = memo(
  ({ inputValue, changeInputAction }) => {
    const setWeight = (value: string) => {
      changeInputAction(value ? value : "");
    };
    const addValue = () => {
      changeInputAction(String(Number(inputValue) + stepCounter));
    };
    const subtractValue = () => {
      changeInputAction(
        Number(inputValue) > stepCounter
          ? String(Number(inputValue) - stepCounter)
          : ""
      );
    };

    return (
      <>
        <InputStyled
          type="number"
          placeholder="0"
          onChange={(e) => setWeight(e.target.value)}
          value={inputValue}
        />
        <GroupButtonStyled>
          <AddValueButtonStyled onClick={addValue}>
            <ArrowIcon viewBox="0 10 32 18" />
          </AddValueButtonStyled>
          <SubtractValueButtonStyled onClick={subtractValue}>
            <ArrowIcon viewBox="0 10 32 18" />
          </SubtractValueButtonStyled>
        </GroupButtonStyled>
      </>
    );
  }
);
