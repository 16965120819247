import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getIsShowUserModal,
  getIsShowVersionModal,
  getIsShowChangeCompanyModal,
  getIsShowAddMissionModal,
  getIsShowDeployedMissionListModal,
  getIsShowContactSupportModal,
  getIsShowGamepadSettingModal,
  getIsShowVehicleModal,
  getIsShowVehicleTypeModal,
  getIsShowFleetModal,
  getIsInviteUserModal,
  getIsInvitationSentModal,
  getIsUserDetailsModal,
  getIsConfirmActiveStatusUserModal,
  getIsConfirmActiveRoleUserModal,
  getIsConfirmChangeVehicleModal,
} from "Slices/modals";

import { VersionsModal } from "./VersionsModal";
import { UserModal } from "./UserModal";
import { ChangeCompanyModal } from "./ChangeCompanyModal";
import { AddMissionModal } from "./AddMissionModal";
import { DeployedMissionListModal } from "./DeployedMissionListModal";
import { ContactSupportModal } from "./ContactSupportModal";
import { GamepadSettingsModal } from "./GamepadSettingsModal";
import { VehicleModal } from "./ListDataPagesModals/VehicleModal";
import { VehicleTypeModal } from "./ListDataPagesModals/VehicleTypeModal";
import { FleetModal } from "./ListDataPagesModals/FleetModal";
import { ConfirmActionModal as ConfirmChangeUserStatusModal } from "./ConfirmActionModal";
import { ConfirmActionModal as ConfirmChangeUserRoleModal } from "./ConfirmActionModal";
import { ConfirmActionModal as ConfirmChangeVehicleModal } from "./ConfirmActionModal";
import { UserDetailsModal } from "./UserDetailsModal";
import { InvitationSentModal } from "./InvitationSentModal";
import { PeopleModal } from "./ListDataPagesModals/PeopleModal";

export const Modals = memo(() => {
  const showUserDetailsModal = useSelector(getIsUserDetailsModal);
  const showConfirmActiveStatusUserModal = useSelector(
    getIsConfirmActiveStatusUserModal
  );
  const showConfirmActiveRoleUserModal = useSelector(
    getIsConfirmActiveRoleUserModal
  );
  const showConfirmChangeVehicleModal = useSelector(
    getIsConfirmChangeVehicleModal
  );
  const isShowInvitationSentModal = useSelector(getIsInvitationSentModal);
  const isShowInviteUserModal = useSelector(getIsInviteUserModal);
  const showVehicleModal = useSelector(getIsShowVehicleModal);
  const showVehicleTypeModal = useSelector(getIsShowVehicleTypeModal);
  const showFleetModal = useSelector(getIsShowFleetModal);
  const showGamepadSettingModal = useSelector(getIsShowGamepadSettingModal);
  const showContactSupportModal = useSelector(getIsShowContactSupportModal);
  const showVersionsModal = useSelector(getIsShowVersionModal);
  const isShowUserModal = useSelector(getIsShowUserModal);
  const isShowChangeCompanyModal = useSelector(getIsShowChangeCompanyModal);
  const isShowAddMissionModal = useSelector(getIsShowAddMissionModal);
  const isShowDeployedMissionListModal = useSelector(
    getIsShowDeployedMissionListModal
  );

  return (
    <>
      {showUserDetailsModal && <UserDetailsModal />}
      {showConfirmActiveStatusUserModal && <ConfirmChangeUserStatusModal />}
      {showConfirmActiveRoleUserModal && <ConfirmChangeUserRoleModal />}
      {showConfirmChangeVehicleModal && <ConfirmChangeVehicleModal />}
      {isShowInvitationSentModal && <InvitationSentModal />}
      {isShowInviteUserModal && <PeopleModal />}
      {showVehicleModal && <VehicleModal />}
      {showVehicleTypeModal && <VehicleTypeModal />}
      {showFleetModal && <FleetModal />}
      {showGamepadSettingModal && <GamepadSettingsModal />}
      {showContactSupportModal && <ContactSupportModal />}
      {showVersionsModal && <VersionsModal />}
      {isShowUserModal && <UserModal />}
      {isShowChangeCompanyModal && <ChangeCompanyModal />}
      {isShowAddMissionModal && <AddMissionModal />}
      {isShowDeployedMissionListModal && <DeployedMissionListModal />}
    </>
  );
});
