import React, { memo, useState } from "react";

import { PeopleHeader } from "Components/ListPageHeader/PeopleHeader";
import {
  ListPageStyled,
  MainListContentStyled,
} from "Components/common/styles";
import { UsersList } from "Components/UsersList/UsersList";

import { UsersListLoader } from "./UsersList.loader";

export const UsersListPage = memo(() => {
  const [searchValue, setSearchValue] = useState<string>("");

  return (
    <UsersListLoader>
      <ListPageStyled>
        <PeopleHeader setSearchValue={setSearchValue} />
        <MainListContentStyled>
          <UsersList searchValue={searchValue} />
        </MainListContentStyled>
      </ListPageStyled>
    </UsersListLoader>
  );
});
