import React, { memo } from "react";

import { VehiclesListHeader } from "Components/ListPageHeader/VehiclesListHeader";
import { VehiclesList } from "Components/VehiclesList/VehiclesList";
import {
  MainListContentStyled,
  ListPageStyled,
} from "Components/common/styles";

import { VehiclesListController } from "./VehiclesList.controller";
import { VehiclesListLoader } from "./VehiclesList.loader";

export const VehiclesListPage = memo(() => (
  <VehiclesListLoader>
    <ListPageStyled>
      <VehiclesListHeader />
      <VehiclesListController />
      <MainListContentStyled>
        <VehiclesList />
      </MainListContentStyled>
    </ListPageStyled>
  </VehiclesListLoader>
));
