import React from "react";

import { ReactComponent as WarningIcon } from "../assets/warningIcon.svg";
import {
  UnhitchWarningBodyStyled,
  UnhitchTextWarningStyled,
  UnhitchWarningIconStyled,
} from "./styles";

export const UnhitchWarning = () => (
  <UnhitchWarningBodyStyled>
    <UnhitchWarningIconStyled>
      <WarningIcon />
    </UnhitchWarningIconStyled>
    <UnhitchTextWarningStyled>
      A mission can only have one unhitch event
    </UnhitchTextWarningStyled>
  </UnhitchWarningBodyStyled>
);
