import React, { memo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { closeAnyModal } from "Slices/modals";
import {
  getCompanies,
  fetchGetCompanies,
  getIsCompaniesLoaded,
} from "Slices/companies";
import {
  patchCurrentUserCompany,
  getCurrentUserCompanyName,
} from "Slices/currentUser";
import { setShowGlobalLoader } from "Slices/appState";

import {
  ChangeCompanyModalStyled,
  HeaderStyled,
  CompaniesContainerStyled,
  ItemContentStyled,
  CompanyNameStyled,
  SelectButtonStyled,
  CloseButtonStyled,
} from "./styles";
import { ReactComponent as CloseIcon } from "./assets/closeIcon.svg";

export const ChangeCompanyModal = memo(() => {
  const dispatch = useDispatch();
  const companies = useSelector(getCompanies);
  const companyName = useSelector(getCurrentUserCompanyName);
  const isCompaniesLoaded = useSelector(getIsCompaniesLoaded);

  const changeCompany = useCallback((companyId) => {
    dispatch(patchCurrentUserCompany(companyId));
    dispatch(closeAnyModal());
  }, []);

  // GET /companies
  useEffect(() => {
    if (isCompaniesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetCompanies());
  }, [isCompaniesLoaded]);

  // turn the loader off
  useEffect(() => {
    if (isCompaniesLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isCompaniesLoaded]);

  return (
    <ChangeCompanyModalStyled>
      <HeaderStyled>
        Change Company
        <CloseButtonStyled onClick={() => dispatch(closeAnyModal())}>
          Close <CloseIcon />
        </CloseButtonStyled>
      </HeaderStyled>
      <CompaniesContainerStyled>
        {companies.map(({ name, id }) => {
          if (companyName === name) {
            return <></>;
          }

          return (
            <ItemContentStyled key={id} onClick={() => changeCompany(id)}>
              <CompanyNameStyled>{name}</CompanyNameStyled>
              <SelectButtonStyled>Select</SelectButtonStyled>
            </ItemContentStyled>
          );
        })}
      </CompaniesContainerStyled>
    </ChangeCompanyModalStyled>
  );
});
