import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import { addNewFleet, setFleets } from ".";
import { setVehiclesPageFleets } from "../vehiclesPage";
import { updateVehicles } from "../vehicles";

export const fetchGetFleets = createAsyncThunk(
  "fleets/fetchGetFleets",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("fleets/");
      thunkAPI.dispatch(setVehiclesPageFleets(response.data.results));

      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostFleet = createAsyncThunk<void, FleetCore>(
  "fleets/fetchPostFleet",
  async (data: FleetCore, thunkAPI) => {
    try {
      const response = await API.post("/fleets/", { ...data });
      thunkAPI.dispatch(addNewFleet(response.data));

      const updatedVehicles = await API.get("vehicles/");
      thunkAPI.dispatch(updateVehicles(updatedVehicles.data.results));

      toast.success("Fleet added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchUpdateFleetById = createAsyncThunk<
  void,
  { id: string; data: FleetCore }
>(
  "fleets/fetchUpdateFleetById",
  async (fleet: { id: string; data: FleetCore }, thunkAPI) => {
    const { id, data } = fleet;
    try {
      await API.patch(`/fleets/${id}/`, data);

      const updatedVehicles = await API.get("vehicles/");
      thunkAPI.dispatch(updateVehicles(updatedVehicles.data.results));

      const getResponse = await API.get("fleets/");
      thunkAPI.dispatch(setFleets(getResponse.data.results));
      thunkAPI.dispatch(setVehiclesPageFleets(getResponse.data.results));
      toast.success(`Fleet ${data.name} was updated`);
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
