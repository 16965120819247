import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import {
  SecondaryNavBarItems,
  SecondaryNavigationBarStyled,
  SecondaryNavItemStyled,
  SecondaryNavTitleStyled,
} from "./styles";

interface SecondaryLinkItemType {
  id: string;
  text: string;
  link: string;
}

const linksConfig: Array<SecondaryLinkItemType> = [
  {
    id: "operator",
    text: "Operator View",
    link: "/operator",
  },
];

export const SecondaryNavBarMap: FC<{
  isSecondaryNavMapShown: boolean;
  setIsSecondaryNavMapShown: Dispatch<SetStateAction<boolean>>;
}> = memo(({ isSecondaryNavMapShown, setIsSecondaryNavMapShown }) => {
  const { pathname } = useLocation();

  return (
    <SecondaryNavigationBarStyled
      isShown={isSecondaryNavMapShown}
      onMouseEnter={() => {
        setIsSecondaryNavMapShown(true);
      }}
      onMouseLeave={() => {
        setIsSecondaryNavMapShown(false);
      }}
    >
      <SecondaryNavBarItems>
        {linksConfig.map(({ id, link, text }) => (
          <SecondaryNavItemStyled
            key={id}
            to={link}
            active={pathname === link}
            onClick={() => {
              setIsSecondaryNavMapShown(false);
            }}
          >
            <SecondaryNavTitleStyled>{text}</SecondaryNavTitleStyled>
          </SecondaryNavItemStyled>
        ))}
      </SecondaryNavBarItems>
    </SecondaryNavigationBarStyled>
  );
});
