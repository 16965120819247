import styled from "styled-components";

export const HelperTextStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
  font-size: 10px;
  line-height: 120%;
  font-family: "Readex Pro", sans-serif;
  color: #004ecc;

  svg {
    margin-right: 4px;
  }
`;
