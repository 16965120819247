import styled from "styled-components";

interface SelectStationProps {
  isPressed: boolean;
}

export const StationCheckboxStyled = styled.div<SelectStationProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 64px;
  background: ${(props) => (props.isPressed ? "#172a3f" : "#f4f4f4")};
  cursor: pointer;

  svg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;

export const StationName = styled.div<SelectStationProps>`
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: ${(props) => (props.isPressed ? "#ffffff" : "#172a3f")};
`;
