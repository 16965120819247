import styled from "styled-components";
import { UserInfoValueField } from "../../styles";

export const UserInfoPhoneNumberValueStyled = styled.div`
  ${UserInfoValueField};
  width: 100px;
`;

export const UserInfoPhoneInputStyled = styled.input`
  width: 100px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
`;

export const UserInfoMetaEditButtonStyled = styled.div`
  ${UserInfoValueField};
  text-decoration: ${(props) => (props.isUnderlined ? "underline" : "none")};
  margin-left: 4px;
  cursor: pointer;
`;

export const UserInfoMetaValueContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
`;
