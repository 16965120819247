import React, { FC, memo, useState } from "react";
import { ReactComponent as CheckIcon } from "../../assets/checkIcon.svg";
import { VehicleItemProps } from "../../types";
import { CheckIconBodyStyled, VehicleIcon, VehicleItemStyled } from "./styles";
import { FMSLogger } from "FMSLogger";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";
import { EMPTY_ICON } from "Utils";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

export const VehicleItem: FC<VehicleItemProps> = memo(
  ({ updateSelectedVehicles, vehicle, isSelected }) => {
    const [selected, setSelected] = useState(isSelected);

    const handleClick = () => {
      missionPlannerActionsLogger.debug(
        `Picked vehicle with id:${vehicle.id} selected:${!selected}`
      );
      updateSelectedVehicles(vehicle.id, !selected);
      setSelected(!selected);
    };

    return (
      <VehicleItemStyled onClick={handleClick} key={vehicle.id}>
        <CheckIconBodyStyled>{selected && <CheckIcon />}</CheckIconBodyStyled>
        <VehicleIcon src={vehicle.iconUri || EMPTY_ICON} />
        {vehicle.name}
      </VehicleItemStyled>
    );
  }
);
