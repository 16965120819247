import React, { FC } from "react";
import { useDispatch } from "react-redux";

import { toggleModal } from "Slices/modals";

import { ReactComponent as AddIcon } from "./assets/addIcon.svg";
import { ReactComponent as CountIcon } from "./assets/countIcon.svg";
import {
  ListPageHeaderStyled,
  HeaderTitleStyled,
  HeaderLinksAddButtonStyled,
  CountItemStyled,
} from "./styles";

type ListHeaderProps = {
  text: string;
  count: number;
  addText: string;
  modalType?: string;
};

export const TransportFacilityHeader: FC<ListHeaderProps> = ({
  text,
  count,
  addText,
  modalType,
}) => {
  const dispatch = useDispatch();

  return (
    <ListPageHeaderStyled>
      <HeaderTitleStyled>
        {text}
        <CountItemStyled>
          <CountIcon />
          {count}
        </CountItemStyled>
      </HeaderTitleStyled>
      {modalType && (
        <HeaderLinksAddButtonStyled
          onClick={() => dispatch(toggleModal({ type: modalType }))}
        >
          <AddIcon />
          {addText}
        </HeaderLinksAddButtonStyled>
      )}
    </ListPageHeaderStyled>
  );
};
