import { fetchMissionAssets } from "./actions";
import { getIsMissionAssetsEmpty, getCurrentMissionAsset } from "./selectors";
import OperatorMissionAssetsSlice from "./reducers";

// actions
export const { resetMissionOperatorAssets } =
  OperatorMissionAssetsSlice.actions;

// async actions
export { fetchMissionAssets };

// selectors
export { getIsMissionAssetsEmpty, getCurrentMissionAsset };

// reducer
export default OperatorMissionAssetsSlice.reducer;
