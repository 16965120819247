import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HornIcon } from "../../assets/horn-icon.svg";
import { CallVehicleButtonStyled, CallVehicleBodyStyled } from "./styles";

export const CallVehicleButton = memo(() => {
  const navigate = useNavigate();

  return (
    <CallVehicleBodyStyled>
      <CallVehicleButtonStyled onClick={() => navigate("/operator")}>
        Go to Operator View
        <HornIcon />
      </CallVehicleButtonStyled>
    </CallVehicleBodyStyled>
  );
});
