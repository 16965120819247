import React, { FC, memo, useMemo } from "react";

import { AbstractWarning } from "Components/Warning/AbstractWarning";

import {
  ActionButtonContainer,
  ActionButtonStyled,
  CancelButtonStyled,
} from "Components/Warning/styles";

const CHANGE_PICKED_ZONE_WARNING_MESSAGE =
  "You are reassigning vehicle(s) to a different fleet. In order to complete this change, please fully restart all reassigned vehicles";

type VehicleWarningProps = {
  pickedVehicles: Array<ModalItem>;
  handleCloseAction: () => void;
  handleSuccessAction: (pickedVehicles: Array<ModalItem>) => void;
};

export const VehicleWarning: FC<VehicleWarningProps> = memo(
  ({ pickedVehicles, handleCloseAction, handleSuccessAction }) => {
    const actionHandler = useMemo(() => {
      return (
        <ActionButtonContainer>
          <CancelButtonStyled
            onClick={() => {
              handleCloseAction();
            }}
          >
            cancel
          </CancelButtonStyled>
          <ActionButtonStyled
            onClick={() => {
              handleSuccessAction(pickedVehicles);
            }}
          >
            I acknowledge
          </ActionButtonStyled>
        </ActionButtonContainer>
      );
    }, [pickedVehicles]);

    return (
      <AbstractWarning
        message={CHANGE_PICKED_ZONE_WARNING_MESSAGE}
        actionHandler={actionHandler}
      />
    );
  }
);
