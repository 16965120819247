import styled from "styled-components";

export const OperatorSidePanelWrapperStyled = styled.div`
  width: 544px;
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  cursor: default;
  overflow-y: auto;
  border-right: 1px solid #d1d1d1;
`;

export const MissionOperatorBoardStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const PickerPaddingStyled = styled.div`
  padding: 32px 32px 21px;
  background: #f4f4f4;
  margin-bottom: 32px;
`;

export const LoadingStyled = styled.div`
  height: 41px;
`;
