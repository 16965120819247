import React, { FC, ReactElement } from "react";

import { ReactComponent as ActiveIcon } from "./assets/activeIcon.svg";
import { ReactComponent as DeactivatedIcon } from "./assets/deactivatedIcon.svg";

type UserStatusIconProps = {
  status?: string;
};

export interface IconsDictInterface {
  [key: string]: ReactElement;
}

const ICONS_DICT: IconsDictInterface = {
  active: <ActiveIcon />,
  deactivated: <DeactivatedIcon />,
};

export const UserStatusIcon: FC<UserStatusIconProps> = ({ status }) => {
  return <>{ICONS_DICT[status ?? ""] || ""}</>;
};
