import styled from "styled-components";

export const WeightInputStyled = styled.div<{
  withAdditionalInfo: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 12px;
  height: 56px;
  margin-bottom: ${(props) => (props.withAdditionalInfo ? "4px" : "16px")};
`;
