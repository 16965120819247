import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getCurrentSemanticMapId, setShowGlobalLoader } from "Slices/appState";
import {
  fetchGetFleets,
  getIsFleetsLoaded,
  getIsFleetsLoadingError,
} from "Slices/fleets";
import {
  fetchGetVehicleTypes,
  getIsVehicleTypesLoadingError,
} from "Slices/vehicleTypes";
import { getIsVehicleTypesLoaded } from "Slices/vehicleTypes";
import { setErrorWarning } from "Slices/warning";
import {
  fetchGetVehicles,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
} from "Slices/vehicles";
import { getServerErrorMessage } from "Utils";
import { ChildrenProps } from "Utils/types";
import { useStopsLoader } from "Utils/loaders";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isVehicleTypesLoadingError = useSelector(getIsVehicleTypesLoadingError);
  const isFleetsLoadingError = useSelector(getIsFleetsLoadingError);
  const isVehiclesLoadingError = useSelector(getIsVehiclesLoadingError);

  useEffect(() => {
    if (
      isVehicleTypesLoadingError ||
      isFleetsLoadingError ||
      isVehiclesLoadingError
    ) {
      const problemData = `
       ${isVehicleTypesLoadingError ? "VEHICLE TYPES" : ""}
       ${isFleetsLoadingError ? "FLEETS" : ""}
       ${isVehiclesLoadingError ? "VEHICLES" : ""}`.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [
    isVehicleTypesLoadingError,
    isFleetsLoadingError,
    isVehiclesLoadingError,
  ]);
};

export const MissionPlannerLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isVehicleTypesLoaded = useSelector(getIsVehicleTypesLoaded);
  const isFleetsLoaded = useSelector(getIsFleetsLoaded);
  const isVehiclesLoaded = useSelector(getIsVehiclesLoaded);

  useHandleDataLoadingErrors();

  // GET /vehicles
  useEffect(() => {
    if (isVehiclesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicles());
  }, [isVehiclesLoaded]);

  // GET /fleets
  useEffect(() => {
    if (isFleetsLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetFleets());
  }, [isFleetsLoaded]);

  const semanticMapId = useSelector(getCurrentSemanticMapId);
  const { isStopsLoaded, isStopsLoadingError } = useStopsLoader(semanticMapId);

  useEffect(() => {
    if (!isStopsLoadingError) return;

    dispatch(setShowGlobalLoader(false));
    dispatch(setErrorWarning(getServerErrorMessage("STOPS")));
  }, [isStopsLoadingError]);

  // stops loading
  useEffect(() => {
    if (isStopsLoaded) return;
    dispatch(setShowGlobalLoader(true));
  }, [isStopsLoaded]);

  // GET /vehicleTypes
  useEffect(() => {
    if (isVehicleTypesLoaded) return;
    dispatch(setShowGlobalLoader(true));
    dispatch(fetchGetVehicleTypes());
  }, [isVehicleTypesLoaded]);

  // turn the loader off
  useEffect(() => {
    if (
      isFleetsLoaded &&
      isVehicleTypesLoaded &&
      isStopsLoaded &&
      isVehiclesLoaded
    ) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isVehicleTypesLoaded, isFleetsLoaded, isStopsLoaded, isVehiclesLoaded]);

  return <>{children}</>;
};
