import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { setShowGlobalLoader } from "Slices/appState";
import { fetchGetUsers, getIsUsersLoaded } from "Slices/users";

import { ChildrenProps } from "Utils/types";
import { setErrorWarning } from "Slices/warning";
import { getServerErrorMessage } from "Utils";
import { getIsUsersLoadingError } from "Slices/users/selectors";

const useHandleDataLoadingErrors = () => {
  const dispatch = useDispatch();
  const isUsersLoadingError = useSelector(getIsUsersLoadingError);

  useEffect(() => {
    if (isUsersLoadingError) {
      const problemData = `
       ${isUsersLoadingError ? "USERS" : ""}
      `.trim();

      dispatch(setShowGlobalLoader(false));
      dispatch(setErrorWarning(getServerErrorMessage(problemData)));
    }
  }, [isUsersLoadingError]);
};

export const UsersListLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const isUsersLoaded = useSelector(getIsUsersLoaded);

  useHandleDataLoadingErrors();

  // GET /users
  useEffect(() => {
    if (!isUsersLoaded) {
      dispatch(setShowGlobalLoader(true));
      dispatch(fetchGetUsers());
    }
  }, []);

  // turn the loader off
  useEffect(() => {
    if (isUsersLoaded) {
      dispatch(setShowGlobalLoader(false));
    }
  }, [isUsersLoaded]);

  return <>{children}</>;
};
