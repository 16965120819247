import styled from "styled-components";

export const ListPageHeaderStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-family: "Readex Pro", sans-serif;
  font-size: 32px;
  line-height: 120%;
  padding: 0 24px;
`;

export const HeaderLinksContainerStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const CountItemStyled = styled.span`
  position: relative;
  margin-left: 8px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  svg {
    position: absolute;
    left: 2px;
    top: 0;
  }
`;

export const HeaderLinksAddButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  background: #3f7f7d;
  font-size: 16px;
  line-height: 128%;
  color: #ffffff;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-right: 8px;
  }
`;

export const HeaderTitleStyled = styled.div`
  display: flex;
  align-items: center;
  color: #1b1b1b;

  span {
    color: #3f7f7d;
  }

  svg,
  path {
    stroke: #3f7f7d;
  }
`;

export const InputContainerStyled = styled.div`
  position: relative;
  margin-right: 16px;

  svg {
    left: 12px;
    top: 12px;
    position: absolute;
  }
`;

export const InputStyled = styled.input`
  background: rgba(233, 233, 233, 0.5);
  height: 48px;
  width: 320px;
  font-size: 14px;
  line-height: 140%;
  color: #1b1b1b;
  padding: 18px 24px 18px 48px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: opacity 0.3s;

  &::placeholder {
    font-weight: 300;
    color: #606060;
  }
`;
