import React, { memo, FC, ReactNode } from "react";
import { useDispatch } from "react-redux";

import { Spinner } from "Components/Spinner/Spinner";
import { closeAnyModal } from "Slices/modals";

import { ReactComponent as CloseIcon } from "../../assets/closeIcon.svg";
import { ReactComponent as SubmitIcon } from "../../assets/submitIcon.svg";
import {
  HeaderCloseBtnStyled,
  ModalWrapperContentStyled,
  ModalWrapperFooterBtnStyled,
  ModalWrapperFooterStyled,
  ModalWrapperHeaderStyled,
  ModalWrapperStyled,
  SpinnerModalWrapperStyled,
} from "./styles";

interface ModalWrapperProps {
  children: ReactNode;
  headerText: string;
  actionButtonText: string;
  successAction?: () => void;
  isActionButtonDisabled?: boolean;
  isActionButtonLoading?: boolean;
}

export const ModalWrapper: FC<ModalWrapperProps> = memo(
  ({
    children,
    headerText,
    successAction,
    isActionButtonDisabled,
    isActionButtonLoading,
    actionButtonText,
  }) => {
    const dispatch = useDispatch();

    const handleCloseAction = () => {
      dispatch(closeAnyModal());
    };

    return (
      <ModalWrapperStyled>
        <ModalWrapperHeaderStyled>
          <span>{headerText}</span>
          <HeaderCloseBtnStyled onClick={handleCloseAction}>
            Close <CloseIcon />
          </HeaderCloseBtnStyled>
        </ModalWrapperHeaderStyled>
        <ModalWrapperContentStyled>{children}</ModalWrapperContentStyled>
        {successAction && (
          <ModalWrapperFooterStyled>
            <ModalWrapperFooterBtnStyled
              isDisabled={isActionButtonDisabled}
              onClick={successAction}
            >
              {actionButtonText}
              {isActionButtonLoading ? (
                <SpinnerModalWrapperStyled>
                  <Spinner color="white" />
                </SpinnerModalWrapperStyled>
              ) : (
                <SubmitIcon />
              )}
            </ModalWrapperFooterBtnStyled>
          </ModalWrapperFooterStyled>
        )}
      </ModalWrapperStyled>
    );
  }
);
