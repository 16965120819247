import React, { memo, FC, useMemo } from "react";
import { WeightInputStyled } from "./styles";
import { Checkbox } from "../Checkbox";
import { UnitsEnum } from "../../VehicleTypeModal";
import { DigitalStepInput } from "../DigitalStepInput";
import { HelperText } from "Components/common/HelperText/HelperText";

interface WeightInputProps {
  inputValue: string;
  checkboxValue: UnitsEnum;
  changeInputAction: (number: string) => void;
  changeCheckboxAction: (units: UnitsEnum) => void;
}

export const WeightInput: FC<WeightInputProps> = memo(
  ({ inputValue, checkboxValue, changeInputAction, changeCheckboxAction }) => {
    const checkboxOptions = useMemo(() => [UnitsEnum.Lbs, UnitsEnum.Kg], []);

    return (
      <>
        <WeightInputStyled withAdditionalInfo>
          <DigitalStepInput
            inputValue={inputValue}
            changeInputAction={changeInputAction}
          />
          <Checkbox
            checkboxOptions={checkboxOptions}
            checkboxValue={checkboxValue}
            changeCheckboxAction={changeCheckboxAction}
          />
        </WeightInputStyled>
        <HelperText additionalInfo="Please enter vehicle payload capacity and select the unit of mass" />
      </>
    );
  }
);
