import React, { memo } from "react";

import { MissionPlanner } from "Components/MissionPlanner/MissionPlanner";

import { MissionPlannerController } from "./MissionPlanner.controller";
import { MissionPlannerLoader } from "./MissionPlanner.loader";

export const MissionPlannerPage = memo(() => {
  return (
    <MissionPlannerLoader>
      <MissionPlannerController />
      <MissionPlanner />
    </MissionPlannerLoader>
  );
});
