import styled from "styled-components";

export const DropdownListItemStyled = styled.li`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    position: relative;
    top: 0;
    left: 0;
  }

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const DropdownListTextItemStyled = styled.div`
  color: #1b1b1b;
  line-height: 128%;
`;
