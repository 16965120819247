import { fetchInviteUser, fetchGetUsers, fetchPatchUser } from "./actions";
import {
  getUsers,
  getUsersStatus,
  getUsersById,
  getUsersFilteredByCurrentCompany,
  getIsUsersLoaded,
} from "./selectors";
import UsersSlice from "./reducers";

// actions
export const { resetUsers, updateUser, addNewUser } = UsersSlice.actions;

// async actions
export { fetchInviteUser, fetchGetUsers, fetchPatchUser };

// selectors
export {
  getUsers,
  getUsersStatus,
  getUsersById,
  getUsersFilteredByCurrentCompany,
  getIsUsersLoaded,
};

// reducer
export default UsersSlice.reducer;
