import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { getOperatorPickedStation } from "../operatorStation";
import { DataStatus } from "../../types";

const getOperatorMissionAssetsByStopId = (state: RootState) =>
  state.operatorMissionAssets.missionAssetsByStopId;
const getOperatorMissionAssetsStatus = (state: RootState) =>
  state.operatorMissionAssets.status;
export const getIsMissionAssetsLoaded = createSelector(
  getOperatorMissionAssetsStatus,
  (status) => status === DataStatus.succeeded
);

export const getIsMissionAssetsEmpty = createSelector(
  getOperatorMissionAssetsByStopId,
  getOperatorMissionAssetsStatus,
  (operatorMissionAssetsByStopId, status) =>
    status === DataStatus.succeeded &&
    operatorMissionAssetsByStopId &&
    Object.values(operatorMissionAssetsByStopId).length === 0
);

export const getCurrentMissionAsset = createSelector(
  getOperatorMissionAssetsByStopId,
  getOperatorPickedStation,
  (missionAssetsByStopId, pickedStation) => {
    if (!pickedStation) return null;
    return missionAssetsByStopId?.[pickedStation.id] ?? null;
  }
);
