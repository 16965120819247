import { createSlice } from "@reduxjs/toolkit";

import { fetchGetFleets } from "./actions";
import { DataStatus } from "../../types";

type FleetsState = {
  data: Array<Fleet>;
  status: DataStatus;
  error: string | null;
};

const initialState: FleetsState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "fleets",
  initialState,
  reducers: {
    resetFleets: () => initialState,
    addNewFleet: (state, { payload }) => {
      state.data = [payload, ...state.data];
      return state;
    },
    setFleets: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetFleets.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetFleets.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetFleets.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
