import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useIsFieldChanged } from "Utils/hooks";
import {
  fetchPostFleet,
  getFleetsById,
  fetchUpdateFleetById,
} from "Slices/fleets";
import {
  getVehiclesTransportFacilityModals,
  getVehiclesTransportFacilityModalsById,
} from "Slices/vehicles";
import {
  getModalData,
  closeAnyModal,
  CONFIRM_CHANGE_VECHICLE_MODAL,
  toggleModal,
} from "Slices/modals";
import { getMissionPlanModalItemsDictSemanticMapId } from "Slices/missionPlans";
import { getSemanticMapModalItems } from "Slices/semanticMaps/selectors";

import { Input } from "../components/Input";
import { ModalWrapper } from "../components/ModalWrapper";
import { Dropdown } from "../components/Dropdown";
import { MissionPlansPicker } from "./components/MissionsPicker";
import { SemanticMapWarning } from "./components/SemanticMapWarning";
import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../../common/ModalComponent/styles";
import { VehicleWarning } from "./components/VehicleWarning";
import { getLoadedMissionPlansSemanticMapIds } from "Slices/missionPlans/selectors";
import { useMissionPlansLoader } from "Utils/loaders";
import { SearchDropdown } from "../components/SearchDropdown";
import { sortModalItemsAlphabetically } from "Utils/collectionUtils";
import { setErrorWarning } from "Slices/warning";

const discrepancyVehiclesWarning =
  "You have a discrepancy between the vehicles in the fleet and the master list of vehicles. Please contact technical support.";

const useGetIsInitialLoadingPhase = (fleetSemanticMapId?: string) => {
  const [isInitialLoadingPhase, setIsInitialLoadingPhase] = useState(true);
  const loadedMissionPlansSemanticMapIds = useSelector(
    getLoadedMissionPlansSemanticMapIds
  );

  useEffect(() => {
    if (!fleetSemanticMapId || !isInitialLoadingPhase) return;

    if (loadedMissionPlansSemanticMapIds.includes(fleetSemanticMapId))
      setIsInitialLoadingPhase(false);
  }, [
    isInitialLoadingPhase,
    loadedMissionPlansSemanticMapIds,
    fleetSemanticMapId,
  ]);

  return { isInitialLoadingPhase };
};

export const FleetModal = memo(() => {
  const dispatch = useDispatch();

  const modalData = useSelector(getModalData);
  const fleetsById = useSelector(getFleetsById);
  const missionPlanModalItemsDictSemanticMapId = useSelector(
    getMissionPlanModalItemsDictSemanticMapId
  );
  const vehiclesById = useSelector(getVehiclesTransportFacilityModalsById);
  const editedFleet = modalData ? fleetsById[modalData.id] : null;
  const semanticMapModalItems = useSelector(getSemanticMapModalItems);
  const vehicles = useSelector(getVehiclesTransportFacilityModals);

  const pickedVehiclesInitial = useMemo(() => {
    if (!editedFleet) return [];

    const isEachFleetVehicleExists = editedFleet.vehiclesId.every(
      (id: string) => vehiclesById[id]
    );
    if (!isEachFleetVehicleExists) {
      dispatch(setErrorWarning(discrepancyVehiclesWarning));
      return [];
    }

    return editedFleet.vehiclesId.map((id: string) => ({
      ...vehiclesById[id],
    }));
  }, []);

  const [name, setName] = useState<string>(editedFleet?.name || "");
  const [pickedVehicles, setPickedVehicles] = useState<Array<ModalItem>>(
    sortModalItemsAlphabetically(pickedVehiclesInitial)
  );

  const [fleetSemanticMap, setFleetSemanticMap] = useState<ModalItem | null>(
    editedFleet
      ? semanticMapModalItems.find(
          (semanticMap) => semanticMap.id === editedFleet.semanticMapId
        ) || semanticMapModalItems[0]
      : null
  );

  const { isMissionPlansLoading, isMissionPlansLoadingError } =
    useMissionPlansLoader(fleetSemanticMap?.id);

  const missionPlanModalItems = useMemo(() => {
    return fleetSemanticMap
      ? missionPlanModalItemsDictSemanticMapId[fleetSemanticMap.id] ?? []
      : [];
  }, [missionPlanModalItemsDictSemanticMapId, fleetSemanticMap]);

  const [semanticMapWarningOptions, setSemanticMapWarningOptions] = useState<{
    isShown: boolean;
    fleetSemanticMap: ModalItem | null;
  }>({ isShown: false, fleetSemanticMap: null });

  const handleSetFleetSemanticMap = useCallback(
    (fleetSemanticMap: ModalItem | null) => {
      setSemanticMapWarningOptions({ isShown: true, fleetSemanticMap });
    },
    []
  );

  const handleCloseSemanticMapWarningAction = useCallback(() => {
    setSemanticMapWarningOptions({ isShown: false, fleetSemanticMap: null });
  }, []);

  const handleSuccessSemanticMapWarningAction = useCallback(
    (fleetSemanticMap: ModalItem | null) => {
      setReturnHomeMission(null);
      setPickedOnVehicleMissions([]);
      setFleetSemanticMap(fleetSemanticMap);
      setSemanticMapWarningOptions({ isShown: false, fleetSemanticMap: null });
    },
    []
  );

  const [vehicleWarningOptions, setVehicleWarningOptions] = useState<{
    isShown: boolean;
    pickedVehicles: Array<ModalItem>;
  }>({ isShown: false, pickedVehicles: pickedVehiclesInitial });

  const handleSetPickedVehicles = useCallback(
    (pickedVehicles: Array<ModalItem>) => {
      setVehicleWarningOptions({ isShown: true, pickedVehicles });
    },
    []
  );

  const handleCloseVehicleWarningAction = useCallback(() => {
    setVehicleWarningOptions({
      isShown: false,
      pickedVehicles: pickedVehiclesInitial,
    });
  }, []);

  const handleSuccessVehicleWarningAction = useCallback(
    (pickedVehicles: Array<ModalItem>) => {
      setPickedVehicles(sortModalItemsAlphabetically(pickedVehicles));
      setVehicleWarningOptions({
        isShown: false,
        pickedVehicles: pickedVehiclesInitial,
      });
    },
    []
  );

  const [pickedOnVehicleMissions, setPickedOnVehicleMissions] = useState<
    Array<ModalItem>
  >([]);

  const [returnHomeMission, setReturnHomeMission] = useState<ModalItem | null>(
    null
  );

  useEffect(() => {
    if (missionPlanModalItems.length === 0) return;

    const pickedOnVehicleMissions = sortModalItemsAlphabetically(
      missionPlanModalItems.filter((missionPlanModalItem) =>
        Object.values(editedFleet?.onVehicleMissionsData ?? {}).includes(
          missionPlanModalItem.id
        )
      )
    );
    setPickedOnVehicleMissions(pickedOnVehicleMissions);

    const returnHomeMission =
      missionPlanModalItems.find(
        ({ id }) => id === editedFleet?.returnHomeMission
      ) || null;
    setReturnHomeMission(returnHomeMission);
  }, [missionPlanModalItems]);

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const { isInitialLoadingPhase } = useGetIsInitialLoadingPhase(
    fleetSemanticMap?.id
  );

  const isFieldsChanged = useIsFieldChanged(
    [
      name,
      pickedVehicles,
      pickedOnVehicleMissions,
      returnHomeMission,
      fleetSemanticMap,
    ],
    !isInitialLoadingPhase
  );
  const isFormFilled = Boolean(
    name && pickedVehicles.length !== 0 && fleetSemanticMap
  );
  const isDisabled = editedFleet
    ? !isFieldsChanged || !isFormFilled
    : !isFormFilled;

  const vehiclesIds = useMemo(
    () => pickedVehicles.map((vehicle) => vehicle.id),
    [pickedVehicles]
  );

  const getOnVehicleMissionIds = () => {
    const pickedOnVehicleMissionIds = pickedOnVehicleMissions.map(
      (mission) => mission.id
    );
    return { ...pickedOnVehicleMissionIds };
  };

  const successFleetAction = useCallback(() => {
    if (isDisabled) return;
    const data = {
      name,
      semanticMapId: (fleetSemanticMap as ModalItem).id,
      vehiclesId: vehiclesIds,
      onVehicleMissionsData: pickedOnVehicleMissions.length
        ? getOnVehicleMissionIds()
        : {},

      returnHomeMission:
        returnHomeMission === null ? returnHomeMission : returnHomeMission?.id,
    };
    if (editedFleet) {
      dispatch(
        fetchUpdateFleetById({
          id: editedFleet.id,
          data,
        })
      );
    } else {
      dispatch(fetchPostFleet(data));
    }
    handleCloseAction();
  }, [
    name,
    vehiclesIds,
    isDisabled,
    pickedOnVehicleMissions,
    returnHomeMission,
    fleetSemanticMap,
  ]);

  const handlePatchVehicleWithConfirmation = useCallback(() => {
    dispatch(
      toggleModal({
        type: CONFIRM_CHANGE_VECHICLE_MODAL,
        data: {
          headerText: "Attention!",
          message:
            "You have unsaved changes on your fleet. Would you like to save them before leaving?",
          confirmButtonText: "Save",
          cancelButtonText: "Discard Changes",
          confirmAction: () => {
            successFleetAction();
          },
          cancelAction: () => {
            handleCloseAction();
          },
        },
      })
    );
  }, [successFleetAction]);

  const handleCloseActionGeneralModal = useCallback(() => {
    if (isFieldsChanged) {
      handlePatchVehicleWithConfirmation();
    } else {
      handleCloseAction();
    }
  }, [isFieldsChanged, name, pickedVehicles]);

  const sortedVehicles: Array<ModalItem> = useMemo(
    () => sortModalItemsAlphabetically(vehicles),
    [vehicles]
  );

  return (
    <>
      <ModalStyled>
        <ModalBackgroundStyled onClick={handleCloseActionGeneralModal} />
        <ModalWrapper
          headerText={`${modalData ? "Edit" : "Add"} Fleet`}
          actionButtonText={`${modalData ? "Save" : "Add Fleet"}`}
          successAction={successFleetAction}
          isActionButtonDisabled={isDisabled}
        >
          <Input
            isRequired={true}
            value={name}
            labelText="Name"
            additionalInfo="Please enter fleet name"
            changeAction={setName}
          />
          <Dropdown
            isIncludesEmptyValue
            isRequired
            currentValue={fleetSemanticMap}
            labelText="Zone"
            options={semanticMapModalItems}
            additionalInfo="Please select a Zone of operation for the fleet"
            changeAction={handleSetFleetSemanticMap}
          />
          <MissionPlansPicker
            isMissionPlansLoading={isMissionPlansLoading}
            isMissionPlansLoadingError={isMissionPlansLoadingError}
            missionPlans={missionPlanModalItems}
            returnHomeMission={returnHomeMission}
            setReturnHomeMission={setReturnHomeMission}
            pickedOnVehicleMissions={pickedOnVehicleMissions}
            setPickedOnVehicleMissions={setPickedOnVehicleMissions}
          />
          <SearchDropdown
            isRequired
            pickedItems={pickedVehicles}
            options={sortedVehicles}
            changeAction={handleSetPickedVehicles}
            labelText="Vehicle"
            placeholderText="Search Vehicle"
          >
            {vehicleWarningOptions.isShown && (
              <VehicleWarning
                pickedVehicles={vehicleWarningOptions.pickedVehicles}
                handleCloseAction={handleCloseVehicleWarningAction}
                handleSuccessAction={handleSuccessVehicleWarningAction}
              />
            )}
          </SearchDropdown>
        </ModalWrapper>
      </ModalStyled>
      {semanticMapWarningOptions.isShown && (
        <SemanticMapWarning
          fleetSemanticMap={semanticMapWarningOptions.fleetSemanticMap}
          handleCloseAction={handleCloseSemanticMapWarningAction}
          handleSuccessAction={handleSuccessSemanticMapWarningAction}
        />
      )}
    </>
  );
});
