import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";
import { RootState } from "../..";

import { getCurrentSemanticMapId } from "../appState";

export const fetchMissionAssets = createAsyncThunk(
  "operatorMissionAssets/fetchMissionAssets",
  async (_, thunkAPI) => {
    try {
      const semanticMapId = getCurrentSemanticMapId(
        thunkAPI.getState() as RootState
      );
      const response = await API.get(
        `/missions/operator_flow/?semantic_map=${semanticMapId}`
      );
      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
