import styled from "styled-components";

export const LabelStyled = styled.div`
  font-size: 14px;
  line-height: 140%;
  color: #606060;
  margin-bottom: 8px;
  display: flex;

  span {
    margin-left: 4px;
    color: #df2020;
  }
`;
