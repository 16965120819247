import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { validateEmail } from "Utils";
import { setShowGlobalLoader } from "../appState";
import API, { showNetworkErrorToast } from "Utils/api";
import { INVITATION_SENT_MODAL, toggleModal } from "../modals";

import { setCurrentUser } from "../currentUser";
import { addNewUser, updateUser } from ".";

type UserInviteFormCore = {
  firstName: string;
  lastName: string;
  email: string;
};

export const fetchInviteUser = createAsyncThunk<void, UserInviteFormCore>(
  "users/fetchInviteUser",
  async (data, thunkAPI) => {
    const { email } = data;
    if (!validateEmail(email)) {
      toast.error("Please enter valid email");
      return;
    }
    thunkAPI.dispatch(setShowGlobalLoader(true));
    try {
      const response = await API.post("/users/", data);
      thunkAPI.dispatch(addNewUser(response.data));
      thunkAPI.dispatch(
        toggleModal({ type: INVITATION_SENT_MODAL, data: email })
      );
      thunkAPI.dispatch(setShowGlobalLoader(false));
    } catch (error) {
      showNetworkErrorToast(error);
      thunkAPI.dispatch(setShowGlobalLoader(false));
      return Promise.reject();
    }
  }
);

export const fetchGetUsers = createAsyncThunk(
  "users/fetchGetUsers",
  async () => {
    try {
      const response = await API.get("users/");
      const isAllUsersHaveId = response.data.results.every(
        (user: User) => user?.id
      );
      if (!isAllUsersHaveId) {
        const uniqError = {
          config: {
            url: "users/",
            method: "GET",
          },
          response: { data: { detail: "no data" }, status: "200" },
        };
        throw uniqError;
      }

      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPatchUser = createAsyncThunk<
  void,
  {
    id: string;
    data: Partial<UserCore>;
    successMessage: string;
    isCurrentUser?: boolean;
  }
>(
  "users/fetchPatchUser",
  async ({ id, data, successMessage, isCurrentUser }, thunkAPI) => {
    try {
      const response = await API.patch(`users/${id}/`, data);
      thunkAPI.dispatch(updateUser(response.data));
      toast.success(successMessage);

      if (isCurrentUser) {
        thunkAPI.dispatch(setCurrentUser(response.data));
      }
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
