import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { DataStatus } from "../../types";

export const getCompanies = (state: RootState) => state.companies.data;

const getCompaniesStatus = (state: RootState) => state.companies.status;

export const getIsCompaniesLoaded = createSelector(
  getCompaniesStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsCompaniesLoadingError = createSelector(
  getCompaniesStatus,
  (status) => status === DataStatus.failed
);
