import React, { memo, useCallback } from "react";
import { useDispatch } from "react-redux";

import { setCurrentFleetId } from "Slices/missionPlanner";
import { FleetPicker } from "../../../common/FleetPicker";

import { FleetPickerPaddingStyled } from "./styles";

export const FleetPickerDropdown = memo(() => {
  const dispatch = useDispatch();

  const handleSelectedFleet = useCallback((fleetId: string | null) => {
    dispatch(setCurrentFleetId(fleetId));
  }, []);

  return (
    <FleetPickerPaddingStyled>
      <FleetPicker
        shouldUseSavedFleet={true}
        handleSelectedFleet={handleSelectedFleet}
      />
    </FleetPickerPaddingStyled>
  );
});
