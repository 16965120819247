import styled from "styled-components";

export const MarkerStyles: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

interface StopLabelProps {
  center?: boolean;
}

export const StopLabelStyled = styled.div<StopLabelProps>`
  font-family: "Readex Pro", sans-serif;
  line-height: 120%;
  color: #172a3f;
  position: absolute;
  width: max-content;
  font-weight: ${(props) => (props.center ? "400" : "500")};
  font-size: ${(props) => (props.center ? "20px" : "12px")};
  top: ${(props) => (props.center ? "20px;" : "-12px")};
`;

export const StopAverageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    position: absolute;
    top: 40px;
  }
`;
