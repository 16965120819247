import styled, { css } from "styled-components";

export const HelpDropdownStyled = styled.div`
  display: flex;
  box-sizing: border-box;
  margin-right: 24px;
  position: relative;
`;

interface HelpDropdownBtnProps {
  active: boolean;
}

export const HelpDropdownBtnStyled = styled.div<HelpDropdownBtnProps>`
  width: 35px;
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  cursor: pointer;
  color: ${(props) => (props.active ? "#55b8b3" : "#bbbbbb")};

  svg {
    margin-right: 18px;
    transition: all 0.3s;
  }

  path {
    fill: ${(props) => (props.active ? "#55b8b3" : "#bbbbbb")};
    transition: all 0.3s;
  }
`;

export const HelpDropdownContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  width: 200px;
  right: -10px;
  top: 58px;
  box-sizing: border-box;
  padding: 8px 0;
  z-index: 2;
  border-top: 2px solid #55b8b3;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`;

export const HelpDropdownItemStyled = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: #606060;
  text-decoration: none;
  box-sizing: border-box;
  padding-left: 18px;
  border-bottom: 1px solid transparent;
  transition: all 0.3s;

  path {
    transition: all 0.3s;
  }

  &:hover {
    color: #55b8b3;
    border-bottom: 1px solid #55b8b3;

    path {
      fill: #55b8b3;
    }
  }

  svg {
    margin-right: 8px;
  }
`;

export const HelpDropdownLinkStyled = styled.a`
  ${HelpDropdownItemStyled}
`;

export const HelpDropdownBtnItemStyled = styled.a`
  ${HelpDropdownItemStyled}
`;
