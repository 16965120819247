import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useOnClickOutside } from "Utils/hooks";
import { ReactComponent as SelectIcon } from "../assets/selectIconBold.svg";
import { getFleetsByCurrentSemanticMap } from "Slices/fleets";
import { storageKeys } from "Utils/localStorageUtils";

import {
  CurrentItemStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  FleetPickerDropdownStyled,
  FleetPickerWrapperStyled,
  PlaceholderStyled,
} from "./styles";

type FleetPickerProps = {
  initFleetId?: string;
  handleSelectedFleet: (id: string | null) => void;
  isPickerDisabled?: boolean;
  shouldUseSavedFleet?: boolean;
};

export const FleetPicker: FC<FleetPickerProps> = memo(
  ({
    initFleetId,
    handleSelectedFleet,
    isPickerDisabled = false,
    shouldUseSavedFleet = false,
  }) => {
    const fleets = useSelector(getFleetsByCurrentSemanticMap);
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    const handleClickOutside = useCallback(() => setShowDropdown(false), []);
    useOnClickOutside(ref, handleClickOutside);

    const isPickerEnabled = fleets.length > 1 && !isPickerDisabled;

    useEffect(() => {
      if (isPickerEnabled) return;

      setShowDropdown(false);
    }, [isPickerEnabled]);

    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<Fleet | null>(null);
    useEffect(() => {
      if (fleets.length === 0) {
        setSelectedItem(null);
        handleSelectedFleet(null);
        return;
      }

      const findFleetById = (fleetId: string | null) =>
        fleets.find((fleet) => fleet.id === fleetId);

      const firstFleet = fleets[0];
      let selectedFleet = firstFleet;

      if (initFleetId) {
        selectedFleet = findFleetById(initFleetId) ?? firstFleet;
      } else if (shouldUseSavedFleet) {
        selectedFleet =
          findFleetById(
            localStorage.getItem(storageKeys.MISSION_OPERATOR_SAVED_FLEET_ID)
          ) ?? firstFleet;
      }

      setSelectedItem(selectedFleet);
      handleSelectedFleet(selectedFleet.id);
    }, [fleets]);

    const selectedItemText = useMemo(() => {
      if (selectedItem === null) {
        return "...";
      } else {
        return selectedItem?.name;
      }
    }, [selectedItem]);

    const handleDropdownItemClick = (item: Fleet) => {
      localStorage.setItem(
        storageKeys.MISSION_OPERATOR_SAVED_FLEET_ID,
        item.id
      );
      setSelectedItem(item);
      handleSelectedFleet(item.id);
      setShowDropdown(false);
    };

    return (
      <FleetPickerWrapperStyled ref={ref} isDisabled={!isPickerEnabled}>
        <FleetPickerDropdownStyled
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
          active={showDropdown}
        >
          <div>
            <CurrentItemStyled>{selectedItemText}</CurrentItemStyled>
            <PlaceholderStyled>Fleet</PlaceholderStyled>
          </div>
          {isPickerEnabled && <SelectIcon />}
        </FleetPickerDropdownStyled>
        {isPickerEnabled && showDropdown && (
          <DropdownContentStyled>
            {fleets.map((fleet: Fleet) => (
              <DropdownItemStyled
                onClick={() => handleDropdownItemClick(fleet)}
                key={fleet.id}
              >
                {fleet.name}
              </DropdownItemStyled>
            ))}
          </DropdownContentStyled>
        )}
      </FleetPickerWrapperStyled>
    );
  }
);
