import React, { memo, FC } from "react";
import { ReactComponent as SelectedIcon } from "../../assets/selectedCheckboxImage.svg";
import { ReactComponent as NotSelectedIcon } from "../../assets/notSelectedCheckboxImage.svg";

import { DropdownListItemStyled, DropdownListTextItemStyled } from "./styles";

interface SearchDropdownListItemProps {
  item: ModalItem;
  isSelected: boolean;
  changeItemAction: (isSelected: boolean, item: ModalItem) => void;
}

export const SearchDropdownListItem: FC<SearchDropdownListItemProps> = memo(
  ({ item, isSelected, changeItemAction }) => (
    <DropdownListItemStyled onClick={() => changeItemAction(!isSelected, item)}>
      {isSelected ? <SelectedIcon /> : <NotSelectedIcon />}
      <DropdownListTextItemStyled>{item.text}</DropdownListTextItemStyled>
    </DropdownListItemStyled>
  )
);
