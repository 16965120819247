import { fetchGetCompanies } from "./actions";
import { getCompanies, getIsCompaniesLoaded } from "./selectors";
import CompaniesSlice from "./reducers";

// actions
export const { resetCompanies } = CompaniesSlice.actions;

// async actions
export { fetchGetCompanies };

// selectors
export { getCompanies, getIsCompaniesLoaded };

// reducer
export default CompaniesSlice.reducer;
