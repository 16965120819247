import {
  fetchGetFleets,
  fetchPostFleet,
  fetchUpdateFleetById,
} from "./actions";
import {
  getFleets,
  getFleetsTransportFacilityModals,
  getFleetsById,
  getFleetsByCurrentSemanticMap,
  getIsFleetsLoaded,
  getIsFleetsLoadingError,
} from "./selectors";
import FleetsSlice from "./reducers";

// actions
export const { addNewFleet, resetFleets, setFleets } = FleetsSlice.actions;

// async actions
export { fetchGetFleets, fetchPostFleet, fetchUpdateFleetById };

// selectors
export {
  getFleets,
  getFleetsTransportFacilityModals,
  getFleetsById,
  getFleetsByCurrentSemanticMap,
  getIsFleetsLoaded,
  getIsFleetsLoadingError,
};

// reducer
export default FleetsSlice.reducer;
