import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getCurrentUserCompanyName,
  getCurrentUserRole,
  getIsCurrentUserLoaded,
} from "Slices/currentUser";
import React from "react";
import { ChildrenProps } from "Utils/types";

export const AuthorizedAppGuard = ({ children }: ChildrenProps) => {
  const location = useLocation();
  const isCurrentUserLoaded = useSelector(getIsCurrentUserLoaded);
  const currentUserRole = useSelector(getCurrentUserRole);
  const companyName = useSelector(getCurrentUserCompanyName);
  const isLoggedIn = window.localStorage.getItem("JWT");

  if (!isLoggedIn) return <Navigate to="/login" />;

  const shouldNavigateToWelcome =
    (!companyName || currentUserRole === null) &&
    location.pathname !== "/welcome" &&
    isCurrentUserLoaded;

  if (shouldNavigateToWelcome) return <Navigate to="/welcome" />;

  return <>{children}</>;
};
