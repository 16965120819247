import React, { FC, useState } from "react";

import { validateEmail } from "Utils";
import {
  ForgotFormContainerStyled,
  TitleStyled,
  InputStyled,
  SubmitStyled,
  CancelStyled,
  ErrMsgStyled,
} from "./styles";

type ForgotPasswordProps = {
  onSubmit: (data: { email: string; callback: () => void }) => void;
  onCancel: () => void;
};

export const ForgotPasswordComponent: FC<ForgotPasswordProps> = ({
  onCancel,
  onSubmit,
}) => {
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [email, setEmail] = useState("");

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const currentEmail = email || "";

    setIsEmailInvalid(false);
    if (!currentEmail || !validateEmail(currentEmail)) {
      setIsEmailInvalid(true);
      return;
    }

    onSubmit({ email: currentEmail, callback: onCancel });
  };

  return (
    <ForgotFormContainerStyled onSubmit={handleSubmit}>
      <TitleStyled>Forgot Password</TitleStyled>
      {isEmailInvalid && <ErrMsgStyled>Valid email required.</ErrMsgStyled>}
      <InputStyled
        type="email"
        name="email"
        onChange={(e) => setEmail(e.currentTarget.value.toLowerCase())}
        placeholder="Email"
        value={email}
      />
      <SubmitStyled>SUBMIT</SubmitStyled>
      <CancelStyled onClick={onCancel}>CANCEL</CancelStyled>
    </ForgotFormContainerStyled>
  );
};
