import styled, { css, keyframes } from "styled-components";

const JustifyContentCenter = css`
  display: flex;
  justify-content: center;
`;

const JustifyContentStart = css`
  display: flex;
  justify-content: start;
`;

export const UserInfoValueField = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #333333;
`;

export const UserInfoContainerStyled = styled.div`
  ${JustifyContentStart};
  margin-bottom: 16px;
  max-height: 36px;
`;

export const UserInfoImageContainerStyled = styled.div`
  width: 36px;
  height: 36px;
  margin-right: 8px;
`;

const breatheAnimation = keyframes`
  0% {
    transform: translateX(400px);
  }
  100% {
    transform: translateX(0px);
  }
`;

export const UserModalContainerStyled = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: column;
  width: 333px;
  min-height: calc(100% - 48px);
  font-family: "Readex Pro", sans-serif;
  font-style: normal;
  background-color: #ffffff;
  cursor: default;
  animation-name: ${breatheAnimation};
  animation-duration: 0.4s;
`;

export const UserModalHeadContainerStyled = styled.div`
  padding: 32px 16px 16px 16px;
`;

export const UserModalBodyContainerStyled = styled.div`
  padding: 16px;
`;

export const UserAvatarStyled = styled.div`
  ${JustifyContentCenter};
  margin-bottom: 8px;
`;

export const UserNameStyled = styled.div`
  display: block;
  text-align: center;
  font-weight: 500;
  margin-bottom: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserInfoDataContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UserInfoMetaNameStyled = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 130%;
  color: #828282;
`;
