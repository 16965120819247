import { fetchStation, fetchPostStation } from "./actions";
import {
  getLoadedStationId,
  getIsStationEmpty,
  getOperatorPickedStation,
  getOperatorStations,
} from "./selectors";
import OperatorStationSlice from "./reducers";

// actions
export const { setStation, resetMissionOperatorStation } =
  OperatorStationSlice.actions;

// async actions
export { fetchStation, fetchPostStation };

// selectors
export {
  getLoadedStationId,
  getIsStationEmpty,
  getOperatorPickedStation,
  getOperatorStations,
};

// reducer
export default OperatorStationSlice.reducer;
