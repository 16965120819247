import styled from "styled-components";

export const AuthenticationContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button:focus {
    border: 2px solid #8fbdff;
  }
`;

export const LoggedInNotify = styled.div`
  background-color: #e4d44b;
`;
