import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";
import { sortCollectionByName } from "Utils/collectionUtils";

export const fetchGetSemanticMaps = createAsyncThunk(
  "semantic_maps/fetchGetSemanticMaps",
  async () => {
    try {
      const response = await API.get(
        "semantic_maps/?fields=id%2C%20name%2C%20bbox%2C%20track"
      );
      return sortCollectionByName(
        response.data.results
      ) as unknown as Array<SemanticMap>;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
