import styled from "styled-components";

interface HelpDatesIntervalButtonProps {
  active: boolean;
}

export const DatesIntervalButtonWrapperStyled = styled.div`
  padding: 32px 32px 21px;
  position: relative;
  cursor: pointer;
`;

export const DatesIntervalButtonDropdownStyled = styled.div<HelpDatesIntervalButtonProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    transform: rotate(90deg)
      ${(props) => (props.active ? "rotateX(0deg);" : "rotateX(180deg)")};
    transition: 0.3s;
  }
`;

export const CurrentIntervalStyled = styled.div`
  height: 16px;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: #606060;
`;

export const HeaderStyled = styled.div`
  font-family: "Readex Pro", sans-serif;
  color: #172a3f;
  font-size: 20px;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DatePickerButton = styled.div`
  width: 100px;
  position: absolute;
  right: 4px;
  top: 4px;
  background: #3f7f7d;
  font-size: 14px;
  padding: 8px 8px;
  font-family: "Readex Pro", sans-serif;
  text-align: center;
  color: #ffffff;
  line-height: 140%;
  cursor: pointer;

  &:hover {
    transition: opacity 0.3s;
    opacity: 0.7;
  }
`;

export const DatePickerBody = styled.div`
  position: fixed;
  left: 313px;
  top: 329px;
  z-index: 2;

  .DateRangePicker {
    flex-direction: column;

    .rdrMonthName {
      text-align: center;
      font-family: "Readex Pro", sans-serif;
      font-weight: 500;
      font-size: 14px;
      color: #1b1b1b;
    }

    .rdrWeekDay {
      font-family: "Readex Pro", sans-serif;
      font-weight: 300;
      font-size: 10px;
      color: #767676;
    }

    .rdrDayPassive .rdrDayNumber span {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: #a4a4a4;
    }

    .rdrDayNumber span {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      color: #494949;
    }

    .rdrDayToday .rdrDayNumber span:after {
      background: #3f7f7d;
    }

    .rdrDayEndOfMonth .rdrInRange,
    .rdrDayEndOfMonth .rdrStartEdge,
    .rdrDayEndOfWeek .rdrInRange,
    .rdrDayEndOfWeek .rdrStartEdge {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      right: 2px;
    }

    .rdrDayEndOfMonth .rdrDayInPreview,
    .rdrDayEndOfMonth .rdrDayStartPreview,
    .rdrDayEndOfWeek .rdrDayInPreview,
    .rdrDayEndOfWeek .rdrDayStartPreview {
      border-color: #3f7f7d;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .rdrDayStartOfMonth .rdrDayInPreview,
    .rdrDayStartOfMonth .rdrDayEndPreview,
    .rdrDayStartOfWeek .rdrDayInPreview,
    .rdrDayStartOfWeek .rdrDayEndPreview {
      border-color: #3f7f7d;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    .rdrDayInPreview {
      border-color: #3f7f7d;
    }

    .rdrDayStartPreview {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border-color: #3f7f7d;
    }

    .rdrDayEndPreview {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-color: #3f7f7d;
    }

    .rdrDayStartOfMonth .rdrInRange,
    .rdrDayStartOfMonth .rdrEndEdge,
    .rdrDayStartOfWeek .rdrInRange,
    .rdrDayStartOfWeek .rdrEndEdge {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 2px;
    }

    .rdrStartEdge {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .rdrEndEdge {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      font-weight: 600;
      font-size: 14px;
      color: #1b1b1b;
    }

    .rdrDefinedRangesWrapper {
      display: flex;
      justify-content: center;
      font-size: 14px;
      width: auto;
      border-right: none;
      border-bottom: solid 1px #e9e9e9;
      background: #cacfd6;

      .rdrStaticRanges {
        height: 44px;
        flex-direction: row;
        justify-content: flex-start;
        width: 664px;

        .rdrStaticRange {
          border-bottom: none;
          background: #cacfd6;

          .rdrStaticRangeLabel {
            padding: 10px 10px;
            font-family: "Readex Pro", sans-serif;
            color: #767676;
            font-weight: 300;
            line-height: 140%;

            &:hover {
              background: #cacfd6;
            }

            &:focus {
              font-weight: 500;
              color: #1b1b1b;
            }
          }
        }
      }
    }

    .rdrCalendarWrapper {
      background: #cacfd6;

      .rdrMonthAndYearPickers select {
        font-family: "Readex Pro", sans-serif;
        font-weight: 500;
        color: #1b1b1b;
        font-size: 14px;

        &:hover {
          background-color: #cacfd6;
        }
      }

      .rdrMonthAndYearWrapper {
        height: 64px;
        padding-top: 0;
        border-bottom: solid 1px #e9e9e9;

        .rdrNextPrevButton {
          background: #cacfd6;

          &:hover {
            background: #cacfd6;
          }
        }
      }
    }
  }
`;
