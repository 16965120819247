import React from "react";

import {
  CheckboxContainerStyled,
  HiddenCheckboxStyled,
  StyledCheckboxStyled,
  Icon,
  CheckboxLabelContainerStyled,
} from "./styles";

export const Checkbox = ({ checked, ...props }) => (
  <CheckboxLabelContainerStyled checked={checked}>
    <CheckboxContainerStyled>
      <HiddenCheckboxStyled checked={checked} {...props} />
      <StyledCheckboxStyled checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckboxStyled>
    </CheckboxContainerStyled>
    <span>{props.labelText}</span>
  </CheckboxLabelContainerStyled>
);
