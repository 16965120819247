import React, { memo } from "react";

import { ReactComponent as BlueVehicle } from "../../assets/blueVehicle.svg";
import { ReactComponent as GrayVehicle } from "../../assets/grayVehicle.svg";
import { ReactComponent as OrangeVehicle } from "../../assets/orangeVehicle.svg";
import { ReactComponent as RedVehicle } from "../../assets/redVehicle.svg";
import { ReactComponent as YellowVehicle } from "../../assets/yellowVehicle.svg";

interface VehicleIconInterface {
  vbStatus: string | null;
  webstate: string | null;
}

const ICONS_DICT: { [key: string]: JSX.Element } = {
  AVAILABLE: <BlueVehicle />,
  UNAVAILABLE: <OrangeVehicle />,
  REMOTE_CONTROL: <BlueVehicle />,
  CALL_FOR_TELEOP: <RedVehicle />,
  CALL_FOR_TECHNICIAN: <OrangeVehicle />,
};

export const VehicleIcon = memo(
  ({ vbStatus, webstate }: VehicleIconInterface) => {
    const webstateKey = webstate?.toUpperCase() || "";

    if (vbStatus?.toUpperCase() === "OVERRIDE") {
      return <YellowVehicle />;
    }
    return <>{ICONS_DICT[webstateKey] || <GrayVehicle />}</>;
  }
);
