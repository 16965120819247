import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { setShowGlobalLoader } from "Slices/appState";

import { ChildrenProps } from "Utils/types";

export const WelcomeLoader = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();

  // turn the loader off
  useEffect(() => {
    dispatch(setShowGlobalLoader(false));
  }, []);

  return <>{children}</>;
};
