import styled from "styled-components";

interface IconLoaderStyledProps {
  isDragActive: boolean;
}

export const IconLoaderBodyStyled = styled.div<IconLoaderStyledProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  margin-bottom: 16px;
  height: 164px;
  background: #f4f4f4;
  border: ${(props) =>
    props.isDragActive ? "3px dashed #3f7f7d" : "1px dashed #d1d1d1"};
`;

export const TextHelperStyled = styled.div`
  display: flex;
  padding: 19px 0 4px 0;
  gap: 4px;
  line-height: 128%;
`;

export const FileInputStyled = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

export const TextFormatLoaderStyled = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #606060;
`;

export const FilePickerStyled = styled.div`
  color: #3f7f7d;
  cursor: pointer;
`;
