import {
  getOperatorMissionStatus,
  getMissionOperatorPickedFleetId,
  getIsPickedFleetHasVehicles,
  getMissionOperatorFleetIdForSubscribe,
  getOperatorMissionOrder,
  getInProgressFleetMissions,
  getInQueueFleetMissions,
} from "./selectors";
import OperatorDataSlice from "./reducers";

// actions
export const {
  setInitialMissionOperatorData,
  setMissionOperatorFleetId,
  resetMissionOperatorData,
  updateMissionOperatorData,
} = OperatorDataSlice.actions;

// selectors
export {
  getOperatorMissionStatus,
  getMissionOperatorPickedFleetId,
  getIsPickedFleetHasVehicles,
  getMissionOperatorFleetIdForSubscribe,
  getOperatorMissionOrder,
  getInProgressFleetMissions,
  getInQueueFleetMissions,
};

// reducer
export default OperatorDataSlice.reducer;
