import React, { FC, memo, useMemo } from "react";

import { AbstractWarning } from "Components/Warning/AbstractWarning";

import {
  ActionButtonContainer,
  ActionButtonStyled,
  CancelButtonStyled,
} from "Components/Warning/styles";

const CHANGE_PICKED_ZONE_WARNING_MESSAGE =
  "You are about to change the Zone this fleet is associated to. All On Vehicle Missions will be erased. Please make sure to restart all vehicles in the fleet for this change.";

type SemanticMapWarningProps = {
  fleetSemanticMap: ModalItem | null;
  handleCloseAction: () => void;
  handleSuccessAction: (fleetSemanticMap: ModalItem | null) => void;
};

export const SemanticMapWarning: FC<SemanticMapWarningProps> = memo(
  ({ fleetSemanticMap, handleCloseAction, handleSuccessAction }) => {
    const actionHandler = useMemo(() => {
      return (
        <ActionButtonContainer>
          <CancelButtonStyled
            onClick={() => {
              handleCloseAction();
            }}
          >
            cancel
          </CancelButtonStyled>
          <ActionButtonStyled
            onClick={() => {
              handleSuccessAction(fleetSemanticMap);
            }}
          >
            I acknowledge
          </ActionButtonStyled>
        </ActionButtonContainer>
      );
    }, [fleetSemanticMap]);

    return (
      <AbstractWarning
        message={CHANGE_PICKED_ZONE_WARNING_MESSAGE}
        actionHandler={actionHandler}
      />
    );
  }
);
