export const VERSION_MODAL = "VERSION_MODAL";
export const USER_MODAL = "USER_MODAL";
export const CHANGE_COMPANY_MODAL = "CHANGE_COMPANY_MODAL";
export const ADD_MISSION_MODAL = "ADD_MISSION_MODAL";
export const DEPLOYED_MISSION_LIST_MODAL = "DEPLOYED_MISSION_LIST_MODAL";
export const CONTACT_SUPPORT_MODAL = "CONTACT_SUPPORT_MODAL";
export const GAMEPAD_SETTINGS_MODAL = "GAMEPAD_SETTINGS_MODAL";
export const VEHICLE_MODAL = "VEHICLE_MODAL";
export const VEHICLE_TYPE_MODAL = "VEHICLE_TYPE_MODAL";
export const FLEET_MODAL = "FLEET_MODAL";
export const INVITE_USER_MODAL = "INVITE_USER_MODAL";
export const INVITATION_SENT_MODAL = "INVITATION_SENT_MODAL";
export const USER_DETAILS_MODAL = "USER_DETAILS_MODAL";
export const CONFIRM_ACTIVE_STATUS_USER_MODAL =
  "CONFIRM_ACTIVE_STATUS_USER_MODAL";
export const CONFIRM_ACTIVE_ROLE_USER_MODAL = "CONFIRM_ACTIVE_ROLE_USER_MODAL";
export const CONFIRM_CHANGE_VECHICLE_MODAL = "CONFIRM_CHANGE_VECHICLE_MODAL";
