import { createSlice } from "@reduxjs/toolkit";

import { fetchGetVehicleTypes } from "./actions";
import { DataStatus } from "../../types";

type VehicleTypesState = {
  data: Array<VehicleType>;
  status: DataStatus;
  error: string | null;
};

const initialState: VehicleTypesState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "vehicleTypes",
  initialState,
  reducers: {
    resetVehicleTypes: () => initialState,
    addNew: (state, { payload }) => {
      state.data = [payload, ...state.data];
      return state;
    },
    updatedVehicleType: (state, { payload }) => {
      state.data = state.data.map((vehicleType) =>
        vehicleType.id === payload.id ? payload : vehicleType
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetVehicleTypes.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetVehicleTypes.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetVehicleTypes.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
