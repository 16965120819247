import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { DataStatus } from "../../types";
import { getCurrentSemanticMapId } from "Slices/appState";

const getMissionPlans = (state: RootState) => state.missionPlans.data;
export const getLoadedMissionPlansSemanticMapIds = (state: RootState) =>
  state.missionPlans.loadedSemanticMapIds;
export const getMissionPlanModalItemsDictSemanticMapId = createSelector(
  getMissionPlans,
  (missionPlans) => {
    return missionPlans.reduce<Record<string, Array<ModalItem>>>(
      (acc, missionPlan) => {
        const currentMissionPlanSemanticMapId = missionPlan["semanticMap"];

        if (acc[currentMissionPlanSemanticMapId]) {
          acc[currentMissionPlanSemanticMapId] = [
            ...acc[currentMissionPlanSemanticMapId],
            {
              id: missionPlan.id,
              text: missionPlan.name,
            },
          ];
        } else {
          acc[currentMissionPlanSemanticMapId] = [
            {
              id: missionPlan.id,
              text: missionPlan.name,
            },
          ];
        }
        return acc;
      },
      {}
    );
  }
);
const getMissionPlansStatus = (state: RootState) => state.missionPlans.status;
export const getIsMissionPlansLoaded = createSelector(
  getMissionPlansStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsMissionPlansLoading = createSelector(
  getMissionPlansStatus,
  (status) => status === DataStatus.loading
);
export const getIsMissionPlansLoadedForCurrentSemanticMap = createSelector(
  getIsMissionPlansLoaded,
  getCurrentSemanticMapId,
  getLoadedMissionPlansSemanticMapIds,
  (isMissionPlansLoaded, currentSemanticMapId, loadedSemanticMapIds) =>
    isMissionPlansLoaded &&
    loadedSemanticMapIds.includes(currentSemanticMapId as string)
);
export const getMissionsPlansByCurrentSemanticMap = createSelector(
  getMissionPlans,
  getCurrentSemanticMapId,
  (missionPlans, currentSemanticMapId) =>
    missionPlans.filter(
      (missionPlan) => missionPlan.semanticMap === currentSemanticMapId
    )
);
export const getIsMissionPlansLoadingError = createSelector(
  getMissionPlansStatus,
  (status) => status === DataStatus.failed
);
