import styled from "styled-components";

interface IStylesDictionary {
  [key: string]: string;
}

const BG_COLOR_DICT: IStylesDictionary = {
  primary: "#253F73;",
  cancel: "transparent;",
  cancelMission: "transparent",
  add: "#4C88FF;",
  success: "#4DAA00",
  disabled: "#C4C4C4",
};

const FONT_COLOR_DICT: IStylesDictionary = {
  primary: "#FFFFFF;",
  cancel: "#9A9AB0;",
  cancelMission: "#9A9AB0;",
  add: "#FFFFFF;",
  success: "#FFFFFF",
  disabled: "#FFFFFF",
};

const FONT_WEIGHT_DICT: IStylesDictionary = {
  primary: "normal",
  cancel: "normal",
  cancelMission: "normal",
  add: "normal",
};

const BORDER_DICT: IStylesDictionary = {
  primary: "2px solid transparent",
  cancel: "2px solid #C4C4C4;",
  cancelMission: "2px solid transparent",
  add: "2px solid transparent",
  disabled: "2px solid transparent",
};

export const ButtonComponentStyled = styled.button<{
  typeBtn: string;
  width?: number;
  fontSize: number;
  posCenter?: boolean;
  marginBottom: number;
}>`
  background-color: ${(props) =>
    props.disabled ? "#C4C4C4;" : BG_COLOR_DICT[props.typeBtn] || "#253f73"};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: ${(props) => (props.width ? `${props.width}px` : "212px")};
  min-width: ${(props) => (props.width ? `${props.width}px` : "212px")};
  height: 100%;
  max-height: 35px;
  padding: 15px 0;
  min-height: 32px;
  font-weight: ${(props) => FONT_WEIGHT_DICT[props.typeBtn] || "normal"};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "12px")};
  line-height: 22px;
  color: ${(props) => FONT_COLOR_DICT[props.typeBtn] || "#FFFFFF"};
  font-family: "Red Hat Display", sans-serif;
  outline: none;
  border: ${(props) => BORDER_DICT[props.typeBtn] || "none"};
  cursor: pointer;
  transition: all 0.2s;
  margin: ${(props) => (props.posCenter ? "0 auto" : "0")};
  margin-bottom: ${(props) =>
    props.marginBottom !== undefined ? `${props.marginBottom}px` : "12px"};

  &:hover {
    opacity: ${(props) => (props.disabled ? 0.7 : 0.9)};
  }
`;
