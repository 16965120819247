import styled from "styled-components";

export const UnhitchWarningBodyStyled = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 10px;
`;

export const UnhitchTextWarningStyled = styled.div`
  font-size: 14px;
  line-height: 140%;
`;

export const UnhitchWarningIconStyled = styled.div`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f0d1d1;
`;
