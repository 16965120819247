import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

export const ToastifyContainer = styled(ToastContainer)`
  z-index: 10000 !important;

  &&&.Toastify__toast-container {
    width: 330px;
  }

  .Toastify__toast-body {
    white-space: pre-line;
  }
`;
