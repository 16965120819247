import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../common/ModalComponent/styles";
import { closeAnyModal } from "Slices/modals";
import { getCurrentUserRole, getCurrentUserName } from "Slices/currentUser";

import { UserAvatar } from "./components/UserAvatar";
// import UserStats from "./components/UserStats";
import { UserInfo } from "./components/UserInfo";
import { UserPhoneNumber } from "./components/UserPhoneNumber";
import { DelimiterStyled } from "../../common/Delimeter/styles";
import { RoleLabel } from "../../common/RoleLabel";
import {
  UserModalContainerStyled,
  UserNameStyled,
  UserAvatarStyled,
  UserModalHeadContainerStyled,
  UserModalBodyContainerStyled,
} from "./styles";

export const UserModal = memo(() => {
  const dispatch = useDispatch();

  const userName = useSelector(getCurrentUserName);
  const userRole = useSelector(getCurrentUserRole);

  const handleCloseModal = () => {
    dispatch(closeAnyModal());
  };

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <UserModalContainerStyled>
        <UserModalHeadContainerStyled>
          <UserAvatarStyled>
            <UserAvatar />
          </UserAvatarStyled>
          <UserNameStyled>{userName}</UserNameStyled>
          <RoleLabel roleId={userRole} />
          {/*
          // Implement when task INFR-4249 will be ready
          <UserStats />
          */}
        </UserModalHeadContainerStyled>
        <DelimiterStyled delimiterColor="#e0e0e0" />
        <UserModalBodyContainerStyled>
          <UserInfo />
          <UserPhoneNumber />
        </UserModalBodyContainerStyled>
      </UserModalContainerStyled>
    </ModalStyled>
  );
});
