import { createSlice } from "@reduxjs/toolkit";

import { fetchGetSemanticMaps } from "./actions";
import { DataStatus } from "../../types";

type SemanticMapState = {
  data: Array<SemanticMap>;
  status: DataStatus;
  error: string | null;
};

const initialState: SemanticMapState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "semanticMaps",
  initialState,
  reducers: {
    resetSemanticMaps: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetSemanticMaps.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetSemanticMaps.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetSemanticMaps.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
