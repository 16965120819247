import React, { memo, FC, useState, useRef, useCallback } from "react";

import { useOnClickOutside } from "Utils/hooks";
import { LabelField } from "../LabelField";
import { HelperText } from "Components/common/HelperText/HelperText";
import { ErrorText } from "Components/common/ErrorText/ErrorText";

import { ReactComponent as ArrowIcon } from "../../assets/arrowIcon.svg";
import {
  DropdownBtnStyled,
  DropdownStyled,
  DropdownListContainerStyled,
  DropdownListStyled,
  DropdownListItemStyled,
  ListItemTextStyled,
} from "./styles";

interface DropdownProps {
  isIncludesEmptyValue?: boolean;
  currentValue: ModalItem | null;
  options: Array<ModalItem>;
  labelText: string;
  additionalInfo: string;
  changeAction: (dropdownItem: ModalItem | null) => void;
  isRequired?: boolean;
  isLoading?: boolean;
  isMissionPlansLoadingError?: boolean;
}

export const Dropdown: FC<DropdownProps> = memo(
  ({
    isIncludesEmptyValue,
    currentValue,
    labelText,
    additionalInfo,
    isRequired,
    changeAction,
    options,
    isMissionPlansLoadingError = false,
    isLoading = false,
  }) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const dropdownRef = useRef() as React.MutableRefObject<HTMLDivElement>;
    const currentValueId = currentValue?.id || null;
    const handleClickOutside = useCallback(() => setIsOpened(false), []);
    useOnClickOutside(dropdownRef, handleClickOutside);

    return (
      <>
        <LabelField labelText={labelText} isRequired={isRequired} />
        <DropdownStyled ref={dropdownRef}>
          <DropdownBtnStyled
            isLoading={isLoading}
            isOpened={isOpened}
            onClick={() => {
              if (!isLoading) setIsOpened(!isOpened);
            }}
          >
            <span>{currentValue?.text ?? "Empty"}</span>
            <ArrowIcon />
          </DropdownBtnStyled>
          {isOpened && (
            <DropdownListContainerStyled>
              <DropdownListStyled>
                {isIncludesEmptyValue && (
                  <DropdownListItemStyled
                    onClick={() => {
                      changeAction(null);
                      setIsOpened(false);
                    }}
                  >
                    Empty
                  </DropdownListItemStyled>
                )}
                {options.map((item, index) =>
                  item.id !== currentValueId ? (
                    <DropdownListItemStyled
                      key={`${item.id}-${index}`}
                      onClick={() => {
                        changeAction(item);
                        setIsOpened(false);
                      }}
                    >
                      <ListItemTextStyled>{item.text}</ListItemTextStyled>
                    </DropdownListItemStyled>
                  ) : null
                )}
              </DropdownListStyled>
            </DropdownListContainerStyled>
          )}
        </DropdownStyled>
        {isMissionPlansLoadingError ? (
          <ErrorText
            errorMessage={"An error occurred while loading missions data"}
          />
        ) : (
          <HelperText additionalInfo={additionalInfo} />
        )}
      </>
    );
  }
);
