import styled, { keyframes } from "styled-components";
import SatIcon from "Components/common/assets/sat-icon.svg";

export const breatheAnimation = keyframes`
  0% {
    transform: translateX(-350px);
  }
  100% {
    transform: translateX(0px);
  }
`;

export const WrapperStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  position: relative;
`;

export const SidebarStyled = styled.div`
  height: 100%;
  min-height: 500px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  animation-name: ${breatheAnimation};
  animation-duration: 0.4s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 4;
`;

export const MapWindowStyled = styled.div`
  flex: 1;
  width: 100%;
`;

export const ToggleSatButtonStyled = styled.div`
  position: absolute;
  right: 12px;
  bottom: 120px;
  background-color: #ffffff;
  padding: 12px;
  &:active {
    opacity: 0.5;
  }
  width: 24px;
  height: 24px;
  border: 1px solid #ffffff;
  cursor: pointer;
  background-image: url(${SatIcon});
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.9;
  }
`;
