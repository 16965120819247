export enum DataStatus {
  idle = "idle",
  loading = "loading",
  succeeded = "succeeded",
  failed = "failed",
}

export enum HitchType {
  UNSET = 0,
  HITCH = 1,
  UNHITCH = 2,
}
