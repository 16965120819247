import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";

import { FMSLogger } from "FMSLogger";
import { ToastifyContainer } from "Utils/toastifyUtils";
import { LOCATION_LOGS } from "Utils/constants";
import { Loader } from "Components/common/Loader";
import { getShowGlobalLoader } from "Slices/appState";

const locationLogger = FMSLogger.byPrefix(LOCATION_LOGS);

export const App = () => {
  const location = useLocation();
  const showGlobalLoader = useSelector(getShowGlobalLoader);

  useEffect(() => {
    locationLogger.info(location.pathname);
  }, [location]);

  return (
    <>
      {showGlobalLoader && <Loader />}
      <ToastifyContainer autoClose={5000} hideProgressBar={true} />
      <Outlet />
    </>
  );
};
