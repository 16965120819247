import styled from "styled-components";

export const VehicleStatusWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 272px;
  padding-left: 32px;
`;

export const VehicleStatusContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 14px;
`;

export const VehicleStatusTitleStyled = styled.div`
  display: flex;
  font-family: "Readex Pro", sans-serif;
  font-weight: 300;
  font-size: 16px;
  color: #172a3f;
`;

export const VehicleStatusCountStyled = styled.div<{
  countColor: string;
}>`
  display: flex;
  font-family: "Readex Pro", sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: ${({ countColor }) => countColor};
`;
