import React, { useState, useCallback, FC } from "react";
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import { getBackUrl, getLogin } from "Slices/appState";
import { BASE } from "Utils/api";
import { getLoginErrorText } from "Slices/appState/selectors";

import {
  SignInFormContainerStyled,
  TitleStyled,
  InputStyled,
  SubmitStyled,
  LoginErrMsgStyled,
  ForgotPasswordStyled,
  GoogleLoginButtonStyled,
} from "./styles";

type LoginProps = {
  onForgotSelected: () => void;
};

export const LoginComponent: FC<LoginProps> = ({ onForgotSelected }) => {
  const loginErrorText = useSelector(getLoginErrorText);
  const backUrl = useSelector(getBackUrl);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoginDataEmpty, setIsLoginDataEmpty] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onGoogleLoginSuccess = useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      response = response as GoogleLoginResponse;
      if (!response.tokenId) {
        toast.error("Invalid google response, token required");
        return;
      }

      const idToken = response.tokenId;

      const data = {
        email: response.profileObj.email,
        first_name: response.profileObj.givenName,
        last_name: response.profileObj.familyName,
      };

      const headers = {
        Authorization: idToken,
        "Content-Type": "application/json",
      };

      const responseAuth = await axios.post(
        `${BASE}/login/GoogleOauth2/`,
        data,
        {
          headers,
        }
      );

      if (responseAuth.data.access) {
        window.localStorage.setItem(
          "JWT",
          `Bearer ${responseAuth.data.access}`
        );
        window.localStorage.setItem(
          "JWTrefresh",
          `Bearer ${responseAuth.data.refresh}`
        );

        navigate(backUrl || "/");
      } else {
        toast.error(
          "Invalid username/password. Try again or click Forgot password to reset it."
        );
      }
    },
    [dispatch, navigate]
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoginDataEmpty(false); // clear out errors on each submit

    if (!email || !password) {
      setIsLoginDataEmpty(true);
      return;
    }

    dispatch(getLogin({ email, password, navigateCallBack: navigate }));
  };

  const GOOGLE_CLIENT_ID = window._env_.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <SignInFormContainerStyled onSubmit={handleSubmit}>
      <TitleStyled>Sign In</TitleStyled>
      {isLoginDataEmpty && (
        <LoginErrMsgStyled>Username and password required.</LoginErrMsgStyled>
      )}
      <InputStyled
        type="email"
        name="email"
        onChange={(e) => setEmail(e.currentTarget.value.toLowerCase())}
        placeholder="Email"
        value={email}
      />
      <InputStyled
        type="password"
        name="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
        placeholder="Password"
        value={password}
      />
      <SubmitStyled>SUBMIT</SubmitStyled>
      {GOOGLE_CLIENT_ID && (
        <GoogleLoginButtonStyled
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Sign in with Google"
          onSuccess={onGoogleLoginSuccess}
          onFailure={(e) => console.log("Google sign in error", e)}
        />
      )}
      <ForgotPasswordStyled onClick={onForgotSelected}>
        FORGOT PASSWORD
      </ForgotPasswordStyled>
      {loginErrorText && (
        <LoginErrMsgStyled>{loginErrorText}</LoginErrMsgStyled>
      )}
    </SignInFormContainerStyled>
  );
};
