import styled from "styled-components";

interface CheckboxBtnProps {
  isActive: boolean;
}

export const CheckboxBtnStyled = styled.div<CheckboxBtnProps>`
  width: 56px;
  height: 56px;
  background: ${(props) => (props.isActive ? "#253f73" : "#ffffff")};
  color: ${(props) => (props.isActive ? "#ffffff" : "#606060")};
  font-weight: 500;
  font-size: 14px;
  border: 1px solid ${(props) => (props.isActive ? "#253f73" : "#e9e9e9")};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    opacity: 0.9;
  }
`;

export const CheckboxStyled = styled.div`
  display: flex;
  gap: 4px;
`;
