import React, { FC, ReactNode } from "react";
import { ButtonComponentStyled } from "./styles";

type ButtonComponentProps = {
  clickAction: () => void;
  children?: ReactNode;
  type: string;
  posCenter?: boolean;
  disabled?: boolean;
  marginBottom: number;
  width?: number;
  fontSize: number;
};

export const ButtonComponent: FC<ButtonComponentProps> = ({
  clickAction,
  children,
  type,
  posCenter,
  disabled,
  marginBottom,
  width,
  fontSize,
}) => {
  return (
    <ButtonComponentStyled
      typeBtn={type}
      onClick={clickAction}
      posCenter={posCenter}
      disabled={disabled}
      marginBottom={marginBottom}
      width={width}
      fontSize={fontSize}
      type="button"
    >
      {children}
    </ButtonComponentStyled>
  );
};
