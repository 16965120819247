import styled from "styled-components";

export const OnlineLabelStyled = styled.div<{
  online?: boolean;
}>`
  font-weight: 300;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #494949;
  position: relative;

  &::before {
    content: "";
    background: ${(props) => (props.online ? "#55b8b3" : "#d9d9d9")};
    width: 10px;
    height: 10px;
    margin-right: 4px;
    left: -10px;
  }
`;
