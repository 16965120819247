import React, { memo, FC } from "react";
import { useSelector } from "react-redux";

import { getRolesById } from "Slices/roles";

import { RoleContainerStyled, RoleStyled } from "./styles";

type RoleLabelProps = {
  roleId?: number | null;
};

export const RoleLabel: FC<RoleLabelProps> = memo(({ roleId }) => {
  const rolesById = useSelector(getRolesById);
  if (!roleId || !rolesById || !rolesById[roleId]) return null;

  return (
    <RoleContainerStyled>
      <RoleStyled>{rolesById[roleId].name}</RoleStyled>
    </RoleContainerStyled>
  );
});
