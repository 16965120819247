import styled from "styled-components";

export const ModalWrapperStyled = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  width: 593px;
  height: 100vh;
  top: 0;
  left: 0;
  font-family: "Readex Pro", sans-serif;
`;

export const ModalWrapperHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 24px 32px;
  justify-content: space-between;
  font-size: 20px;
  line-height: 120%;
  color: #172a3f;
`;

export const ModalWrapperFooterStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 32px;
`;

export const SpinnerModalWrapperStyled = styled.div`
  width: 24px;
  height: 24px;
`;

export const ModalWrapperFooterBtnStyled = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 56px;
  width: 100%;
  background: ${(props) => (props.isDisabled ? "#A4A4A4" : "#3f7f7d")};
  font-size: 20px;
  color: #ffffff;
  transition: all 0.3s;
  cursor: ${(props) => (props.isDisabled ? "default" : "pointer")};

  &:hover {
    opacity: ${(props) => (props.isDisabled ? "1" : "0.9")};
  }
`;

export const ModalWrapperContentStyled = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 177px);
  overflow-y: auto;
  padding: 24px 32px;
  border-top: 1px solid #e9e9e9;
  flex: 1;
`;

export const HeaderCloseBtnStyled = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #606060;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 8px;
  }
`;
