import React, { Dispatch, FC, memo, SetStateAction } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getCurrentUserCompanyName, getIsAdmin } from "Slices/currentUser";
import { ReactComponent as MapIcon } from "../../assets/mapIcon.svg";
import { ReactComponent as MissionsIcon } from "../../assets/missions.svg";
import { ReactComponent as AssetsIcon } from "../../assets/assetsIcon.svg";
import { ReactComponent as PeopleIcon } from "../../assets/peopleIcon.svg";
import {
  LinksContainerStyled,
  LinksItemStyled,
  LinksItemContentStyled,
  LinksItemTextStyled,
} from "./styles";
import { FMSLogger } from "FMSLogger";
import { NAVIGATION_BAR_ACTIONS } from "Utils/constants";
import { getIsMissionOperator } from "Slices/currentUser/selectors";

const navigationBarActionsLogger = FMSLogger.byPrefix(NAVIGATION_BAR_ACTIONS);

interface LinkItemType {
  id: string;
  text: string;
  link: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  onlyAdmin?: boolean;
  onlyMissionOperator?: boolean;
}

const linksConfig: Array<LinkItemType> = [
  {
    id: "map",
    text: "Map",
    link: "/",
    Icon: MapIcon,
    onlyAdmin: true,
  },
  {
    id: "operator",
    text: "Map",
    link: "/operator",
    Icon: MapIcon,
    onlyMissionOperator: true,
  },
  {
    id: "missions",
    text: "Missions",
    link: "/missions",
    Icon: MissionsIcon,
    onlyAdmin: false,
  },
  {
    id: "assets",
    text: "Assets",
    link: "/vehicles",
    Icon: AssetsIcon,
    onlyAdmin: true,
  },
  {
    id: "people",
    text: "People",
    link: "/people",
    Icon: PeopleIcon,
    onlyAdmin: true,
  },
];

export const LinksContainer: FC<{
  setIsSecondaryNavAssetsShown: Dispatch<SetStateAction<boolean>>;
  setIsSecondaryNavMapShown: Dispatch<SetStateAction<boolean>>;
}> = memo(({ setIsSecondaryNavAssetsShown, setIsSecondaryNavMapShown }) => {
  const { pathname } = useLocation();
  const isAdmin = useSelector(getIsAdmin);
  const isMissionOperator = useSelector(getIsMissionOperator);
  const companyName = useSelector(getCurrentUserCompanyName);

  return (
    <LinksContainerStyled>
      {linksConfig.map(
        ({ id, text, link, Icon, onlyAdmin, onlyMissionOperator }) => {
          const active: boolean = pathname === link;

          if ((!isAdmin && onlyAdmin) || !companyName) {
            return null;
          }

          if (!isMissionOperator && onlyMissionOperator) return null;

          if (id === "map") {
            return (
              <LinksItemStyled
                data-test-id={id}
                key={id}
                to={link}
                active={active}
                onClick={() => {
                  navigationBarActionsLogger.debug(
                    `go to ${link} page via navigation bar`
                  );
                  setIsSecondaryNavMapShown(false);
                }}
                onMouseEnter={() => {
                  setIsSecondaryNavMapShown(true);
                }}
                onMouseLeave={() => {
                  setIsSecondaryNavMapShown(false);
                }}
              >
                <LinksItemContentStyled active={active}>
                  <Icon />
                  <LinksItemTextStyled>{text}</LinksItemTextStyled>
                </LinksItemContentStyled>
              </LinksItemStyled>
            );
          }

          if (id === "assets") {
            const assetsPaths = ["/vehicles", "/vehicletypes", "/fleets"];
            const isAssetsActive = assetsPaths.includes(pathname);
            return (
              <LinksItemStyled
                data-test-id={id}
                key={id}
                to={link}
                active={isAssetsActive}
                onClick={() => {
                  setIsSecondaryNavAssetsShown(false);
                }}
                onMouseEnter={() => {
                  setIsSecondaryNavAssetsShown(true);
                }}
                onMouseLeave={() => {
                  setIsSecondaryNavAssetsShown(false);
                }}
              >
                <LinksItemContentStyled active={isAssetsActive}>
                  <Icon />
                  <LinksItemTextStyled>{text}</LinksItemTextStyled>
                </LinksItemContentStyled>
              </LinksItemStyled>
            );
          }

          return (
            <LinksItemStyled
              data-test-id={id}
              key={id}
              to={link}
              active={active}
              onClick={() => {
                navigationBarActionsLogger.debug(
                  `go to ${link} page via navigation bar`
                );
              }}
            >
              <LinksItemContentStyled active={active}>
                <Icon />
                <LinksItemTextStyled>{text}</LinksItemTextStyled>
              </LinksItemContentStyled>
            </LinksItemStyled>
          );
        }
      )}
    </LinksContainerStyled>
  );
});
