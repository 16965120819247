import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { prepareVehiclesData } from ".";
import { EMPTY_ARRAY, sortByColumn } from "Utils";

type VehiclesDataState = {
  missionsByVehicleId: Record<string, Mission>;
  driveModeStatusesByVehicleId: Record<string, VehicleState["t_drive_mode"]>;
  vehicles: Array<VehiclesPageVehicle>;
  fleets: Array<Fleet>;
  vehicleTypes: Array<VehicleType>;
};

const initialState: VehiclesDataState = {
  missionsByVehicleId: {},
  driveModeStatusesByVehicleId: {},
  vehicles: EMPTY_ARRAY,
  fleets: [],
  vehicleTypes: [],
};

export const Slice = createSlice({
  name: "vehiclesPage",
  initialState,
  reducers: {
    resetVehiclesPageData: () => initialState,
    setVehiclesPageMissions: (state, action) => {
      state.missionsByVehicleId = action.payload
        .reverse()
        .reduce((acc: Record<string, Mission>, item: Mission) => {
          acc[item.vehicle] = item;
          return acc;
        }, {});
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageVehicles: (state, action) => {
      state.vehicles = prepareVehiclesData({
        ...state,
        vehicles: action.payload,
      });
      return state;
    },
    setVehiclesPageFleets: (state, action) => {
      state.fleets = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypeWithNewTypes: (state, action) => {
      state.vehicleTypes = [action.payload, ...state.vehicleTypes];
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypeWithUpdatedTypes: (state, action) => {
      state.vehicleTypes = state.vehicleTypes.map((vehicleType) =>
        vehicleType.id === action.payload.id ? action.payload : vehicleType
      );
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageTypes: (state, action) => {
      state.vehicleTypes = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    updateVehiclesPageMissions: (state, action) => {
      const updatedMission = action.payload;
      if (!updatedMission.vehicle) return state;

      const missionsByVehicleId = state.missionsByVehicleId;

      if (missionsByVehicleId[updatedMission.vehicle]) {
        missionsByVehicleId[updatedMission.vehicle] = {
          ...missionsByVehicleId[updatedMission.vehicle],
          status: updatedMission.status,
          name: updatedMission.name,
        };
      } else {
        missionsByVehicleId[updatedMission.vehicle] = { ...updatedMission };
      }

      state.missionsByVehicleId = missionsByVehicleId;
      state.vehicles = prepareVehiclesData(state);

      return state;
    },
    updateVehicleDriveModeStatus: (state, action) => {
      try {
        const currentDriveMode =
          state.driveModeStatusesByVehicleId[action.payload.vehicle_id];
        const newDriveMode = action.payload.t_drive_mode;
        if (currentDriveMode !== newDriveMode) {
          state.driveModeStatusesByVehicleId[action.payload.vehicle_id] =
            action.payload.t_drive_mode;
          state.vehicles = prepareVehiclesData(state);
        }
      } catch (e) {
        toast.error("Can not update drive mode status");
      }

      return state;
    },
    setInitialDriveModeStatuses: (state, action) => {
      state.driveModeStatusesByVehicleId = action.payload;
      state.vehicles = prepareVehiclesData(state);
      return state;
    },
    setVehiclesPageSorting: (state, action) => {
      state.vehicles = sortByColumn(
        state.vehicles,
        action.payload.columnName,
        action.payload.order
      );
      return state;
    },
  },
});

export default Slice;
