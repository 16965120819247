import styled from "styled-components";

export const UserDetailsModalStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const AvatarWrapperStyled = styled.div`
  width: 284px;
  height: 284px;
  margin-bottom: 12px;
`;

export const NameBodyStyled = styled.div`
  color: #1b1b1b;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  margin-bottom: 8px;
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
  }
`;

export const NameStyled = styled.div`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
`;

export const InfoStyled = styled.div`
  color: #1b1b1b;
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

interface StatusChangeButtonInterface {
  isActive: boolean;
}

export const StatusChangeButtonStyled = styled.div<StatusChangeButtonInterface>`
  background: ${({ isActive }) => (isActive ? "#df2020 " : "#ffffff")};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #df2020;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  color: ${({ isActive }) => (isActive ? "#ffffff" : "#df2020")};
  opacity: 1;
  transition: all 0.3s;
  height: 48px;
  width: 100%;

  &:hover {
    ${({ isActive }) => (isActive ? "opacity: 0.8" : "background: #f2f2f2")};
  }
`;

export const MessageBodyStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const MessageStyled = styled.div`
  color: #1b1b1b;
`;
