import styled from "styled-components";

type BGColor = {
  [key: string]: string;
};

const BG_COLOR: BGColor = {
  AUTONOMOUS: "#008000",
  ARMED: "#008000",
  AVAILABLE: "#008000",
  ACTIVE: "#008000",
  AUTO: "#253F73",
  RUNNING: "#008000",
  MANUAL: "#253F73",
  "MANUAL DRIVER": "#253F73",
  "REMOTE CONTROL": "#253F73",
  STOPPING: "#rgba(255, 0, 0, 0.6)",
  DISARMED: "#rgba(255, 0, 0, 0.6)",
  UNAVAILABLE: "rgba(255, 0, 0, 0.6)",
  IDLE: "#8F8F8F",
  OVERRIDE: "#8F8F8F",
  CALL_FOR_TECHNICIAN: "#8B0000",
  CALL_FOR_TELEOP: "#8B0000",
};

interface VehicleItemInterface {
  showDetails: boolean;
}

export const VehicleItemStyled = styled.div<VehicleItemInterface>`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 8px 16px;
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #000000;
  min-height: ${(props) => (props.showDetails ? "min-content" : "56px")};
  height: min-content;
  transition: all 0.2s;

  button {
    width: 100% !important;
  }

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

export const DetailsBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-left: 66px;
`;

export const TopContentStyled = styled.div`
  display: flex;
  min-height: 56px;
  justify-content: space-between;
`;

export const TopLeftContentStyled = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

export const VehicleItemLineStyled = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const ImgStyled = styled.img`
  display: flex;
  align-items: center;
  width: 50px;
  height: 50px;
  margin-right: 16px;
  border-radius: 5px;
  object-fit: contain;
`;

export const VehicleItemLabelStyled = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #838383;
`;

interface VehicleItemTextInterface {
  text?: string;
}

export const VehicleItemTextStyled = styled.div<VehicleItemTextInterface>`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => (props.text ? "#ffffff" : "#000000")};
  margin-bottom: 12px;
  height: 14px;
  text-transform: capitalize;
`;

interface VehicleDriveModeInterface {
  text?: string;
  marginBottom?: boolean;
}

export const VehicleDriveModeStyled = styled.div<VehicleDriveModeInterface>`
  background: ${(props) =>
    props.text ? BG_COLOR[props.text] || "#ffcc00" : "#687684"};
  min-width: max-content;
  border-radius: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  height: 14px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 4px;
  color: #ffffff;
  width: max-content;
  text-transform: uppercase;
  margin-bottom: ${(props) => (props.marginBottom ? "12px" : "0")};
`;

export const VehicleImageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 50px;
  height: 50px;
  margin-right: 16px;
  background: #c4c4c4;
  border-radius: 5px;
`;

export const FriendlyNameStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 156px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-right: 8px;
`;

export const LastContactStyled = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #687684;

  strong {
    margin: 0 4px;
  }
`;

interface DetailsInterface {
  showDetails?: boolean;
}

export const DetailsStyled = styled.div<DetailsInterface>`
  display: flex;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${(props) => (props.showDetails ? "#000000" : "#687684")};
  transition: all 0.2s;
  padding-bottom: 8px;

  img {
    margin-left: 4px;
  }

  fill: #000000 !important;
`;

export const DetailsIconStyled = styled.img<DetailsInterface>`
  transform: ${(props) =>
    props.showDetails ? "rotateX(180deg)" : "rotateX(0deg)"};
`;
