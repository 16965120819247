import React from "react";

import { AddMissionsDropdown } from "../AddMissionsDropdown";
import { HeaderControlsStyled } from "./styles";

export const HeaderControls = () => (
  <HeaderControlsStyled>
    <AddMissionsDropdown />
  </HeaderControlsStyled>
);
