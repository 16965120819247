import React, { Dispatch, memo, SetStateAction } from "react";
import { StationCheckboxStyled, StationName } from "./styles";
import { ReactComponent as MarkIcon } from "../../assets//mark-icon.svg";
import { FMSLogger } from "FMSLogger";
import { MISSION_OPERATOR_ACTIONS } from "Utils/constants";

const missionOperatorLogger = FMSLogger.byPrefix(MISSION_OPERATOR_ACTIONS);

type PreparedStation = {
  id: string;
  name: string;
  isPicked: boolean;
};

export type StationCheckboxProps = {
  isPressed: boolean;
  station: PreparedStation;
  stationPressedHandler: Dispatch<SetStateAction<string | null>>;
};

export const StationCheckbox = memo(
  ({ station, stationPressedHandler, isPressed }: StationCheckboxProps) => {
    return (
      <StationCheckboxStyled
        isPressed={isPressed}
        onClick={() => {
          missionOperatorLogger.debug(
            `Following station has been pressed: ${station.id}`
          );
          stationPressedHandler(station?.id);
        }}
      >
        <StationName isPressed={isPressed}>{station?.name}</StationName>
        {station?.isPicked && <MarkIcon />}
      </StationCheckboxStyled>
    );
  }
);
