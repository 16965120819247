import React from "react";

import { ReactComponent as Autonomous } from "./assets/autonomous.svg";
import { ReactComponent as Manual } from "./assets/manual.svg";
import { ReactComponent as Offline } from "./assets/offline.svg";
import { ReactComponent as Stuck } from "./assets/stuck.svg";

type DriveModeMeta = {
  icon: JSX.Element;
  driveModeName: string;
};

export const vehicleDriveModesMap: {
  [key in VehicleDriveMode]: DriveModeMeta;
} = {
  AUTONOMOUS: { icon: <Autonomous />, driveModeName: "Autonomous" },
  OFFLINE: { icon: <Offline />, driveModeName: "Offline" },
  STUCK: { icon: <Stuck />, driveModeName: "Vehicle Stuck" },
  MANUAL: { icon: <Manual />, driveModeName: "Manual Driver" },
};
