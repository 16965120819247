import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../../index";
import { getRolesById } from "../roles";
import { DataStatus } from "../../types";

export const getCurrentUserId = (state: RootState) =>
  state.currentUser.data?.id;
export const getCurrentUserEmail = (state: RootState) =>
  state.currentUser.data?.email;
export const getCurrentUserFirstName = (state: RootState) =>
  state.currentUser.data?.firstName;
export const getCurrentUserLastName = (state: RootState) =>
  state.currentUser.data?.lastName;
export const getCurrentUserName = createSelector(
  getCurrentUserFirstName,
  getCurrentUserLastName,
  (firstName, lastName) => {
    if (!firstName && !lastName) return "";
    return `${firstName} ${lastName}`;
  }
);
export const getCurrentUserImage = (state: RootState) =>
  state.currentUser.data?.image?.link;
export const getCurrentUserCompanyId = (state: RootState) =>
  state.currentUser.data?.companyId;
export const getCurrentUserCompanyName = (state: RootState) =>
  state.currentUser.data?.companyName;
export const getCurrentUserPhoneNumber = (state: RootState) =>
  state.currentUser.data?.phoneNumber;
export const getCurrentUserRole = (state: RootState) =>
  state.currentUser.data?.role;
export const getIsImageLoading = (state: RootState) =>
  state.currentUser.data?.isImageLoading;
export const getIsPhoneNumberUpdating = (state: RootState) =>
  state.currentUser.data?.isPhoneNumberUpdating;
export const getIsAdmin = createSelector(
  getCurrentUserRole,
  getRolesById,
  (userRole, rolesById) => rolesById?.[userRole ?? ""]?.name === "Admin"
);
export const getIsMissionOperator = createSelector(
  getCurrentUserRole,
  getRolesById,
  (userRole, rolesById) =>
    rolesById?.[userRole ?? ""]?.name === "Mission Operator"
);
export const getIsCurrentUserLoaded = (state: RootState): boolean =>
  state.currentUser.status === DataStatus.succeeded;
export const getIsCurrentUserLoadingFailed = (state: RootState): boolean =>
  state.currentUser.status === DataStatus.failed;
