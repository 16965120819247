import {
  fetchGetVehicleTypes,
  addVehicleTypeImage,
  fetchPostVehicleTypes,
  fetchPutVehicleTypesId,
} from "./actions";
import {
  getVehicleTypes,
  getVehicleTypesTransportFacilityModals,
  getVehicleTypesById,
  getIsVehicleTypesLoaded,
  getIsVehicleTypesLoadingError,
} from "./selectors";
import VehicleTypesSlice from "./reducers";

// actions
export const { addNew, updatedVehicleType, resetVehicleTypes } =
  VehicleTypesSlice.actions;

// async actions
export {
  fetchGetVehicleTypes,
  addVehicleTypeImage,
  fetchPostVehicleTypes,
  fetchPutVehicleTypesId,
};

// selectors
export {
  getVehicleTypes,
  getVehicleTypesTransportFacilityModals,
  getVehicleTypesById,
  getIsVehicleTypesLoaded,
  getIsVehicleTypesLoadingError,
};

// reducer
export default VehicleTypesSlice.reducer;
