import styled from "styled-components";

export const VehicleItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-all;
  padding: 14px 7px;
  gap: 8px;
  font-family: "Readex Pro", sans-serif;
  font-size: 14px;
  cursor: pointer;
  color: #333333;
`;

export const CheckIconBodyStyled = styled.div`
  width: 16px;
  height: 16px;
`;

export const VehicleIcon = styled.img`
  width: 30px;
  height: 20px;
  object-fit: contain;
`;
