import React, {
  useState,
  useRef,
  memo,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Range } from "react-date-range";
import format from "date-fns/format";

import { FMSLogger } from "FMSLogger";
import { useOnClickOutside } from "Utils/hooks";
import { MISSION_PLANNER_ACTIONS } from "Utils/constants";
import { storageKeys } from "Utils/localStorageUtils";
import {
  getMissionPlannerPeriod,
  setCurrentFleetPeriod,
} from "Slices/missionPlanner";
import { DatePicker } from "../DatePicker";
import { getDefaultPeriod } from "Slices/missionPlanner/reducers";

import { ReactComponent as SelectIcon } from "../../assets/selectIconBold.svg";
import {
  DatesIntervalButtonDropdownStyled,
  DatesIntervalButtonWrapperStyled,
  CurrentIntervalStyled,
  HeaderStyled,
  DatePickerBody,
  DatePickerButton,
} from "./styles";

const missionPlannerActionsLogger = FMSLogger.byPrefix(MISSION_PLANNER_ACTIONS);

export const DatesIntervalButton = memo(() => {
  const dispatch = useDispatch();

  const fleetPeriod = useSelector(getMissionPlannerPeriod);

  // set default period, it needs for trigger request for missions
  useEffect(() => {
    if (fleetPeriod) return;
    dispatch(setCurrentFleetPeriod(getDefaultPeriod()));
  }, [fleetPeriod]);

  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [datesInterval, setDatesInterval] = useState<[Range]>([
    {
      startDate: fleetPeriod ? new Date(fleetPeriod.startDate) : new Date(),
      endDate: fleetPeriod ? new Date(fleetPeriod.endDate) : new Date(),
      key: "selection",
      color: "#e9e9e9",
    },
  ]);

  const fleetPeriodFormatString = useMemo(() => {
    const { startDate, endDate } = datesInterval[0];
    if (!startDate || !endDate) return;
    return (
      format(new Date(startDate), "MM-dd-yyyy") +
      " / " +
      format(new Date(endDate), "MM-dd-yyyy")
    );
  }, [datesInterval]);

  const handleDatePickerClick = () => {
    const { startDate, endDate } = datesInterval[0];

    const startDateISO = startDate?.toISOString();

    endDate?.setHours(23, 59, 59, 999);
    const endDateISO = endDate?.toISOString();

    missionPlannerActionsLogger.debug(
      `Date picker apply button pressed with periods: '${startDateISO}' and '${endDateISO}'`
    );
    localStorage.setItem(
      storageKeys.MISSION_OPERATOR_SAVED_PERIOD,
      JSON.stringify({
        startDate: startDateISO,
        endDate: endDateISO,
      })
    );
    dispatch(
      setCurrentFleetPeriod({
        startDate: startDateISO,
        endDate: endDateISO,
      })
    );
    setShowDatePicker(false);
  };

  const datesIntervalButtonRef =
    useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setShowDatePicker(false), []);
  useOnClickOutside(datesIntervalButtonRef, handleClickOutside);

  return (
    <DatesIntervalButtonWrapperStyled ref={datesIntervalButtonRef}>
      <DatesIntervalButtonDropdownStyled
        onClick={() => setShowDatePicker(!showDatePicker)}
        active={showDatePicker}
      >
        <div>
          <HeaderStyled>Period</HeaderStyled>
          <CurrentIntervalStyled>
            {fleetPeriodFormatString}
          </CurrentIntervalStyled>
        </div>
        <SelectIcon />
      </DatesIntervalButtonDropdownStyled>
      {showDatePicker && (
        <DatePickerBody>
          <DatePickerButton onClick={handleDatePickerClick}>
            Apply
          </DatePickerButton>
          <DatePicker
            datesInterval={datesInterval}
            setDatesInterval={setDatesInterval}
          />
        </DatePickerBody>
      )}
    </DatesIntervalButtonWrapperStyled>
  );
});
