import {
  fetchGetVehicles,
  fetchPostVehicles,
  fetchPutVehiclesId,
} from "./actions";
import {
  getVehiclesTransportFacilityModals,
  getVehiclesTransportFacilityModalsById,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
  getVehiclesByCurrentSemanticMap,
  getVehicles,
} from "./selectors";
import VehiclesSlice from "./reducers";

// actions
export const { addNewVehicle, updateVehicle, resetVehicles, updateVehicles } =
  VehiclesSlice.actions;

// async actions
export { fetchGetVehicles, fetchPostVehicles, fetchPutVehiclesId };

// selectors
export {
  getVehiclesTransportFacilityModals,
  getVehiclesTransportFacilityModalsById,
  getIsVehiclesLoaded,
  getIsVehiclesLoadingError,
  getVehiclesByCurrentSemanticMap,
  getVehicles,
};

// reducer
export default VehiclesSlice.reducer;
