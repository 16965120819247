import styled from "styled-components";
import VehicleStatusImage from "../../assets/vehicle-status-background.svg";

export const VehicleOnWayBodyStyled = styled.div<{
  background: string;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  height: 108px;
  margin-top: 32px;
  background: ${({ background }) => background} url(${VehicleStatusImage})
    no-repeat bottom right;
  padding: 24px;
  font-family: "Readex Pro", sans-serif;
`;

export const StatusNameStyled = styled.div`
  font-weight: 300;
  font-size: 20px;
  color: #172a3f;
`;

// TODO: uncomment when backend will be done
// export const EstimatedTimeBlockStyled = styled.div`
//   padding: 6px 12px;
//   font-weight: 300;
//   text-align: right;
//   font-size: 14px;
//   line-height: 140%;
//   color: #172a3f;
//   opacity: 0.8;
// `;
//
// export const TimeStyled = styled.div`
//   font-weight: 400;
//   font-size: 20px;
//   line-height: 120%;
//   opacity: 1;
// `;
//
export const PlaceInQueueStyled = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 20px;
`;

export const PlaceStyled = styled.div`
  font-size: 48px;
  letter-spacing: -0.02em;
  color: #172a3f;
`;
