import { createSlice } from "@reduxjs/toolkit";

import { fetchGetFlags } from "./actions";
import { DataStatus } from "../../types";

type UnhitchFlags = {
  [key: CompanyId]: boolean;
};

type FlagsTypes = { unhitch: UnhitchFlags | null };

type FlagsState = {
  data: FlagsTypes | null;
  status: DataStatus;
  error: string | null;
};

const initialState: FlagsState = {
  data: null,
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    resetFlags: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetFlags.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetFlags.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetFlags.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
