import React, { memo } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  getCurrentUserCompanyName,
  getCurrentUserName,
  getCurrentUserRole,
} from "Slices/currentUser";

import { WelcomeLoader } from "./Welcome.loader";

const WelcomePageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Readex Pro", sans-serif;
  height: 100%;
`;

const WelcomeTitle = styled.div`
  display: flex;
  padding-bottom: 32px;
  font-weight: 500;
  font-size: 18px;
`;

export const WelcomePage = memo(() => {
  const currentUsername = useSelector(getCurrentUserName);
  const role = useSelector(getCurrentUserRole);
  const companyName = useSelector(getCurrentUserCompanyName);

  if (companyName && role !== null) return <Navigate to="/" replace={true} />;

  return (
    <WelcomeLoader>
      <WelcomePageStyled>
        <WelcomeTitle>Hello {currentUsername}.</WelcomeTitle>
        <div>
          We are Cyngn. Inc. We create autonomous technology for industrial
          fleets.
        </div>
        <div>
          We have received your request. Our administrator will process it soon.
        </div>
      </WelcomePageStyled>
    </WelcomeLoader>
  );
});
