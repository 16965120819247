import { FMSLogger as FMSLoggerClass, Prefix } from "./FMSLogger";
import { colours } from "./constants";

export const getPrefixToColor = () => {
  let lastColorIndex = 0;
  const colorMap: Record<string, string> = {};

  const getColor = () => {
    const colour = colours[lastColorIndex % colours.length];
    lastColorIndex++;
    return colour;
  };
  return (prefix: Prefix) =>
    colorMap[prefix] ||
    Object.assign(colorMap, { [prefix]: getColor() })[prefix];
};

export const downloadFile = (url: string, name: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  if (name) {
    anchor.download = name;
  }
  document.body.appendChild(anchor);

  anchor.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(anchor);
};

export const addListenerToDownloadLogs = (logger: FMSLoggerClass) => {
  document.addEventListener("keydown", (evt) => {
    if (
      evt.key?.toLowerCase() === "l" &&
      evt.shiftKey &&
      evt.ctrlKey &&
      evt.metaKey
    ) {
      logger.downloadLogs();
    }
  });
};

export const addListenerForUnhandledErrors = (logger: FMSLoggerClass) => {
  const prefixedLogger = logger.byPrefix("error-catch");

  window.addEventListener("error", (event) =>
    prefixedLogger.error("UNCAUGHT_ERROR", event.error)
  );

  window.addEventListener("unhandledrejection", (event) =>
    prefixedLogger.error("UNHANDLED_REJECTION", event.reason)
  );
};
