import { LoadingStyles } from "Components/common/styles";
import styled from "styled-components";

interface SearchDropdownBtnProps {
  isLoading: boolean;
}

export const SearchDropdownInputStyled = styled.input<SearchDropdownBtnProps>`
  background: #f4f4f4;
  height: 56px;
  width: 100%;
  font-size: 16px;
  line-height: 128%;
  color: #1b1b1b;
  padding: 18px 80px 18px 48px;
  border: none;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.isLoading && LoadingStyles}

  &::placeholder {
    font-weight: 300;
    color: #606060;
  }
`;

export const SearchDropdownContainerStyled = styled.div`
  position: relative;
`;

export const SearchInputContainerStyled = styled.div`
  position: relative;
`;

export const SearchIconStyled = styled.div`
  svg {
    position: absolute;
    left: 12px;
    top: 16px;
  }
`;

export const SearchDropdownListContainerStyled = styled.div`
  position: absolute;
  width: 100%;
  top: 56px;
  z-index: 1;
`;

interface DropdownBtnProps {
  isOpened: boolean;
}

export const SearchDropdownBtnStyled = styled.div<DropdownBtnProps>`
  position: absolute;
  top: 12px;
  right: 23px;
  cursor: pointer;
  transition: all 0.3s;

  svg {
    transform: ${(props) =>
      props.isOpened ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export const SearchDropdownListStyled = styled.ul`
  margin: 0;
  background-color: #f8f8f8;
  box-sizing: border-box;
  color: #3b4256;
  height: 100%;
  padding: 8px 0;
  font-size: 16px;
  max-height: 270px;
  overflow-y: auto;
`;

export const DelimiterStyled = styled.hr`
  position: absolute;
  left: 16px;
  width: 94%;
  background-color: #c5c5c5;
  margin: 0 auto;
  padding: 0;
  height: 1px;
  border: none;
`;

export const SearchDropdownPickedItemsContainerStyled = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
`;

export const SearchDropdownPickedItemsStyled = styled.div`
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 16px 0 12px;
  border: 1px solid #e9e9e9;
  margin-right: 12px;
  margin-bottom: 8px;

  svg {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 16px;
    cursor: pointer;
  }
`;
