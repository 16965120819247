import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetRoles = createAsyncThunk(
  "currentUser/fetchGetRoles",
  async () => {
    try {
      const response = await API.get("roles/");
      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
