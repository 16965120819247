import { EMPTY_ARRAY } from "Utils";

type PrepareVehicleDataFunction = (state: {
  vehicles: Array<VehiclesPageVehicle>;
  vehicleTypes: VehicleType[];
  fleets: Fleet[];
  missionsByVehicleId: Record<string, Mission>;
  driveModeStatusesByVehicleId: Record<string, VehicleState["t_drive_mode"]>;
}) => Array<VehiclesPageVehicle>;

export const prepareVehiclesData: PrepareVehicleDataFunction = ({
  vehicles,
  vehicleTypes,
  fleets,
  missionsByVehicleId,
  driveModeStatusesByVehicleId,
}) => {
  if (vehicles.length === 0) return EMPTY_ARRAY;

  return vehicles.map((vehicle) => ({
    ...vehicle,
    driveMode:
      driveModeStatusesByVehicleId[vehicle.id] ||
      vehicle.driveMode ||
      "No data",
    type:
      vehicleTypes?.find(
        (vehicleType) => vehicleType.id === vehicle.vehicleTypeId
      )?.name ?? "",
    fleetName:
      fleets?.find((fleet) => fleet.id === vehicle.fleetId)?.name ?? "",
    missionStatus: missionsByVehicleId?.[vehicle.id]?.status ?? "No data",
    missionName: missionsByVehicleId?.[vehicle.id]?.name ?? "No data",
  }));
};
