import React, { memo, useMemo, useRef } from "react";
import { Marker } from "react-map-gl";
import { useSelector } from "react-redux";

import { MarkerStyles } from "../../styles";
import { ReactComponent as VehicleProgressIcon } from "../../assets/vehicleProgressIcon.svg";
import { ReactComponent as VehicleNoMissionIcon } from "../../assets/vehicleNoMissionIcon.svg";
import { ReactComponent as DriverIcon } from "../../assets/driverIcon.svg";
import { OnlineLabel } from "../OnlineLabel";
import { getVehiclesByCurrentSemanticMap } from "Slices/vehicles";
import { getDiffTime } from "Utils";

import { useOnClickOutside } from "Utils/hooks";
import {
  VehicleTooltipStyled,
  VehicleTooltipInfoStyled,
  // VehicleTooltipLinkContentStyled,
  // VehicleTooltipLinkStyled,
  VehicleTooltipTopInfoStyled,
  VehicleTooltipLineInfoStyled,
  VehicleTooltipNameStyled,
  VehicleTooltipLastUpdateStyled,
  VehicleTooltipProgressStyled,
  VehicleTooltipDriverStyled,
} from "./styles";

export interface VehicleTooltipData {
  id: string;
  latitude: number | null;
  longitude: number | null;
  driveMode: string | null;
  lastContact: string | null;
  online: string | null;
  missionStatus: string | null;
}

const EMPTY_VEHICLE_DATA = {
  name: "No Data",
};

const DRIVE_MOD_DICT: { [key: string]: string } = {
  AUTONOMOUS: "Autonomous",
  AUTO: "Autonomous",
  MANUAL: "Manual Driver",
};

interface VehicleTooltipInterface extends VehicleTooltipData {
  closeAction: () => void;
  vehicleData: Vehicle;
}

export const VehicleTooltip = memo(
  ({
    id,
    latitude,
    longitude,
    driveMode,
    lastContact,
    online,
    missionStatus,
    closeAction,
    vehicleData,
  }: VehicleTooltipInterface) => {
    const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
    useOnClickOutside(ref, closeAction);
    const vehicles: Array<Vehicle> = useSelector(
      getVehiclesByCurrentSemanticMap
    );
    const currentVehicle =
      vehicles.find((vehicleItem) => vehicleItem.id === id) ||
      EMPTY_VEHICLE_DATA;

    const currentDriveMod = useMemo(() => {
      if (driveMode) {
        return DRIVE_MOD_DICT[driveMode] || driveMode;
      }

      if (vehicleData?.driveMode) {
        return vehicleData.driveMode;
      }

      return "No Data";
    }, [driveMode, vehicleData?.driveMode]);

    return (
      <Marker
        longitude={longitude || vehicleData.longitude}
        latitude={latitude || vehicleData.latitude}
        onClick={(e) => {
          e.originalEvent.stopPropagation();
        }}
        anchor={"bottom-left"}
        offset={[0, -12]}
        style={{ ...MarkerStyles, zIndex: 4 }}
      >
        <VehicleTooltipStyled ref={ref}>
          <VehicleTooltipInfoStyled>
            <VehicleTooltipTopInfoStyled>
              <VehicleTooltipLineInfoStyled>
                <VehicleTooltipNameStyled>
                  {currentVehicle.name}
                </VehicleTooltipNameStyled>
                <OnlineLabel online={online === "ONLINE"} />
              </VehicleTooltipLineInfoStyled>
              <VehicleTooltipLineInfoStyled>
                <VehicleTooltipDriverStyled>
                  <DriverIcon />
                  {currentDriveMod}
                </VehicleTooltipDriverStyled>
              </VehicleTooltipLineInfoStyled>
            </VehicleTooltipTopInfoStyled>
            <VehicleTooltipLineInfoStyled>
              <VehicleTooltipProgressStyled>
                {missionStatus === "IN_PROGRESS" ? (
                  <VehicleProgressIcon />
                ) : (
                  <VehicleNoMissionIcon />
                )}
                {missionStatus === "IN_PROGRESS" ? "In Progress" : "No Mission"}
              </VehicleTooltipProgressStyled>
              <VehicleTooltipLastUpdateStyled>
                Last updated:
                <span>
                  {lastContact ? getDiffTime(lastContact) : "No data"}
                </span>
              </VehicleTooltipLastUpdateStyled>
            </VehicleTooltipLineInfoStyled>
          </VehicleTooltipInfoStyled>
          {/* <VehicleTooltipLinkContentStyled>
            <VehicleTooltipLinkStyled>Show More</VehicleTooltipLinkStyled>
          </VehicleTooltipLinkContentStyled> */}
        </VehicleTooltipStyled>
      </Marker>
    );
  }
);
