import { LoadingStyles } from "Components/common/styles";
import styled from "styled-components";

interface DropdownBtnProps {
  isOpened: boolean;
  isLoading: boolean;
}

export const DropdownBtnStyled = styled.div<DropdownBtnProps>`
  background: #f4f4f4;
  height: 56px;
  box-sizing: border-box;
  width: 100%;
  font-size: 16px;
  line-height: 128%;
  color: #1b1b1b;
  padding: 0 24px;
  border: none;
  cursor: pointer;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(props) => props.isLoading && LoadingStyles}

  svg {
    transform: ${(props) =>
      props.isOpened ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s;
  }
`;

export const DropdownStyled = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`;

export const DropdownListContainerStyled = styled.div`
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 20px 0;
  position: absolute;
  width: 100%;
  top: 56px;
  z-index: 1;
`;

export const DropdownListStyled = styled.ul`
  margin: 0;
  background-color: #f8f8f8;
  box-sizing: border-box;
  color: #3b4256;
  height: 100%;
  padding: 8px 0;
  font-size: 16px;
  max-height: 208px;
  overflow-y: auto;
  font-family: "Readex Pro", sans-serif;
  font-weight: lighter;
`;

export const DropdownListItemStyled = styled.li`
  display: flex;
  background-color: transparent;
  align-items: center;
  list-style: none;
  line-height: 128%;
  color: #1b1b1b;
  padding: 0 24px;
  height: 40px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const ListItemTextStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
