import { createSlice } from "@reduxjs/toolkit";

import { fetchGetCompanies } from "./actions";
import { DataStatus } from "../../types";

type Company = {
  url: string;
  id: string;
  name: string;
  shortName: string;
  domainUrl: string;
};

type CompaniesState = {
  data: Array<Company>;
  status: DataStatus;
  error: string | null;
};

const initialState: CompaniesState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    resetCompanies: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCompanies.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetCompanies.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetCompanies.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
