import React, { memo } from "react";
import { useSelector } from "react-redux";

import {
  getInProgressFleetMissions,
  getInQueueFleetMissions,
} from "Slices/operatorData";

import { VehicleStatus } from "../VehicleStatus";
import { DelimiterStyled } from "../../../common/Delimeter/styles";
import { VehicleStatusesStyled, VehicleStatusesWrapperStyled } from "./styles";
import { ReactComponent as InProgress } from "../../assets/inProgress.svg";
import { ReactComponent as InQueue } from "../../assets/inQueue.svg";

export const VehicleStatuses = memo(() => {
  const inProgressCount = useSelector(getInProgressFleetMissions);
  const inQueueCount = useSelector(getInQueueFleetMissions);

  return (
    <VehicleStatusesWrapperStyled>
      <DelimiterStyled delimiterColor="#d1d1d1" />
      <VehicleStatusesStyled>
        <VehicleStatus
          title="In progress"
          count={inProgressCount}
          Icon={InProgress}
          countColor="#004ecc"
        />
        <VehicleStatus
          title="In queue"
          count={inQueueCount}
          Icon={InQueue}
          countColor="#864c13"
        />
      </VehicleStatusesStyled>
    </VehicleStatusesWrapperStyled>
  );
});
