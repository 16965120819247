import React, { FC } from "react";

import { ReactComponent as WhiteSpinner } from "./assets/spinner-white.svg";
import { ReactComponent as BlackSpinner } from "./assets/spinner-black.svg";
import { ImageStyled } from "./styles";

type SpinnerProps = {
  color?: string;
};

export const Spinner: FC<SpinnerProps> = ({ color }) => (
  <ImageStyled>
    {color === "white" ? <WhiteSpinner /> : <BlackSpinner />}
  </ImageStyled>
);
