import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  setMissionPlannerData,
  getCurrentSemanticMapId,
  getMissionPlannerData,
} from "Slices/appState";
import { getStopsByCurrentSemanticMap } from "Slices/stops";
import { getCurrentSemanticMap } from "Slices/semanticMaps";
import { MissionPlannerData } from "Slices/appState/reducers";
import { MapComponent } from "Components/MapComponent";

import { VehiclesList } from "./components/VehiclesList";
import { MissionPlanner } from "./components/MissionPlanner";
import { CallVehicleButton } from "./components/CallVehicle";
import {
  MapWindowStyled,
  SidebarStyled,
  ToggleSatButtonStyled,
  WrapperStyled,
} from "./styles";

export const RealTimeMap = memo(() => {
  const [showSatView, setShowSatView] = useState<boolean>(false);
  const missionPlannerData = useSelector(getMissionPlannerData);
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);
  const stops = useSelector(getStopsByCurrentSemanticMap);
  const dispatch = useDispatch();

  const mapBounds = useMemo(() => {
    if (!currentSemanticMap) return;
    const { bbox } = currentSemanticMap;
    if (bbox && bbox[0]) {
      return bbox;
    }
    return;
  }, [currentSemanticMap]);

  const toggleSatView = () => {
    setShowSatView(!showSatView);
  };

  const setMissionPlannerDataAction = (
    missionPlannerData: MissionPlannerData
  ) => {
    dispatch(setMissionPlannerData(missionPlannerData));
  };

  return (
    <WrapperStyled>
      {currentSemanticMapId && (
        <SidebarStyled>
          <VehiclesList
            setMissionPlannerData={setMissionPlannerDataAction}
            currentSemanticMap={currentSemanticMap}
          />
          <CallVehicleButton />
        </SidebarStyled>
      )}
      <MapWindowStyled>
        <MapComponent
          mapBounds={mapBounds}
          currentSemanticMap={currentSemanticMap}
          showSatView={showSatView}
        />
        <ToggleSatButtonStyled onClick={toggleSatView}></ToggleSatButtonStyled>
      </MapWindowStyled>
      {missionPlannerData && (
        <MissionPlanner
          stops={stops}
          missionPlannerData={missionPlannerData}
          setMissionPlannerData={setMissionPlannerDataAction}
        />
      )}
    </WrapperStyled>
  );
});
