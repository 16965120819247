import { RootState } from "../../index";

export const getCurrentFleetId = (state: RootState) =>
  state.missionPlanner.currentFleetId;
export const getMissionRowsLimit = (state: RootState) =>
  state.missionPlanner.limit;
export const getSearchValue = (state: RootState) =>
  state.missionPlanner.searchValue;
export const getHeaderData = (state: RootState) =>
  state.missionPlanner.headerData;
export const getActiveTab = (state: RootState) =>
  state.missionPlanner.activeTab;
export const getMissionPlannerPeriod = (state: RootState) =>
  state.missionPlanner.period;
export const getAllMissionsCount = (state: RootState) =>
  state.missionPlanner.allMissionsCount;
export const getDeployedMissionsCount = (state: RootState) =>
  state.missionPlanner.deployedMissionsCount;
export const getCompletedMissionsCount = (state: RootState) =>
  state.missionPlanner.completedMissionsCount;
export const getMissions = (state: RootState) =>
  state.missionPlanner.missionsQueue;
export const getSelectedVehicleIds = (state: RootState) =>
  state.missionPlanner.selectedVehicleIds;
export const getIsMissionPlannerUpdating = (state: RootState) =>
  state.missionPlanner.isUpdating;
export const getIsMissionsUpdatesPaused = (state: RootState) =>
  state.missionPlanner.isMissionsUpdatesPaused;
export const getMissionsUpdatesStorage = (state: RootState) =>
  state.missionPlanner.missionsUpdatesStorage;
