import React, { memo } from "react";
import { TransportFacilityHeader } from "Components/ListPageHeader/TransportFacilityHeader";
import { useSelector } from "react-redux";
import { getVehicles } from "Slices/vehicles";
// import { VEHICLE_MODAL } from "Slices/modals";

export const VehiclesListHeader = memo(() => {
  const vehicles = useSelector(getVehicles);
  return (
    <TransportFacilityHeader
      text={"Vehicles"}
      // modalType={VEHICLE_MODAL}
      addText={"Add Vehicle"}
      count={vehicles.length}
    />
  );
});
