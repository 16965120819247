import React from "react";

import { VehiclesPicker } from "../VehiclesPicker";
import { DatesIntervalButton } from "../DatesIntervalButton";
import { FleetPickerDropdown } from "../FleetPickerDropdown";

import {
  AsideFiltersWrapperStyled,
  SemanticMapPickerPaddingStyled,
} from "./styles";
import { DelimiterStyled } from "../../../common/Delimeter/styles";
import { SemanticMapPicker } from "Components/common/SemanticMapPicker";

export const AsideFilters = () => (
  <AsideFiltersWrapperStyled>
    <SemanticMapPickerPaddingStyled>
      <SemanticMapPicker />
    </SemanticMapPickerPaddingStyled>
    <DelimiterStyled />
    <FleetPickerDropdown />
    <DelimiterStyled />
    <VehiclesPicker />
    <DatesIntervalButton />
  </AsideFiltersWrapperStyled>
);
