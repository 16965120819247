import { createSlice } from "@reduxjs/toolkit";
import { arrayToObjectByField } from "Utils";

interface VehiclesStateData {
  data: VehiclesStateDataItem | null;
}

const initialState: VehiclesStateData = {
  data: null,
};

export const Slice = createSlice({
  name: "vehiclesState",
  initialState,
  reducers: {
    resetVehicleStateData: () => initialState,
    updateVehiclesStateData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setVehiclesStateData: (state, action) => {
      state.data = arrayToObjectByField(action.payload, "vehicle_id");
      return state;
    },
  },
});

export default Slice;
