import styled from "styled-components";

export const UserAvatarContainerStyled = styled.div`
  position: relative;
  width: 109px;
  height: 109px;

  label {
    svg {
      position: absolute;
      top: 84px;
      left: 90px;

      width: 32px;
      height: 32px;

      cursor: pointer;
      border-radius: 50%;
    }
  }

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
`;

export const SpinnerUserImageStyled = styled.div`
  position: absolute;
  top: 86px;
  left: 92px;
  width: 28px;
  height: 28px;
`;
