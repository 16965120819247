import { RootState } from "../../index";
import { createSelector } from "@reduxjs/toolkit";
import { arrayToObjectByField } from "Utils";
import { getCurrentUserCompanyId } from "../currentUser";
import { DataStatus } from "../../types";

export const getUsers = (state: RootState) => state.users.data;
export const getUsersStatus = (state: RootState) => state.users.status;
export const getUsersById = createSelector(getUsers, (users) =>
  arrayToObjectByField(users)
);
export const getUsersFilteredByCurrentCompany = createSelector(
  getUsers,
  getCurrentUserCompanyId,
  (users, currentUserCompanyId) =>
    currentUserCompanyId
      ? users.filter(({ companyId }) => companyId === currentUserCompanyId)
      : users
);
export const getIsUsersLoaded = createSelector(
  getUsersStatus,
  (status) => status === DataStatus.succeeded
);
export const getIsUsersLoadingError = createSelector(
  getUsersStatus,
  (status) => status === DataStatus.failed
);
