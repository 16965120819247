import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { getFleetsByCurrentSemanticMap } from "../fleets";

const getOperatorMissionState = (state: RootState) =>
  state.operatorData.operatorMissionState;
const getFleetMissions = (state: RootState) =>
  state.operatorData.fleetMissionsState;
export const getOperatorMissionStatus = createSelector(
  getOperatorMissionState,
  (operatorMissionState) => operatorMissionState?.status
);
export const getMissionOperatorPickedFleetId = (state: RootState) =>
  state.operatorData.pickedFleetId;

export const getIsPickedFleetHasVehicles = createSelector(
  getMissionOperatorPickedFleetId,
  getFleetsByCurrentSemanticMap,
  (fleetId, fleets) => {
    const pickedFleet = fleets.find((fleet) => fleet.id === fleetId);
    return Boolean(pickedFleet?.vehiclesId.length);
  }
);

export const getMissionOperatorFleetIdForSubscribe = (state: RootState) =>
  state.operatorData.operatorMissionState?.fleetId;

export const getOperatorMissionOrder = createSelector(
  getOperatorMissionState,
  (operatorMissionState) => operatorMissionState?.order
);

export const getInProgressFleetMissions = createSelector(
  getFleetMissions,
  (fleetMissions) =>
    fleetMissions.length > 0
      ? fleetMissions.filter(
          (fleetMission) => fleetMission.status === "IN_PROGRESS"
        ).length
      : "..."
);
export const getInQueueFleetMissions = createSelector(
  getFleetMissions,
  (fleetMissions) =>
    fleetMissions.length > 0
      ? fleetMissions.filter(
          (fleetMission) =>
            fleetMission.status === "NEW" ||
            fleetMission.status === "SENT" ||
            fleetMission.status === "RECEIVED"
        ).length
      : "..."
);
