import styled from "styled-components";

type MissionListContentProps = {
  isShowAssignPicker: boolean;
};

export const MissionListContentStyled = styled.div<MissionListContentProps>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: ${(props) => (props.isShowAssignPicker ? "hidden" : "inherit")};

  button {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
