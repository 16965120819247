import React, { memo, FC } from "react";

import { ReactComponent as AdditionalInfoIcon } from "../assets/additionalInfo.svg";
import { ErrorTextStyled } from "./styles";

interface ErrorTextProps {
  errorMessage: string;
}

export const ErrorText: FC<ErrorTextProps> = memo(({ errorMessage }) => (
  <ErrorTextStyled>
    <AdditionalInfoIcon />
    {errorMessage}
  </ErrorTextStyled>
));
