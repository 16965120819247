import styled from "styled-components";
import { NavLink } from "react-router-dom";

import MenuItemBackground from "../../assets/menuItemBackground.svg";

export const LinksContainerStyled = styled.div`
  height: 100%;
  display: flex;
`;

interface LinksItemProps {
  active: boolean;
}

export const LinksItemStyled = styled(NavLink)<LinksItemProps>`
  display: flex;
  height: 100%;
  border-bottom: ${(props) =>
    props.active ? "4px solid #55B8B3" : "4px solid transparent"};
  color: ${(props) => (props.active ? "#55B8B3" : "#bbbbbb")};
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  text-decoration: none;
  padding: 8px 0 4px 0;
  box-sizing: border-box;

  &:hover {
    opacity: 0.8;
  }
`;

interface LinksItemContentProps {
  active: boolean;
}

export const LinksItemContentStyled = styled.div<LinksItemContentProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: ${(props) =>
    props.active ? "rgba(255, 255, 255, 0.05)" : "transparent"};
  transition: all 0.3s;
  background-image: ${(props) =>
    props.active ? `url(${MenuItemBackground})` : "none"};
  background-position: right bottom;
  background-repeat: no-repeat;

  path {
    fill: ${(props) => (props.active ? "#55B8B3" : "#bbbbbb")};
  }
`;

export const LinksItemTextStyled = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;
