import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";

import {
  setVehiclesPageTypes,
  setVehiclesPageTypeWithNewTypes,
  setVehiclesPageTypeWithUpdatedTypes,
} from "../vehiclesPage";
import { addNew, updatedVehicleType } from ".";

export const fetchGetVehicleTypes = createAsyncThunk(
  "vehicleTypes/fetchGetVehicleTypes",
  async (_, thunkAPI) => {
    try {
      const response = await API.get("/vehicle_types/");
      thunkAPI.dispatch(setVehiclesPageTypes(response.data.results));

      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const addVehicleTypeImage = async (file: File) => {
  try {
    const { data } = await API.postForm("assets/", {
      file: file,
      target: "default",
    });
    return data.id;
  } catch (error) {
    showNetworkErrorToast(error);
    return Promise.reject();
  }
};

export const fetchPostVehicleTypes = createAsyncThunk<void, VehicleTypeCore>(
  "vehicleTypes/fetchPostVehicleTypes",
  async (data: VehicleTypeCore, thunkAPI) => {
    try {
      const response = await API.post("/vehicle_types/", data);
      thunkAPI.dispatch(addNew(response.data));
      thunkAPI.dispatch(setVehiclesPageTypeWithNewTypes(response.data));
      toast.success("Vehicle Type added successfully");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPutVehicleTypesId = createAsyncThunk<
  void,
  { id: string; data: Partial<VehicleType> }
>(
  "vehicleTypes/fetchPutVehicleTypesId",
  async (vehicleType: { id: string; data: Partial<VehicleType> }, thunkAPI) => {
    const { id, data } = vehicleType;
    try {
      const response = await API.patch(`/vehicle_types/${id}/`, {
        ...data,
      });
      thunkAPI.dispatch(updatedVehicleType(response.data));
      thunkAPI.dispatch(setVehiclesPageTypeWithUpdatedTypes(response.data));
      toast.success(
        `Vehicle type ${data.manufacturer} ${data.model} edited successfully`
      );
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
