import { createSlice } from "@reduxjs/toolkit";

import { fetchGetMissionPlans } from "./actions";
import { DataStatus } from "../../types";

type MissionPlansState = {
  data: Array<MissionAsset>;
  loadedSemanticMapIds: Array<string>;
  status: DataStatus;
  error: string | null;
};

const initialState: MissionPlansState = {
  data: [],
  loadedSemanticMapIds: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "missionPlans",
  initialState,
  reducers: {
    resetMissionPlans: () => initialState,
    addNewMissionPlan: (state, { payload }) => {
      state.data = state.data.concat(payload);
      return state;
    },
    updateMissionPlan: (state, { payload }) => {
      state.data = state.data.map((missionPlan) =>
        missionPlan.id === payload.id ? payload : missionPlan
      );
      return state;
    },
    removeMissionPlan: (state, { payload }) => {
      state.data = state.data.filter(
        (missionPlan) => missionPlan.id !== payload
      );
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetMissionPlans.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetMissionPlans.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = state.data.concat(action.payload.missions);
        state.loadedSemanticMapIds = state.loadedSemanticMapIds.concat(
          action.payload.semanticMapId as string
        );
      })
      .addCase(fetchGetMissionPlans.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
