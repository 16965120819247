import React, { memo } from "react";
import { useSelector } from "react-redux";

import { TransportFacilityHeader } from "Components/ListPageHeader/TransportFacilityHeader";
import { getFleets } from "Slices/fleets";
import { FLEET_MODAL } from "Slices/modals";

export const FleetsListHeader = memo(() => {
  const fleets = useSelector(getFleets);
  return (
    <TransportFacilityHeader
      text={"Fleets"}
      modalType={FLEET_MODAL}
      addText={"Add Fleet"}
      count={fleets.length}
    />
  );
});
