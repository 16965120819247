import { fetchGetStops } from "./actions";
import {
  getStopsByCurrentSemanticMap,
  getStopsByCurrentSemanticMapDict,
  getIsStopsByCurrentSemanticMapEmpty,
} from "./selectors";
import StopsSlice from "./reducers";

//async actions
export { fetchGetStops };

// actions
export const { resetStops } = StopsSlice.actions;

//selectors
export {
  getStopsByCurrentSemanticMap,
  getStopsByCurrentSemanticMapDict,
  getIsStopsByCurrentSemanticMapEmpty,
};

//reducer
export default StopsSlice.reducer;
