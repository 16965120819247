import styled from "styled-components";

export const EmptyCoverStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 60px 60px 0 60px;
  width: 100%;

  img {
    margin-bottom: 24px;
  }
`;

export const EmptyCoverTextStyled = styled.div`
  display: flex;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 8px;
`;

export const EmptyCoverBtnStyled = styled.div`
  display: flex;
  width: 212px;
  height: 41px;
  left: 332px;
  top: 1123.5px;
  background: #4daa00;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.005em;
  color: #ffffff;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
