import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import API, { showNetworkErrorToast } from "Utils/api";
import {
  addNewMissionPlan,
  removeMissionPlan,
  updateMissionPlan,
} from "Slices/missionPlans";

export const fetchGetMissionPlans = createAsyncThunk(
  "missions/fetchGetMissionPlans",
  async (semanticMapId: string) => {
    try {
      const missions = await API.get(`missions/?semantic_map=${semanticMapId}`);

      return { missions: missions.data.results || [], semanticMapId };
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPostMissionPlan = createAsyncThunk<void, MissionAsset>(
  "missions/fetchPostMissionPlan",
  async (data: MissionAsset, thunkAPI) => {
    try {
      const response = await API.post("missions/", data);
      thunkAPI.dispatch(addNewMissionPlan(response.data));
      toast.success("Mission plan Added");
      return response.data;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchPatchMissionPlan = createAsyncThunk<
  void,
  { id: string; data: string; hideToast: boolean }
>(
  "missions/fetchPatchMissionPlan",
  async (
    missionPlanData: { id: string; data: string; hideToast: boolean },
    thunkAPI
  ) => {
    try {
      const response = await API.patch(
        `missions/${missionPlanData.id}/`,
        missionPlanData.data
      );
      await thunkAPI.dispatch(updateMissionPlan(response.data));
      if (!missionPlanData.hideToast) {
        toast.success("Mission plan Changed");
      }
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchDeleteMissionPlan = createAsyncThunk<void, string>(
  "missions/fetchDeleteMissionPlan",
  async (id: string, thunkAPI) => {
    try {
      await API.delete(`missions/${id}`);
      await thunkAPI.dispatch(removeMissionPlan(id));
      toast.success("Mission plan Deleted");
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);

export const fetchDeployMissionPlan = createAsyncThunk(
  "missions/fetchDeployMissionPlan",
  async (data: {
    id: string;
    vehicle?: string;
    currentFleetId?: string;
    fleet?: string;
    stationId?: string;
  }) => {
    try {
      const missionPlans = await API.post("/fleets/mission_queue/", {
        ...data,
        //it is requires to support tracking of an operator-launched mission
        mission: data.id,
      });
      toast.success("Mission plan Deployed");
      return missionPlans.data;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
