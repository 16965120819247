import styled from "styled-components";
import CheckIcon from "./assets/checkIcon.svg";

export const ConfirmActionModalStyled = styled.div`
  position: relative;
  height: 192px;
  width: 438px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background: #ffffff;
  box-sizing: border-box;
  z-index: 1;
  font-family: "Readex Pro", sans-serif;
`;

export const HeaderStyled = styled.div`
  width: 100%;
  font-weight: 500;
  line-height: 128%;
  display: flex;
  align-items: center;
  color: #1b1b1b;
  height: 48px;
  width: 100;
  box-sizing: border-box;
  padding: 0 16px;
  border-bottom: 1px solid #e9e9e9;
`;

export const MessageGeneralTextStyled = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 140%;
  color: #767676;
  margin-bottom: 24px;
`;

export const ContentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
  justify-content: space-between;
  flex: 1;
`;

const ButtonStyled = styled.div`
  height: 48px;
  font-weight: 400;
  line-height: 128%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  cursor: pointer;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface SubmitButtonInterface {
  color?: string;
}

export const SubmitButtonStyled = styled(ButtonStyled)<SubmitButtonInterface>`
  width: 107px;
  background: ${({ color }) => (color === "red" ? "#df2020 " : "#3f7f7d")};
  border: 2px solid transparent;
  color: #ffffff;
  margin-left: 8px;

  &:hover {
    border: 2px solid
      ${({ color }) => (color === "red" ? "#b21919 " : "#0f625f")};
  }

  &:active {
    background: ${({ color }) => (color === "red" ? "#b21919 " : "#0f625f")};
  }
`;

interface CancelButtonInterface {
  longText?: string;
}

export const CancelButtonStyled = styled(ButtonStyled)<CancelButtonInterface>`
  width: ${({ longText }) => (longText ? "150px" : "107px")};
  border: 2px solid ${({ longText }) => (longText ? "transparent" : "#d1d1d1")};
  background: ${({ longText }) => (longText ? "#df2020" : "#ffffff")};
  color: ${({ longText }) => (longText ? "#ffffff" : "#000000")};

  &:hover {
    border: 2px solid ${({ longText }) => (longText ? "#b21919" : "#bbbbbb")};
  }

  &:active {
    background: ${({ longText }) => (longText ? "#b21919" : "#e9e9e9")};
  }
`;

export const CheckboxContainerStyled = styled.div`
  position: absolute;
  left: 16px;
`;

export const CheckboxStyled = styled.input.attrs({
  type: "checkbox",
  id: "checkbox",
})`
  display: none;

  &:checked + label {
    font-weight: 400;
    color: #1b1b1b;

    &::before {
      background-color: #3f7f7d;
      border: none;
    }

    &::after {
      opacity: 1;
    }
  }
`;

export const CheckboxTextStyled = styled.label.attrs({ htmlFor: "checkbox" })`
  font-weight: 300;
  color: #606060;
  cursor: pointer;
  position: relative;
  padding-left: 30px;

  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    vertical-align: bottom;
    border: 2px solid #a4a4a4;
    border-radius: 50%;
    z-index: 1;
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    vertical-align: bottom;
    background: url(${CheckIcon}) no-repeat center;
    border-radius: 50%;
    z-index: 2;
  }
`;
