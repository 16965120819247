import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { getIsAdmin } from "Slices/currentUser";
import { getIsMissionOperator } from "Slices/currentUser/selectors";
import { ChildrenProps } from "Utils/types";

export const OnlyForAdminGuard = ({ children }: ChildrenProps) => {
  const isUserAdmin = useSelector(getIsAdmin);

  if (!isUserAdmin) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
};

export const NotForMissionOperatorGuard = ({ children }: ChildrenProps) => {
  const isMissionOperator = useSelector(getIsMissionOperator);

  if (isMissionOperator) return <Navigate to="/operator" />;

  return <>{children}</>;
};
