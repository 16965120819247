import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetFlags = createAsyncThunk(
  "flags/fetchGetFlags",
  async () => {
    try {
      const response = await API.get("feature_flags/");

      return response.data.results;
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
