import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ChildrenProps } from "Utils/types";
import { fetchCheckAccessToken, getExpirationDate } from "Slices/appState";

export const TokenValidator = ({ children }: ChildrenProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expirationDate = useSelector(getExpirationDate);

  // expirationDate sets inside fetchCheckAccessToken
  useEffect(() => {
    dispatch(fetchCheckAccessToken(() => navigate("/login")));
  }, []);

  return <>{expirationDate ? children : null}</>;
};
