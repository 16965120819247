import { createSlice } from "@reduxjs/toolkit";

import { getLocalStorageItem, storageKeys } from "Utils/localStorageUtils";

import { fetchGetCurrentUser } from "./actions";
import { DataStatus } from "../../types";

type CurrentUserState = {
  data: User | null;
  status: DataStatus;
  error: string | null;
};

const emptyUserState: User = {
  id: "",
  email: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  companyId: "",
  companyName: "",
  status: "",
  isActive: false,
  role: null,
  isImageLoading: false,
  isPhoneNumberUpdating: false,
};

const getCurrentUserInitialData = () => {
  const cashedFirstName = getLocalStorageItem(storageKeys.USER_FIRST_NAME);
  const cashedLastName = getLocalStorageItem(storageKeys.USER_LAST_NAME);
  const cashedCompanyName = getLocalStorageItem(storageKeys.USER_COMPANY);
  const cashedImage = getLocalStorageItem(storageKeys.USER_IMAGE_LINK);
  const cashedRole = getLocalStorageItem(storageKeys.USER_ROLE);

  if (
    !cashedFirstName &&
    !cashedLastName &&
    !cashedCompanyName &&
    !cashedImage &&
    !cashedRole
  )
    return null;

  return {
    ...emptyUserState,
    firstName: cashedFirstName || "",
    lastName: cashedLastName || "",
    companyName: cashedCompanyName || "",
    image: {
      id: "",
      link: cashedImage || "",
    },
    role: cashedRole || "",
  };
};

const initialState: CurrentUserState = {
  data: getCurrentUserInitialData(),
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "currentUser",
  initialState,
  reducers: {
    resetUser: () => {
      return {
        data: null,
        status: DataStatus.idle,
        error: null,
      };
    },
    setCurrentUser: (state, { payload }) => {
      return payload;
    },
    setCurrentUserImage: (state, { payload }) => {
      const currentData = state.data || emptyUserState;
      currentData.image = {
        ...(currentData.image as UserImage),
        link: payload,
      };
      state.data = currentData;
      return state;
    },
    setImageLoading: (state, { payload }) => {
      const currentData = state.data || emptyUserState;
      currentData.isImageLoading = payload;
      state.data = currentData;
      return state;
    },
    setPhoneNumber: (state, { payload }) => {
      const currentData = state.data || emptyUserState;
      currentData.phoneNumber = payload;
      state.data = currentData;
      return state;
    },
    setPhoneNumberUpdating: (state, { payload }) => {
      const currentData = state.data || emptyUserState;
      currentData.isPhoneNumberUpdating = payload;
      state.data = currentData;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCurrentUser.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetCurrentUser.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetCurrentUser.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
