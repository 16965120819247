import styled from "styled-components";

export const CallVehicleBodyStyled = styled.div`
  height: 101px;
  border-top: 1px solid #e9e9e9;
  padding: 21px 32px 24px 32px;
`;

export const CallVehicleButtonStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  height: 56px;
  background: #3f7f7d;
  font-size: 20px;
  font-family: "Red Hat Display", sans-serif;
  color: #ffffff;
  cursor: pointer;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;
