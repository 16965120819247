import { createAsyncThunk } from "@reduxjs/toolkit";

import API, { showNetworkErrorToast } from "Utils/api";

export const fetchGetStops = createAsyncThunk(
  "stops/fetchGetStopsBySemanticMapId",
  async (semanticMapId: string) => {
    try {
      const response = await API.get(`/stops/?semantic_map=${semanticMapId}`);

      return { stops: response.data.results || [], semanticMapId };
    } catch (error) {
      showNetworkErrorToast(error);
      return Promise.reject();
    }
  }
);
