import React from "react";

import { ReactComponent as EmptyImg } from "../../../common/assets/empty.svg";

import {
  EmptyCoverStyled,
  EmptyCoverTextStyled,
  EmptyCoverBtnStyled,
} from "./styles";

export const EmptyCover = ({ btnClick }) => {
  return (
    <EmptyCoverStyled>
      <EmptyImg />
      <EmptyCoverTextStyled>
        Looks like you have no mission history saved.
      </EmptyCoverTextStyled>
      <EmptyCoverBtnStyled onClick={btnClick}>
        Plan a New Mission
      </EmptyCoverBtnStyled>
    </EmptyCoverStyled>
  );
};
