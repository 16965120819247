import React, { useCallback, useEffect, useState, memo } from "react";
import { useDispatch } from "react-redux";
import Gamepad from "react-gamepad";
import { toast } from "react-toastify";

import { toggleModal, GAMEPAD_SETTINGS_MODAL } from "Slices/modals";
import { ButtonComponent } from "../../common/ButtonComponent/Button.component";
import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../common/ModalComponent/styles";

import {
  GamepadSettingsModalStyled,
  GamepadSettingsModalHeaderStyled,
  GamepadSettingsLineStyled,
  GamepadSettingsButtonStyled,
  GamepadSettingsInfoStyled,
  GamepadSettingsButtonsContainerStyled,
} from "./styles";

export interface ObjectKeysType {
  [key: string]: string | number;
}

const getButtonTextByState = (stateValue: string) =>
  ({
    notSet: "Not Set",
    pending: "Cancel",
  }[stateValue] || `Set: ${stateValue}`);

const INFO_TEXT: ObjectKeysType = {
  notSet: "Click on red/green button to set/change value",
  pending:
    "Set the value by using the desired button or axis on the gamepad, or click on yellow button for cancel",
};

interface StylesInterface {
  marginBottom: number;
  width?: number;
  fontSize: number;
}

const BUTTON_STYLES: StylesInterface = {
  width: 120,
  fontSize: 14,
  marginBottom: 0,
};

export const GamepadSettingsModal = memo(() => {
  const dispatch = useDispatch();
  const gamepadSettings =
    JSON.parse(window.localStorage.getItem("GamepadSettings") || "") || {};
  const [gasAxiosKey, setGasAxiosKey] = useState(
    gamepadSettings.gasAxiosKey || "notSet"
  );
  const [turnAxiosKey, setTurnAxiosKey] = useState(
    gamepadSettings.turnAxiosKey || "notSet"
  );
  const [breakAxiosKey, setBreakAxiosKey] = useState(
    gamepadSettings.breakAxiosKey || "notSet"
  );
  const [remoteControlKey, setRemoteControlKey] = useState(
    gamepadSettings.autonomousKey || "notSet"
  );
  const [autonomousKey, setAutonomousKey] = useState(
    gamepadSettings.autonomousKey || "notSet"
  );
  const [manualKey, setManualKey] = useState(
    gamepadSettings.manualKey || "notSet"
  );
  const [infoValue, setInfoValue] = useState("notSet");
  const isPending = infoValue === "pending";

  const getButtonState = useCallback(
    (value: string) => {
      if (isPending && value !== "pending") {
        return "disabled";
      }
      return value;
    },
    [infoValue]
  );

  const setAction = (
    keyOfSetValue: string,
    setValueByKey: (value: string) => void
  ) => {
    if (keyOfSetValue === "pending") {
      setValueByKey("notSet");
    }
    if (!isPending && keyOfSetValue !== "pending") {
      setValueByKey("pending");
    }
  };

  useEffect(() => {
    if (
      [
        gasAxiosKey,
        turnAxiosKey,
        breakAxiosKey,
        remoteControlKey,
        autonomousKey,
        manualKey,
      ].includes("pending")
    ) {
      setInfoValue("pending");
    } else {
      if (infoValue === "pending") {
        setInfoValue("notSet");
      }
    }
  }, [
    gasAxiosKey,
    turnAxiosKey,
    breakAxiosKey,
    remoteControlKey,
    autonomousKey,
    manualKey,
  ]);

  const onButtonChangeAction = (keyEvent: string) => {
    if (isPending) {
      if (remoteControlKey === "pending") {
        setRemoteControlKey(keyEvent);
      }
      if (autonomousKey === "pending") {
        setAutonomousKey(keyEvent);
      }
      if (manualKey === "pending") {
        setManualKey(keyEvent);
      }
    }
  };

  const onAxisChangeAction = (keyEvent: string) => {
    if (isPending) {
      if (gasAxiosKey === "pending") {
        setGasAxiosKey(keyEvent);
      }
      if (turnAxiosKey === "pending") {
        setTurnAxiosKey(keyEvent);
      }
      if (breakAxiosKey === "pending") {
        setBreakAxiosKey(keyEvent);
      }
    }
  };

  const saveChangesAction = () => {
    if (
      [
        gasAxiosKey,
        turnAxiosKey,
        breakAxiosKey,
        remoteControlKey,
        autonomousKey,
        manualKey,
      ].includes("notSet")
    ) {
      toast.error("Set all values");
      return;
    }

    localStorage.setItem(
      "GamepadSettings",
      JSON.stringify({
        gasAxiosKey,
        turnAxiosKey,
        breakAxiosKey,
        remoteControlKey,
        autonomousKey,
        manualKey,
      })
    );
    toast.success("Settings changed");
    dispatch(toggleModal({ type: GAMEPAD_SETTINGS_MODAL }));
  };

  return (
    <ModalStyled>
      <Gamepad
        onButtonChange={onButtonChangeAction}
        onAxisChange={onAxisChangeAction}
      >
        <div />
      </Gamepad>
      <ModalBackgroundStyled
        onClick={() => dispatch(toggleModal({ type: GAMEPAD_SETTINGS_MODAL }))}
      />
      <GamepadSettingsModalStyled>
        <GamepadSettingsModalHeaderStyled>
          Gamepad Settings
        </GamepadSettingsModalHeaderStyled>
        <GamepadSettingsInfoStyled>
          {INFO_TEXT[infoValue]}
        </GamepadSettingsInfoStyled>
        <GamepadSettingsLineStyled>
          <span>Acceleration</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(gasAxiosKey, setGasAxiosKey)}
            currentState={getButtonState(gasAxiosKey)}
          >
            {getButtonTextByState(gasAxiosKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsLineStyled>
          <span>Turn</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(turnAxiosKey, setTurnAxiosKey)}
            currentState={getButtonState(turnAxiosKey)}
          >
            {getButtonTextByState(turnAxiosKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsLineStyled>
          <span>Break</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(breakAxiosKey, setBreakAxiosKey)}
            currentState={getButtonState(breakAxiosKey)}
          >
            {getButtonTextByState(breakAxiosKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsLineStyled>
          <span>Remote Control</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(remoteControlKey, setRemoteControlKey)}
            currentState={getButtonState(remoteControlKey)}
          >
            {getButtonTextByState(remoteControlKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsLineStyled>
          <span>Autonomous</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(autonomousKey, setAutonomousKey)}
            currentState={getButtonState(autonomousKey)}
          >
            {getButtonTextByState(autonomousKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsLineStyled>
          <span>Manual</span>
          <GamepadSettingsButtonStyled
            onClick={() => setAction(manualKey, setManualKey)}
            currentState={getButtonState(manualKey)}
          >
            {getButtonTextByState(manualKey)}
          </GamepadSettingsButtonStyled>
        </GamepadSettingsLineStyled>
        <GamepadSettingsButtonsContainerStyled>
          <ButtonComponent
            clickAction={saveChangesAction}
            type={"primary"}
            {...BUTTON_STYLES}
          >
            Save Changes
          </ButtonComponent>
          <ButtonComponent
            clickAction={() =>
              dispatch(toggleModal({ type: GAMEPAD_SETTINGS_MODAL }))
            }
            type={"cancel"}
            {...BUTTON_STYLES}
          >
            Cancel
          </ButtonComponent>
        </GamepadSettingsButtonsContainerStyled>
      </GamepadSettingsModalStyled>
    </ModalStyled>
  );
});
