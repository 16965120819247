import React, { memo, useMemo } from "react";
import { useSelector } from "react-redux";

import {
  UserInfoContainerStyled,
  UserInfoImageContainerStyled,
  UserInfoDataContainerStyled,
  UserInfoMetaNameStyled,
} from "../../styles";
import { UserInfoMetaValue } from "./styles";
import { ReactComponent as CompanyInfoImage } from "../../assets/companyInfo.svg";
import { ReactComponent as EmailInfoImage } from "../../assets/emailInfo.svg";

import {
  getCurrentUserCompanyName,
  getCurrentUserEmail,
} from "Slices/currentUser";

export const UserInfo = memo(() => {
  const companyName = useSelector(getCurrentUserCompanyName);
  const email = useSelector(getCurrentUserEmail);

  const userMetaInfoData = useMemo(
    () => [
      {
        imageComponent: <CompanyInfoImage />,
        userInfoField: "Company",
        userInfoValue: companyName,
      },
      {
        imageComponent: <EmailInfoImage />,
        userInfoField: "Email",
        userInfoValue: email,
      },
    ],
    [companyName, email]
  );

  return (
    <>
      {userMetaInfoData.map(
        ({ imageComponent, userInfoField, userInfoValue }) => (
          <UserInfoContainerStyled key={userInfoField}>
            <UserInfoImageContainerStyled>
              {imageComponent}
            </UserInfoImageContainerStyled>
            <UserInfoDataContainerStyled>
              <UserInfoMetaNameStyled>{userInfoField}</UserInfoMetaNameStyled>
              <UserInfoMetaValue>{userInfoValue}</UserInfoMetaValue>
            </UserInfoDataContainerStyled>
          </UserInfoContainerStyled>
        )
      )}
    </>
  );
});
