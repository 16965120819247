import { useEffect, useRef, useState } from "react";
import useWebSocket from "react-use-websocket";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { REACT_APP_WSS_URL_API } from "./api";
import { FMSLogger } from "FMSLogger";
import { usePrevious } from "./hooks";
import { PrefixedLogger } from "FMSLogger/PrefixedLogger";
import { refreshTokenAction } from "Slices/appState";

export const getLastMessageData = (event: MessageEvent) => {
  try {
    return JSON.parse(event.data);
  } catch {
    return null;
  }
};

type SubscriptionData = string | null | string[] | { [key: string]: any };

type useFMSWebsocketProps = {
  url: string;
  subscriptionData: SubscriptionData;
  loggerFlag: string;
  getDataForSubscribe: (subscriptionData: any) => Record<string, any>;
  getDataForUnsubscribe: (subscriptionData: any) => Record<string, any>;
  handleInitialMessage?: (data: any) => void;
  handleMessage?: (data: any) => void;
};

export const useFMSWebsocket = ({
  url,
  subscriptionData,
  loggerFlag,
  getDataForSubscribe,
  getDataForUnsubscribe,
  handleInitialMessage,
  handleMessage,
}: useFMSWebsocketProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [socketAuthSuccessful, setSocketAuthSuccessful] = useState(false);
  const prevSubscriptionData = usePrevious(subscriptionData);

  const prefixedLoggerRef = useRef<PrefixedLogger>(
    FMSLogger.byPrefix(loggerFlag)
  );

  // subscribe/unsubscribe handler
  useEffect(() => {
    if (!socketAuthSuccessful) return;

    if (prevSubscriptionData && prevSubscriptionData !== subscriptionData) {
      prefixedLoggerRef.current.info(
        "WS unsubscribed from:",
        getDataForUnsubscribe(prevSubscriptionData)
      );
      sendMessage(
        JSON.stringify({
          action: "unsubscribe",
          data: getDataForUnsubscribe(prevSubscriptionData),
        })
      );
    }

    if (!subscriptionData) return;
    prefixedLoggerRef.current.info(
      "WS subscribed to:",
      getDataForUnsubscribe(subscriptionData)
    );
    sendMessage(
      JSON.stringify({
        action: "subscribe",
        data: getDataForSubscribe(subscriptionData),
      })
    );
  }, [socketAuthSuccessful, subscriptionData]);

  const login = () => {
    const token = window.localStorage.getItem("JWT");
    prefixedLoggerRef.current.info("WS login", token ?? "no token!");
    sendMessage(
      JSON.stringify({
        action: "login",
        data: {
          Authorization: token,
        },
      })
    );
  };

  const handleUnauthorized = async () => {
    prefixedLoggerRef.current.error("handleUnauthorized");
    setSocketAuthSuccessful(false);
    await dispatch(refreshTokenAction(() => navigate("/login")));
    login();
  };

  const { sendMessage, getWebSocket } = useWebSocket(
    `${REACT_APP_WSS_URL_API}${url}`,
    {
      onOpen: () => {
        prefixedLoggerRef.current.info("WS has been opened");
        login();
      },
      onClose: () => {
        prefixedLoggerRef.current.info("WS has been closed");
        setSocketAuthSuccessful(false);
      },
      onMessage: (event) => {
        const { type = null, data = null } = getLastMessageData(event);
        if (type === "auth") {
          prefixedLoggerRef.current.info("WS auth successful:", data);
          setSocketAuthSuccessful(true);
        } else if (type === "create") {
          prefixedLoggerRef.current.debug("WS got create message:", data);
          handleInitialMessage?.(data);
        } else if (type === "update") {
          prefixedLoggerRef.current.debug("WS got new message:", data);
          handleMessage?.(data);
        } else if (type === "error") {
          prefixedLoggerRef.current.error("WS got error message:", data);
          if (data?.code === 401) {
            handleUnauthorized();
          }
        }
      },
      shouldReconnect: () => {
        return true;
      },
      reconnectInterval: 300,
      retryOnError: true,
    }
  );

  // Close webSocket on unmount
  useEffect(
    () => () => {
      prefixedLoggerRef.current.info("WS has been closed due to unmount");
      getWebSocket()?.close();
    },
    []
  );
};
