import React, { memo } from "react";
import { RealTimeMap } from "Components/RealTimeMap/RealTimeMap";
import { RealTimeMapController } from "./RealTimeMap.controller";
import { RealTimeMapLoader } from "./RealTimeMap.loader";

export const RealTimeMapPage = memo(() => {
  return (
    <RealTimeMapLoader>
      <RealTimeMapController />
      <RealTimeMap />
    </RealTimeMapLoader>
  );
});
