import React, { Dispatch, FC, SetStateAction } from "react";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker, Range } from "react-date-range";

type DatePickerProps = {
  datesInterval: Range[];
  setDatesInterval: Dispatch<SetStateAction<[Range]>>;
};

export const DatePicker: FC<DatePickerProps> = ({
  datesInterval,
  setDatesInterval,
}) => {
  const handleOnChange = (selection: Range) => {
    setDatesInterval([selection]);
  };

  return (
    <DateRangePicker
      onChange={(ranges) => handleOnChange(ranges.selection)}
      months={2}
      ranges={datesInterval}
      direction="horizontal"
      showDateDisplay={false}
      className={"DateRangePicker"}
      inputRanges={[]}
    />
  );
};
