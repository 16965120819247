import React, { FC, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ButtonComponent } from "Components/common/ButtonComponent/Button.component";
import { AsideLoader } from "Components/common/Loader";
import {
  getIsMissionPlansLoadedForCurrentSemanticMap,
  getMissionsPlansByCurrentSemanticMap,
} from "Slices/missionPlans/selectors";
import { fetchDeleteMissionPlan } from "Slices/missionPlans";
import { MISSION_DEPLOYMENT_ACTIONS } from "Utils/constants";
import { useMissionPlansLoader } from "Utils/loaders";
import { FMSLogger } from "FMSLogger";

import { EmptyCover } from "../EmptyCover";
import { MissionItem } from "../MissionItem";
import { MissionListContentStyled } from "../../styles";
import { getCurrentSemanticMapId } from "Slices/appState";
import { MissionPlansLoadingError } from "../MissionPlansLoadingError";

const missionDeploymentActionsLogger = FMSLogger.byPrefix(
  MISSION_DEPLOYMENT_ACTIONS
);

type MissionListProps = {
  isShowAssignPicker: boolean;
  setActiveTab: (activeTab: string) => void;
  cancelAction: () => void;
  setEditData: () => void;
  deployAction: (missionAsset: MissionAsset) => void;
  savedMissionsDisabled: boolean;
};

export const MissionList: FC<MissionListProps> = memo(
  ({
    isShowAssignPicker,
    setActiveTab,
    cancelAction,
    setEditData,
    deployAction,
    savedMissionsDisabled,
  }) => {
    const dispatch = useDispatch();
    const missionPlansForCurrentSemanticMap = useSelector(
      getMissionsPlansByCurrentSemanticMap
    );
    const isMissionPlansLoadedForCurrentSemanticMap = useSelector(
      getIsMissionPlansLoadedForCurrentSemanticMap
    );
    const deletePlanMissionAction = (id: string) =>
      dispatch(fetchDeleteMissionPlan(id));

    const currentSemanticMapId = useSelector(getCurrentSemanticMapId);

    const { isMissionPlansLoading, isMissionPlansLoadingError } =
      useMissionPlansLoader(currentSemanticMapId);

    return (
      <MissionListContentStyled isShowAssignPicker={isShowAssignPicker}>
        {isMissionPlansLoading && <AsideLoader />}
        {isMissionPlansLoadingError && <MissionPlansLoadingError />}
        {isMissionPlansLoadedForCurrentSemanticMap && (
          <>
            {missionPlansForCurrentSemanticMap.length === 0 && (
              <EmptyCover
                btnClick={() => {
                  missionDeploymentActionsLogger.debug(
                    "new mission from empty cover"
                  );
                  setActiveTab?.("newMission");
                }}
              />
            )}
            {missionPlansForCurrentSemanticMap.map((missionPlan, index) => (
              <MissionItem
                key={missionPlan.id}
                missionData={missionPlan}
                currentIndex={index}
                setEditData={setEditData}
                deletePlanMissionAction={deletePlanMissionAction}
                savedMissionsDisabled={savedMissionsDisabled}
                deployAction={deployAction(missionPlan)}
              />
            ))}
            <ButtonComponent
              clickAction={() => {
                missionDeploymentActionsLogger.debug("cancel on mission list");
                cancelAction();
              }}
              type="cancel"
              posCenter={true}
              fontSize={14}
              marginBottom={0}
            >
              Cancel
            </ButtonComponent>
          </>
        )}
      </MissionListContentStyled>
    );
  }
);
