import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { DataStatus } from "../../types";
import { getCurrentSemanticMapId } from "Slices/appState";

export const getSemanticMaps = (state: RootState): Array<SemanticMap> =>
  state.semanticMaps.data;
export const getCurrentSemanticMap = createSelector(
  getCurrentSemanticMapId,
  getSemanticMaps,
  (currentSemanticMapId, semanticMaps) =>
    semanticMaps.find(
      (semanticMap) => semanticMap.id === currentSemanticMapId
    ) || null
);
export const getIsSemanticMapsLoaded = (state: RootState): boolean =>
  state.semanticMaps.status === DataStatus.succeeded;
export const getIsSemanticMapsLoadingError = (state: RootState): boolean =>
  state.semanticMaps.status === DataStatus.failed;
export const getSemanticMapModalItems = createSelector(
  getSemanticMaps,
  (semanticMaps) =>
    [...semanticMaps]
      .sort((prev, next) =>
        prev?.name?.toLowerCase().localeCompare(next?.name?.toLowerCase())
      )
      .map((semanticMap) => ({
        id: semanticMap.id,
        text: semanticMap.name,
      }))
);
