import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import { closeAnyModal, getModalData } from "Slices/modals";

import { ReactComponent as InvitationImage } from "./assets/invitationImage.svg";
import {
  InvitationSentModalStyled,
  HeaderStyled,
  TextStyled,
  CloseButtonStyled,
} from "./styles";

export const InvitationSentModal = memo(() => {
  const dispatch = useDispatch();
  const email = useSelector(getModalData);

  const handleCloseModal = useCallback(() => {
    dispatch(closeAnyModal());
  }, []);

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseModal} />
      <InvitationSentModalStyled>
        <CloseButtonStyled onClick={handleCloseModal} />
        <InvitationImage />
        <HeaderStyled>Invitation Sent</HeaderStyled>
        <TextStyled>
          Invitation has been sent to <span>{String(email)}</span>
        </TextStyled>
      </InvitationSentModalStyled>
    </ModalStyled>
  );
});
