import styled, { css, keyframes } from "styled-components";

export const MainListContentStyled = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 72px);
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 900px;
`;

export const ListPageStyled = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px;
`;

const loadingAnimation = keyframes`
  to {
    background-position-x: -200%;
  }
`;

export const LoadingStyles = css`
  cursor: default;
  background: linear-gradient(110deg, #f4f4f4 8%, #dcdcdc 18%, #f4f4f4 28%);
  animation-name: ${loadingAnimation};
  background-size: 200% 100%;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;
