import { RootState } from "../..";
import { createSelector } from "@reduxjs/toolkit";
import { arrayToObjectByField } from "Utils";
import { DataStatus } from "../../types";

const AVAILABLE_ROLES = ["Admin", "Mission Operator"];

export const getRoles = (state: RootState) => state.roles.data;
export const getRolesById = createSelector(getRoles, (roles) =>
  arrayToObjectByField(roles)
);

export const getAvailableRoles = createSelector(getRoles, (roles) => {
  return roles.filter((role) => AVAILABLE_ROLES.includes(role?.name));
});
export const getIsRolesLoaded = (state: RootState): boolean =>
  state.roles.status === DataStatus.succeeded;
export const getIsRolesLoadingFailed = (state: RootState): boolean =>
  state.roles.status === DataStatus.failed;
