import React, { Dispatch, FC, memo, SetStateAction, useMemo } from "react";
import { Dropdown } from "Components/Modals/ListDataPagesModals/components/Dropdown";
import { SearchDropdown } from "Components/Modals/ListDataPagesModals/components/SearchDropdown";
import { sortModalItemsAlphabetically } from "Utils/collectionUtils";

type MissionPlansPickerProps = {
  isMissionPlansLoading?: boolean;
  isMissionPlansLoadingError?: boolean;
  missionPlans: ModalItem[];
  returnHomeMission: ModalItem | null;
  setReturnHomeMission: Dispatch<SetStateAction<ModalItem | null>>;
  pickedOnVehicleMissions: ModalItem[];
  setPickedOnVehicleMissions: Dispatch<SetStateAction<ModalItem[]>>;
};

export const MissionPlansPicker: FC<MissionPlansPickerProps> = memo(
  ({
    isMissionPlansLoading = false,
    isMissionPlansLoadingError = false,
    missionPlans,
    returnHomeMission,
    setReturnHomeMission,
    pickedOnVehicleMissions,
    setPickedOnVehicleMissions,
  }) => {
    const sortedMissionPlans: Array<ModalItem> = useMemo(
      () => sortModalItemsAlphabetically(missionPlans),
      [missionPlans]
    );

    return (
      <>
        <Dropdown
          isLoading={isMissionPlansLoading}
          isMissionPlansLoadingError={isMissionPlansLoadingError}
          isIncludesEmptyValue={true}
          currentValue={returnHomeMission}
          labelText="Return Home Mission"
          options={sortedMissionPlans}
          additionalInfo="Please select Return Home Mission"
          changeAction={setReturnHomeMission}
        />
        <SearchDropdown
          isLoading={isMissionPlansLoading}
          isMissionPlansLoadingError={isMissionPlansLoadingError}
          pickedItems={pickedOnVehicleMissions}
          options={sortedMissionPlans}
          changeAction={setPickedOnVehicleMissions}
          labelText="On Vehicle Missions"
          placeholderText="Search Missions"
          additionalInfo="Please select missions to be deployed from the vehicles"
        />
      </>
    );
  }
);
