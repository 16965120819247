import { Dispatch, SetStateAction } from "react";

const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string): boolean {
  return emailRegExp.test(email);
}

export const getTodayStartISO = () => {
  const dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);
  return dateNow.toISOString();
};
export const getTodayEndISO = () => {
  const dateNow = new Date();
  dateNow.setHours(23, 59, 59, 999);
  return dateNow.toISOString();
};

export const capitalizeFirstLetter = (string: string) =>
  !string ? string : string[0].toUpperCase() + string.slice(1);

export const isCoordinatesValid = (
  longitude: number | null,
  latitude: number | null
) =>
  (longitude === null ? true : longitude > -180 && longitude < 180) ||
  (latitude === null ? true : latitude > -90 && latitude < 90);

export type OrderType = 1 | -1;

export const EMPTY_ICON = "/assets/icons/empty.svg";

export function sortByColumn<T extends string, H extends Record<T, any>>(
  tableData: H[],
  columnName: T,
  order: OrderType
) {
  return tableData.sort(function (a, b) {
    if (a[columnName] === null) {
      return b[columnName] === null ? 0 : 1;
    } else if (b[columnName] === null) {
      return -1;
    }
    return (
      String(a[columnName]).localeCompare(String(b[columnName]), undefined, {
        numeric: true,
      }) * order
    );
  });
}

export function createReorderHandler<T extends Record<string, any>>(
  fieldName: string,
  data: T[],
  setData: Dispatch<SetStateAction<T[]>>,
  order: OrderType,
  setOrder: Dispatch<SetStateAction<OrderType>>
) {
  return () => {
    const newFleetsData = sortByColumn(data, fieldName, order);
    setData([...newFleetsData]);
    setOrder((order * -1) as OrderType);
  };
}

export const EMPTY_ARRAY: [] = [];
export const EMPTY_OBJECT: Record<string, never> = {};

export const convertVehicleData = (vehicleData: VehicleState) => {
  const {
    t_long,
    t_lat,
    vehicle_id,
    t_drive_mode,
    last_contact,
    online_status,
    mission_status,
  } = vehicleData;
  return {
    longitude: t_long,
    latitude: t_lat,
    id: vehicle_id,
    driveMode: t_drive_mode,
    lastContact: last_contact,
    online: online_status,
    missionStatus: mission_status,
  };
};

export const arrayToObjectByField = <T extends Record<string, unknown>>(
  currentArray: T[],
  fieldName: keyof T = "id"
): Record<string, T> => {
  return currentArray.reduce<Record<string, T>>((acc, arrayItem) => {
    const currentItemId = arrayItem[fieldName];

    if (currentItemId) {
      acc[currentItemId as string] = arrayItem;
    }
    return acc;
  }, {});
};

export const getDiffTime = (lastContact: string) => {
  const currentTime = new Date().getTime() - new Date(lastContact).getTime();
  const minuteToHourTreshold = 60;
  const hourToDayTreshold = 24;

  const minutes = Math.round(currentTime / 1000 / 60);
  if (minutes < minuteToHourTreshold) {
    return `${minutes} minutes ago`;
  }

  const hours = Math.round(minutes / 60);
  if (hours < hourToDayTreshold) {
    return `${hours} hours ago`;
  }

  const days = Math.round(hours / 24);
  return `${days} days ago`;
};

export const getServerErrorMessage = (problemData: string) =>
  `An error with loading ${problemData} data from the server has occurred.
  Further app functionality can't be guaranteed.
  Please try reloading the page or try to logout`;

export const isArraysEqual = (array1: Array<unknown>, array2: Array<unknown>) =>
  JSON.stringify(array1) === JSON.stringify(array2);
export const isPrimitivesEqual = (value: unknown, newValue: unknown) => {
  return value === newValue;
};
export const isObjectsEqual = (object1: any, object2: any) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !isObjectsEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
};

export const isObject = (object: any) => {
  return object != null && typeof object === "object";
};
