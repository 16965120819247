import styled from "styled-components";

export const ChangeCompanyModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: calc(100% - 72px);
  background-color: #ffffff;
  box-sizing: border-box;
  padding: 24px 32px;
  z-index: 11;
  overflow-y: auto;
`;

export const HeaderStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #172a3f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  margin-bottom: 24px;
`;

export const CompaniesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemContentStyled = styled.div`
  border: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 102px;
  width: 100%;
  box-sizing: border-box;
  padding: 24px;
  margin-bottom: 12px;
  position: relative;

  &:after {
    content: "";
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #d9d9d9;
    position: absolute;
    left: -9px;
    bottom: -11px;
    transform: rotate(-140deg);
    z-index: 1;
    background: #ffffff;
  }
`;

export const CompanyNameStyled = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #1b1b1b;
`;

export const SelectButtonStyled = styled.div`
  width: 124px;
  height: 56px;
  background: #3f7f7d;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }
`;

export const CloseButtonStyled = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 128%;
  display: flex;
  align-items: center;
  color: #606060;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    margin-left: 4px;
  }
`;
