import styled from "styled-components";
import { NavLink } from "react-router-dom";

type SecondaryNavigationBarProps = {
  isShown: boolean;
};

export const SecondaryNavItemStyled = styled(NavLink)<{ active: boolean }>`
  border-bottom: ${(props) =>
    props.active ? "4px solid #55B8B3" : "4px solid transparent"};
  color: ${(props) => (props.active ? "#55B8B3" : "#bbbbbb")};
  cursor: pointer;
  text-decoration: none;
  padding: 8px 0 4px 0;

  &:hover {
    opacity: 0.8;
  }
}
`;

export const SecondaryNavBarItems = styled.div`
  display: flex;
  height: 100%;
`;

export const SecondaryNavTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Readex Pro", sans-serif;
`;

export const SecondaryNavigationBarStyled = styled.div<SecondaryNavigationBarProps>`
  position: absolute;
  z-index: 11;
  background: #172a3f;
  width: 100%;
  height: 48px;
  top: ${({ isShown }) => (isShown ? "72px" : "24px")};
  transition: top 0.2s ease-in-out;
  transition-delay: ${({ isShown }) => (isShown ? "0.1s" : "0.2s")};
`;
