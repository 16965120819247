import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { useOnClickOutside } from "Utils/hooks";
import { getSemanticMaps, getCurrentSemanticMap } from "Slices/semanticMaps";
import { setCurrentSemanticMapId } from "Slices/appState";
import { resetMissionsQueue } from "Slices/missionPlanner";
import { storageKeys } from "Utils/localStorageUtils";

import { ReactComponent as SelectIcon } from "../assets/selectIconBold.svg";
import {
  CurrentItemStyled,
  DropdownContentStyled,
  DropdownItemStyled,
  SemanticMapPickerDropdownStyled,
  SemanticMapPickerWrapperStyled,
  PlaceholderStyled,
} from "./styles";

export const SemanticMapPicker = memo(() => {
  const semanticMaps = useSelector(getSemanticMaps);
  const currentSemanticMap = useSelector(getCurrentSemanticMap);
  const dispatch = useDispatch();
  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;
  const handleClickOutside = useCallback(() => setShowDropdown(false), []);
  useOnClickOutside(ref, handleClickOutside);

  const isPickerEnabled = semanticMaps.length > 1;

  useEffect(() => {
    if (isPickerEnabled) return;

    setShowDropdown(false);
  }, [isPickerEnabled]);

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const selectedSemanticMapName = useMemo(() => {
    if (currentSemanticMap === undefined) {
      return "...";
    } else {
      return currentSemanticMap?.name;
    }
  }, [currentSemanticMap]);

  const handleSelectedSemanticMap = (semanticMapId: string | null) => {
    dispatch(resetMissionsQueue());
    localStorage.setItem(
      storageKeys.CURRENT_SEMANTIC_MAP_ID,
      JSON.stringify(semanticMapId)
    );
    dispatch(setCurrentSemanticMapId(semanticMapId));
    setShowDropdown(false);
  };

  return (
    <SemanticMapPickerWrapperStyled ref={ref} isDisabled={!isPickerEnabled}>
      <SemanticMapPickerDropdownStyled
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        active={showDropdown}
      >
        <div>
          <CurrentItemStyled>{selectedSemanticMapName}</CurrentItemStyled>
          <PlaceholderStyled>Zone</PlaceholderStyled>
        </div>
        {isPickerEnabled && <SelectIcon />}
      </SemanticMapPickerDropdownStyled>
      {isPickerEnabled && showDropdown && (
        <DropdownContentStyled>
          {semanticMaps.map((semanticMap) => (
            <DropdownItemStyled
              onClick={() => handleSelectedSemanticMap(semanticMap.id)}
              key={semanticMap.id}
            >
              {semanticMap.name}
            </DropdownItemStyled>
          ))}
        </DropdownContentStyled>
      )}
    </SemanticMapPickerWrapperStyled>
  );
});
