import { createSlice } from "@reduxjs/toolkit";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Utils";

type OperatorDataState = {
  operatorMissionState: {
    queuedMissionId?: string;
    status?: "NO_MISSION" | "IN_QUEUE" | "IN_PROGRESS";
    order?: number;
    fleetId?: string | null;
  };
  pickedFleetId: string | null;
  fleetMissionsState: Mission[];
};

const initialState: OperatorDataState = {
  operatorMissionState: EMPTY_OBJECT,
  fleetMissionsState: EMPTY_ARRAY,
  pickedFleetId: null,
};

export const Slice = createSlice({
  name: "operatorData",
  initialState,
  reducers: {
    setInitialMissionOperatorData: (state, action) => {
      state.operatorMissionState =
        action.payload.missionOperatorData ?? EMPTY_OBJECT;
      state.fleetMissionsState = action.payload.missions ?? EMPTY_ARRAY;
    },
    setMissionOperatorFleetId: (state, action) => {
      state.pickedFleetId = action.payload;
    },
    resetMissionOperatorData: () => initialState,
    updateMissionOperatorData: (state, action) => {
      if (action.payload.missionOperatorData) {
        state.operatorMissionState = action.payload.missionOperatorData;
      }

      if (action.payload.missions) {
        const receivedMission = action.payload.missions;
        const isNewMission = !state.fleetMissionsState?.find(
          (mission) => mission?.id === receivedMission?.id
        );
        if (isNewMission) {
          state.fleetMissionsState = [
            receivedMission,
            ...state.fleetMissionsState,
          ];
        } else {
          state.fleetMissionsState = state.fleetMissionsState.map((mission) =>
            mission.id === action.payload.missions.id
              ? {
                  ...receivedMission,
                }
              : {
                  ...mission,
                }
          );
        }
      }
    },
  },
});

export default Slice;
