import React, { FC } from "react";

import { ButtonComponent } from "../../common/ButtonComponent/Button.component";

import {
  MissionDetailsModalButtonsStyled,
  MissionDetailsModalContentStyled,
  MissionDetailsModalStyled,
  MissionDetailsModalTextStyled,
} from "../styles";

type MissionsModalProps = {
  text: string;
  confirmAction: () => void;
  cancelAction: () => void;
};

export const MissionsModal: FC<MissionsModalProps> = ({
  text,
  confirmAction,
  cancelAction,
}) => {
  return (
    <MissionDetailsModalStyled>
      <MissionDetailsModalContentStyled>
        <MissionDetailsModalTextStyled>{text}</MissionDetailsModalTextStyled>
        <MissionDetailsModalButtonsStyled>
          <ButtonComponent
            fontSize={14}
            marginBottom={0}
            width={120}
            clickAction={confirmAction}
            type="primary"
          >
            Yes
          </ButtonComponent>
          <ButtonComponent
            fontSize={14}
            marginBottom={0}
            width={120}
            clickAction={cancelAction}
            type="cancel"
          >
            No
          </ButtonComponent>
        </MissionDetailsModalButtonsStyled>
      </MissionDetailsModalContentStyled>
    </MissionDetailsModalStyled>
  );
};
