import React, { memo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../../common/ModalComponent/styles";
import { closeAnyModal } from "Slices/modals";
import { fetchInviteUser } from "Slices/users";
import { Input } from "../components/Input";
import { ModalWrapper } from "../components/ModalWrapper";

export const PeopleModal = memo(() => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastNamee] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const isDisabled = !firstName || !lastName || !email;

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  const sendInviteAction = useCallback(() => {
    if (isDisabled) return;
    dispatch(
      fetchInviteUser({
        firstName,
        lastName,
        email,
      })
    );
  }, [isDisabled, firstName, lastName, email]);

  const handleSetEmail = useCallback(
    (email: string) => setEmail(email.toLowerCase()),
    []
  );

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ModalWrapper
        headerText="Invite a teammate"
        actionButtonText="Send Invite"
        successAction={sendInviteAction}
        isActionButtonDisabled={isDisabled}
      >
        <Input
          value={firstName}
          labelText="First Name"
          additionalInfo="Please enter user first name"
          changeAction={setFirstName}
          isRequired={true}
          limitSymbols={25}
        />
        <Input
          value={lastName}
          labelText="Last Name"
          additionalInfo="Please enter user last name"
          changeAction={setLastNamee}
          isRequired={true}
          limitSymbols={25}
        />
        <Input
          value={email}
          labelText="Email Address"
          additionalInfo="Please enter user email address"
          changeAction={handleSetEmail}
          isRequired={true}
        />
      </ModalWrapper>
    </ModalStyled>
  );
});
