import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { checkForgotPassToken, confirmNewPassword } from "Slices/auth";
import {
  InputStyled,
  TitleStyled,
  SubmitStyled,
} from "Components/Authentication/styles";

import { NewPasswordContainer } from "./styles";

export const NewPasswordPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const sendNewPassword = () => {
    if (!token) {
      toast.error("Token not found");
      return;
    }
    if (password && confirmPassword && password === confirmPassword) {
      dispatch(
        confirmNewPassword({
          token,
          password,
          callback: () => navigate("/login"),
        })
      );
    } else {
      toast.error("The entered passwords do not match.");
    }
  };

  useEffect(() => {
    dispatch(
      checkForgotPassToken({ token, callback: () => navigate("/login") })
    );
  }, [token]);

  return (
    <NewPasswordContainer>
      <TitleStyled>Enter new password</TitleStyled>
      <InputStyled
        type="password"
        name="password"
        onChange={(e) => setPassword(e.currentTarget.value)}
        placeholder="Password"
        value={password}
      />
      <InputStyled
        type="password"
        name="confirmPassword"
        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        placeholder="Confirm password"
        value={confirmPassword}
      />
      <SubmitStyled onClick={sendNewPassword}>SUBMIT</SubmitStyled>
    </NewPasswordContainer>
  );
};
