import React, { FC, ReactNode, useCallback, useState } from "react";
import { toast } from "react-toastify";

const acceptableImageFormats = [
  "image/png",
  "image/jpg",
  "image/svg+xml",
  "image/svg",
];

interface DragAndDropIconHOCProps {
  children: (isDragActive: boolean) => ReactNode;
  setImageFile: (file: File | null) => void;
}

export const DragAndDropIconHOC: FC<DragAndDropIconHOCProps> = ({
  children,
  setImageFile,
}) => {
  const [isDragActive, setDragActive] = useState(false);

  const dragOverHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(true);
  }, []);
  const dragLeaveHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setDragActive(false);
  }, []);
  const onDropHandler = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    console.log(file.type);
    if (acceptableImageFormats.includes(file.type)) {
      setImageFile(file);
    } else {
      setImageFile(null);
      toast.error("Invalid image format");
    }
    setDragActive(false);
  }, []);

  return (
    <div
      onDragOver={dragOverHandler}
      onDragLeave={dragLeaveHandler}
      onDrop={onDropHandler}
    >
      {children(isDragActive)}
    </div>
  );
};
