import styled from "styled-components";

export const RoleContainerStyled = styled.div`
  display: flex;
  justify-content: center;
`;

export const RoleStyled = styled.div`
  background: #d1f0da;
  border-radius: 100px;
  padding: 4px 12px;

  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
`;
