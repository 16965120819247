import styled from "styled-components";

export const UserAvatarComponentStyled = styled.div`
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;
