import { createSlice } from "@reduxjs/toolkit";

import { fetchGetVehicles } from "./actions";
import { DataStatus } from "../../types";

type VehiclesState = {
  data: Array<Vehicle>;
  status: DataStatus;
  error: string | null;
};

const initialState: VehiclesState = {
  data: [],
  status: DataStatus.idle,
  error: null,
};

export const Slice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    resetVehicles: () => initialState,
    addNewVehicle: (state, { payload }) => {
      state.data = [payload, ...state.data];
      return state;
    },
    updateVehicle: (state, { payload }) => {
      state.data = state.data.map((vehicle) =>
        vehicle.id === payload.id ? payload : vehicle
      );
      return state;
    },
    updateVehicles: (state, { payload }) => {
      state.data = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetVehicles.pending, (state) => {
        state.status = DataStatus.loading;
        state.error = null;
      })
      .addCase(fetchGetVehicles.fulfilled, (state, action) => {
        state.status = DataStatus.succeeded;
        state.data = action.payload;
      })
      .addCase(fetchGetVehicles.rejected, (state, action) => {
        state.status = DataStatus.failed;
        state.error = action.error.message ?? "";
      });
  },
});

export default Slice;
