import React, { memo, useState } from "react";
import { useSelector } from "react-redux";

import {
  ModalStyled,
  ModalBackgroundStyled,
} from "../../common/ModalComponent/styles";
import { getModalData } from "Slices/modals";

import {
  ConfirmActionModalStyled,
  HeaderStyled,
  ContentStyled,
  ButtonsContainerStyled,
  CancelButtonStyled,
  SubmitButtonStyled,
  CheckboxContainerStyled,
  CheckboxStyled,
  CheckboxTextStyled,
  MessageGeneralTextStyled,
} from "./styles";

interface ConfirmActionData {
  headerText: string;
  message: JSX.Element;
  color?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmAction: (checkboxStatus: boolean) => void;
  cancelAction: () => void;
  isModalWithCheckbox?: boolean;
}

export const ConfirmActionModal = memo(() => {
  const [checkboxStatus, setCheckboxStatus] = useState<boolean>(false);
  const data = useSelector(getModalData);
  const {
    headerText,
    message,
    confirmAction,
    cancelAction,
    color,
    confirmButtonText,
    cancelButtonText,
    isModalWithCheckbox,
  } = data as unknown as ConfirmActionData;

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={cancelAction} />
      <ConfirmActionModalStyled>
        <HeaderStyled>{headerText}</HeaderStyled>
        <ContentStyled>
          <MessageGeneralTextStyled>{message}</MessageGeneralTextStyled>
          <ButtonsContainerStyled>
            {isModalWithCheckbox && (
              <CheckboxContainerStyled>
                <CheckboxStyled
                  checked={checkboxStatus}
                  onChange={() => setCheckboxStatus(!checkboxStatus)}
                />
                <CheckboxTextStyled>Don’t ask again</CheckboxTextStyled>
              </CheckboxContainerStyled>
            )}
            <CancelButtonStyled
              onClick={cancelAction}
              longText={cancelButtonText}
            >
              {cancelButtonText || "Cancel"}
            </CancelButtonStyled>
            <SubmitButtonStyled
              color={color}
              onClick={() => confirmAction(checkboxStatus)}
            >
              {confirmButtonText || "Confirm"}
            </SubmitButtonStyled>
          </ButtonsContainerStyled>
        </ContentStyled>
      </ConfirmActionModalStyled>
    </ModalStyled>
  );
});
