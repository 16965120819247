import React, { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getModalData,
  closeAnyModal,
  toggleModal,
  CONFIRM_ACTIVE_STATUS_USER_MODAL,
  USER_DETAILS_MODAL,
} from "Slices/modals";
import { ModalWrapper } from "../ListDataPagesModals/components/ModalWrapper";
import { UserAvatarComponent } from "../../common/UserAvatarComponent";
import { getUsersById, fetchPatchUser } from "Slices/users";
import { UserStatusIcon } from "../../common/UserStatusIcon";
import { RoleLabel } from "../../common/RoleLabel";

import { ReactComponent as MailIcon } from "./assets/mailIcon.svg";
import { ReactComponent as PhoneIcon } from "./assets/phoneIcon.svg";
import {
  NameBodyStyled,
  InfoStyled,
  StatusChangeButtonStyled,
  UserDetailsModalStyled,
  AvatarWrapperStyled,
  MessageBodyStyled,
  MessageStyled,
  NameStyled,
} from "./styles";
import {
  ModalBackgroundStyled,
  ModalStyled,
} from "../../common/ModalComponent/styles";

type UserDetailsModalDataType = {
  userId: string;
};

export const UserDetailsModal = memo(() => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const { userId } = modalData as unknown as UserDetailsModalDataType;
  const usersById = useSelector(getUsersById);

  const currentUser = usersById[String(userId)] || {};
  const { email, firstName, lastName, phoneNumber, image, status, role } =
    currentUser;
  const isUserActive = status === "active";

  const generateMessage = useCallback(
    (firstName: string, lastName: string, isUserActive: boolean) => (
      <MessageBodyStyled>
        Are you sure you want to {isUserActive ? "deactivate" : "reactivate"} “
        <MessageStyled>
          {firstName} {lastName}
        </MessageStyled>
        ”?
      </MessageBodyStyled>
    ),
    []
  );

  const changeUserStatus = useCallback(() => {
    dispatch(
      toggleModal({
        type: CONFIRM_ACTIVE_STATUS_USER_MODAL,
        data: {
          headerText: `Confirm ${
            isUserActive ? "Deactivation" : "Reactivation"
          }`,
          message: generateMessage(firstName, lastName, isUserActive),
          color: "red",
          confirmButtonText: "Confirm",
          confirmAction: () => {
            dispatch(
              fetchPatchUser({
                id: String(userId),
                data: { status: isUserActive ? "deactivated" : "active" },
                successMessage: `User was ${
                  isUserActive ? "deactivated" : "reactivated"
                }`,
              })
            );
            handleCloseAction();
          },
          cancelAction: () =>
            dispatch(toggleModal({ type: USER_DETAILS_MODAL, data: userId })),
        },
      })
    );
  }, [userId, isUserActive]);

  const handleCloseAction = () => {
    dispatch(closeAnyModal());
  };

  return (
    <ModalStyled>
      <ModalBackgroundStyled onClick={handleCloseAction} />
      <ModalWrapper headerText="User details" actionButtonText="User details">
        <UserDetailsModalStyled>
          <AvatarWrapperStyled>
            <UserAvatarComponent image={image} />
          </AvatarWrapperStyled>
          <NameBodyStyled>
            <NameStyled>{`${firstName} ${lastName}`}</NameStyled>
            <UserStatusIcon status={status} />
          </NameBodyStyled>
          <InfoStyled>
            <RoleLabel roleId={role} />
          </InfoStyled>
          <InfoStyled>
            <MailIcon />
            {email}
          </InfoStyled>
          <InfoStyled>
            <PhoneIcon />
            {phoneNumber || "No Data"}
          </InfoStyled>
          <StatusChangeButtonStyled
            onClick={changeUserStatus}
            isActive={isUserActive}
          >
            {isUserActive ? "Deactivate" : "Reactivate"}
          </StatusChangeButtonStyled>
        </UserDetailsModalStyled>
      </ModalWrapper>
    </ModalStyled>
  );
});
