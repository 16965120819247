import React, { memo } from "react";

import { FleetsListHeader } from "Components/ListPageHeader/FleetsListHeader";
import {
  MainListContentStyled,
  ListPageStyled,
} from "Components/common/styles";
import { FleetsList } from "Components/FleetsList/FleetsList";

import { FleetsListLoader } from "./FleetsList.loader";

export const FleetsListPage = memo(() => (
  <FleetsListLoader>
    <ListPageStyled>
      <FleetsListHeader />
      <MainListContentStyled>
        <FleetsList />
      </MainListContentStyled>
    </ListPageStyled>
  </FleetsListLoader>
));
