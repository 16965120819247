import { getLogin, refreshTokenAction, fetchCheckAccessToken } from "./actions";
import {
  getMissionPlannerData,
  getShowGlobalLoader,
  getExpirationDate,
  getBackUrl,
  getCurrentSemanticMapId,
} from "./selectors";
import AppStateSlice from "./reducers";

// actions
export const {
  setLoginErrorText,
  setMissionPlannerData,
  setCurrentSemanticMapId,
  setExpirationDate,
  setBackUrl,
  setShowGlobalLoader,
  resetAppState,
} = AppStateSlice.actions;

// async actions
export { getLogin, refreshTokenAction, fetchCheckAccessToken };

// selectors
export {
  getMissionPlannerData,
  getShowGlobalLoader,
  getExpirationDate,
  getBackUrl,
  getCurrentSemanticMapId,
};

// reducer
export default AppStateSlice.reducer;
