import { createSlice } from "@reduxjs/toolkit";

type Warning = { message: string | null; type: "primary" | "error" };

const initialState: Warning = { message: null, type: "primary" };

export const Slice = createSlice({
  name: "warning",
  initialState,
  reducers: {
    closeWarning: () => initialState,
    setWarning: (state, action) => {
      return {
        message: action.payload,
        type: "primary",
      };
    },
    setErrorWarning: (state, action) => {
      return {
        message: action.payload,
        type: "error",
      };
    },
  },
});

export default Slice;
