import { fetchGetActiveMissions } from "./actions";
import {
  getVehiclesPageVehicles,
  getFleetIdsRelatesToVehiclePage,
} from "./selectors";
import VehiclesPageVehiclesSlice from "./reducers";
import { prepareVehiclesData } from "./utils";

//utils
export { prepareVehiclesData };

// actions
export const {
  setVehiclesPageMissions,
  setVehiclesPageVehicles,
  setVehiclesPageFleets,
  setVehiclesPageTypeWithNewTypes,
  setVehiclesPageTypeWithUpdatedTypes,
  setVehiclesPageTypes,
  updateVehiclesPageMissions,
  updateVehicleDriveModeStatus,
  setInitialDriveModeStatuses,
  setVehiclesPageSorting,
  resetVehiclesPageData,
} = VehiclesPageVehiclesSlice.actions;

// async actions
export { fetchGetActiveMissions };

// selectors
export { getVehiclesPageVehicles, getFleetIdsRelatesToVehiclePage };

// reducer
export default VehiclesPageVehiclesSlice.reducer;
