import React, { memo, FC } from "react";
import { IconLoaderProps } from "../../types";

import { AddIconLoader } from "../AddIconLoader";
import { EditIconLoader } from "../EditIconLoader";

export const IconLoaderController: FC<IconLoaderProps> = memo(
  ({ setImageFile, imageFile, imageData }) =>
    imageFile || imageData ? (
      <EditIconLoader
        setImageFile={setImageFile}
        imageFile={imageFile}
        imageData={imageData}
      />
    ) : (
      <AddIconLoader setImageFile={setImageFile} />
    )
);
