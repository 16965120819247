import React, { memo } from "react";

import { VehicleTypesListHeader } from "Components/ListPageHeader/VehicleTypesListHeader";
import { VehicleTypesList } from "Components/VehicleTypesList/VehicleTypesList";
import {
  MainListContentStyled,
  ListPageStyled,
} from "Components/common/styles";

import { VehicleTypesListLoader } from "./VehicleTypesList.loader";

export const VehicleTypesListPage = memo(() => (
  <VehicleTypesListLoader>
    <ListPageStyled>
      <VehicleTypesListHeader />
      <MainListContentStyled>
        <VehicleTypesList />
      </MainListContentStyled>
    </ListPageStyled>
  </VehicleTypesListLoader>
));
