export const colours = [
  "#0a0",
  "#f00",
  "#a0f",
  "#5aa",
  "#5af",
  "#aaf",
  "#aa0",
  "#a5f",
  "#af0",
  "#f0a",
  "#0f5",
  "#f50",
  "#fa5",
  "#a5a",
  "#0a5",
  "#f5a",
  "#55f",
  "#696",
  "#faf",
  "#0ff",
  "#0aa",
  "#0f0",
  "#a50",
  "#0fa",
  "#0af",
  "#f0f",
  "#05f",
  "#aff",
  "#5a0",
  "#a55",
  "#faa",
  "#fa0",
  "#af5",
  "#aaa",
];
