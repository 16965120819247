import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { FMSLogger } from "FMSLogger";
import { Modals } from "Components/Modals";
import { Warning } from "Components/Warning/Warning";
import { NavigationBar } from "Components/NavigationBar/NavigationBar";
import { LocalStorageCache } from "Components/LocalStorageCache";
import { useResetEntitiesOnLogOut } from "Utils/hooks";
import { TimeoutWorker } from "Utils/timeoutWorker";
import { TIMEOUT_LOGS } from "Utils/constants";
import { refreshTokenAction, getExpirationDate } from "Slices/appState";

import { AuthorizedAppLoader } from "./AuthorizedApp.loader";
import { AuthorizedAppGuard } from "./AuthorizedApp.guard";
import { AppStyled } from "./styles";
import { WebsocketLoaderController } from "Pages/AuthorizedApp/WebsocketLoaderController";

const timeoutLogger = FMSLogger.byPrefix(TIMEOUT_LOGS);

const REFRESH_TIME_DELTA = 10000;
let timer: number | undefined = undefined;
let timeoutWorker: TimeoutWorker;

export const AuthorizedApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const expirationDate = useSelector(getExpirationDate);

  useLayoutEffect(() => {
    timeoutWorker = new TimeoutWorker();
    return () => {
      timeoutWorker?.destroy();
    };
  }, []);

  useEffect(() => {
    if (expirationDate) {
      //update the token 10 seconds before it expires
      const now = Date.now();
      const timeToRefreshToken = expirationDate - now - REFRESH_TIME_DELTA;
      timeoutLogger.info("set new timeout for refresh", {
        expirationDate,
        now,
        timeToRefreshToken,
      });

      timer = timeoutWorker.setTimeout(() => {
        dispatch(refreshTokenAction(() => navigate("/login")));
      }, timeToRefreshToken);
    }

    return () => {
      timeoutWorker.clearTimeout(timer as number);
    };
  }, [expirationDate]);

  useResetEntitiesOnLogOut();

  return (
    <>
      <LocalStorageCache />
      <AppStyled>
        <NavigationBar />
        <Modals />
        <Warning />
        <AuthorizedAppLoader>
          <AuthorizedAppGuard>
            <WebsocketLoaderController>
              <Outlet />
            </WebsocketLoaderController>
          </AuthorizedAppGuard>
        </AuthorizedAppLoader>
      </AppStyled>
    </>
  );
};
