import { Level, Prefix, PrefixData } from "./FMSLogger";
import { LoggedMessage } from "./PrefixedLogger";
import { getPrefixToColor } from "./utils";
import { LEVELS } from "./LogExecutor";

export class ConsoleTransport {
  private readonly prefixToColor: (prefix: Prefix) => string;
  constructor() {
    this.prefixToColor = getPrefixToColor();
  }
  private isLevelAllowed(level: Level, currentLevel: Level) {
    return LEVELS[level] >= LEVELS[currentLevel];
  }

  private isPrefixAllowed(prefix: Prefix, currentPrefixData: PrefixData) {
    if (currentPrefixData === null) {
      return true;
    } else if (Array.isArray(currentPrefixData)) {
      return currentPrefixData.includes(prefix);
    } else {
      return currentPrefixData === prefix;
    }
  }

  parseMessages(messages: LoggedMessage[]) {
    return messages.map((message, index) => {
      const newLineSymbol = index === 0 ? "" : "\n";
      const logMessage =
        typeof message === "object"
          ? JSON.stringify(message, null, 1)
          : message;

      return `${newLineSymbol}${logMessage}`;
    });
  }

  log(
    prefix: Prefix,
    level: Level,
    messages: LoggedMessage[],
    currentPrefixData: PrefixData,
    currentLevel: Level
  ) {
    if (!this.isPrefixAllowed(prefix, currentPrefixData)) return;

    if (!this.isLevelAllowed(level, currentLevel)) return;

    console[level](
      `${new Date().toISOString()} %c[${prefix}]`,
      `color: ${this.prefixToColor(prefix)}`,
      ...this.parseMessages(messages)
    );
  }
}
