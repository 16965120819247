import React, { memo } from "react";
import { CheckboxBtnStyled, CheckboxStyled } from "./styles";

interface CheckboxProps<T> {
  checkboxOptions: Array<T>;
  checkboxValue: T;
  changeCheckboxAction: (value: T) => void;
}

type CheckboxComponent = <T extends string>(
  props: CheckboxProps<T>
) => JSX.Element | null;

export const Checkbox: CheckboxComponent = memo(
  ({ checkboxOptions, checkboxValue, changeCheckboxAction }) => (
    <CheckboxStyled>
      {checkboxOptions.map((value) => (
        <CheckboxBtnStyled
          key={value}
          isActive={checkboxValue === value}
          onClick={() => changeCheckboxAction(value)}
        >
          {value}
        </CheckboxBtnStyled>
      ))}
    </CheckboxStyled>
  )
);
