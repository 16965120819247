import {
  memo,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFMSWebsocket } from "Utils/webSocketUtils";
import { getLoadedStationId } from "Slices/operatorStation";
import { getCurrentSemanticMapId } from "Slices/appState";
import {
  getMissionOperatorFleetIdForSubscribe,
  getMissionOperatorPickedFleetId,
  getOperatorMissionStatus,
  resetMissionOperatorData,
  setInitialMissionOperatorData,
  updateMissionOperatorData,
} from "Slices/operatorData";
import {
  MISSION_OPERATOR_WS,
  VEHICLE_STATE_WS,
  WS_SPINNER_LOGS,
} from "Utils/constants";
import { setVehiclesStateData, updateVehicle } from "Slices/vehiclesState";
import { WebsocketLoaderContext } from "Pages/AuthorizedApp/WebsocketLoaderController";
import { FMSLogger } from "FMSLogger";

const wsSpinnerLogs = FMSLogger.byPrefix(`${WS_SPINNER_LOGS}:MissionOperator`);

const UPDATING_FIELDS = [
  "t_long",
  "t_lat",
  "t_drive_mode",
  "t_web_status",
  "t_virtual_bumper_status",
  "t_vehicle_status",
  "last_contact",
];

export const MissionOperatorController = memo(() => {
  const dispatch = useDispatch();

  const setIsWSConnecting = useContext(WebsocketLoaderContext);

  useLayoutEffect(
    () => () => {
      wsSpinnerLogs.debug("unmount component spinner off");
      setIsWSConnecting?.(false);
    },
    []
  );

  const [isMapWSConnecting, setIsMapWSConnecting] = useState(false);
  const [isMissionWSConnecting, setIsMissionWSConnecting] = useState(false);

  useEffect(() => {
    if (isMapWSConnecting || isMissionWSConnecting) {
      wsSpinnerLogs.debug(
        "isMapWSConnecting or isMissionWSConnecting spinner on"
      );
      setIsWSConnecting?.(true);
    } else {
      wsSpinnerLogs.debug(
        "no isMapWSConnecting and no isMissionWSConnecting spinner off"
      );
      setIsWSConnecting?.(false);
    }
  }, [isMapWSConnecting, isMissionWSConnecting]);

  const stationId = useSelector(getLoadedStationId);
  const fleetIdForSubscribe = useSelector(
    getMissionOperatorFleetIdForSubscribe
  );
  const pickedFleetId = useSelector(getMissionOperatorPickedFleetId);
  const operatorMissionStatus = useSelector(getOperatorMissionStatus);
  const currentSemanticMapId = useSelector(getCurrentSemanticMapId);

  useEffect(() => {
    setIsMapWSConnecting(true);
    if (!currentSemanticMapId) return;

    dispatch(resetMissionOperatorData());
  }, [currentSemanticMapId]);

  const fleetId = useMemo(() => {
    if (!operatorMissionStatus) return null;

    return operatorMissionStatus === "NO_MISSION"
      ? pickedFleetId
      : fleetIdForSubscribe || pickedFleetId;
  }, [fleetIdForSubscribe, operatorMissionStatus, pickedFleetId]);

  const subscriptionData = useMemo(() => {
    if (!stationId && !fleetId) return null;
    setIsMissionWSConnecting(true);

    return {
      ...(stationId ? { stationId: stationId } : {}),
      ...(fleetId ? { fleetIds: [fleetId] } : {}),
    };
  }, [stationId, fleetId]);

  useFMSWebsocket({
    url: "/fleets/mission_queue/",
    subscriptionData: subscriptionData,
    loggerFlag: MISSION_OPERATOR_WS,
    getDataForSubscribe: ({ fleetIds, stationId }) => ({
      ...(fleetIds ? { fleet_ids: fleetIds } : {}),
      ...(stationId ? { missionOperatorStationId: stationId } : {}),
    }),
    getDataForUnsubscribe: ({ fleetIds, stationId }) => ({
      ...(fleetIds ? { fleet_ids: fleetIds } : {}),
      ...(stationId ? { missionOperatorStationId: stationId } : {}),
    }),
    handleInitialMessage: (data) => {
      setIsMissionWSConnecting(false);
      dispatch(setInitialMissionOperatorData(data));
    },
    handleMessage: (data) => {
      const shouldIgnoreUpdate = data?.state === "STATE_RUNNING";
      if (shouldIgnoreUpdate) return;

      dispatch(updateMissionOperatorData(data));
    },
  });

  useFMSWebsocket({
    url: "/vehicles/state/",
    subscriptionData: currentSemanticMapId,
    loggerFlag: VEHICLE_STATE_WS,
    getDataForSubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
      fields: UPDATING_FIELDS,
    }),
    getDataForUnsubscribe: (subscriptionId) => ({
      semantic_map_ids: [subscriptionId],
    }),
    handleInitialMessage: (data) => {
      setIsMapWSConnecting(false);
      dispatch(setVehiclesStateData(data));
    },
    handleMessage: (data: VehicleState) => {
      dispatch(updateVehicle(data));
    },
  });

  return null;
});
