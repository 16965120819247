import styled from "styled-components";
import { ObjectKeysType } from "./index";

export const GamepadSettingsModalStyled = styled.div`
  position: relative;
  width: 400px;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #ffffff;
  box-sizing: border-box;
  color: #0e152f;
  z-index: 1;
  border-radius: 4px;
  padding: 16px 40px;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
`;

export const GamepadSettingsModalHeaderStyled = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #0e152f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const GamepadSettingsInfoStyled = styled.div`
  display: flex;
  font-weight: 400;
  height: 48px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  box-sizing: border-box;
  color: #0e152f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GamepadSettingsLineStyled = styled.div`
  display: flex;
  font-weight: 400;
  height: 40px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: #0e152f;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-weight: bold;
  }
`;

export const GamepadSettingsButtonsContainerStyled = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const COLORS_BY_TYPE: ObjectKeysType = {
  notSet: "rgb(205,42,42)",
  disabled: "#C4C4C4",
  pending: "#e8d319",
};

interface GamepadSettingsButtonProps {
  currentState: string;
}

export const GamepadSettingsButtonStyled = styled.div<GamepadSettingsButtonProps>`
  display: flex;
  width: 112px;
  height: 32px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  border: 4px solid;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ currentState }) =>
    currentState === "disabled" ? "default" : "pointer"};
  transition: opacity 0.3s;
  border-color: ${({ currentState }) =>
    COLORS_BY_TYPE[currentState] || "rgb(7,165,7)"};
  color: ${({ currentState }) =>
    COLORS_BY_TYPE[currentState] || "rgb(7,165,7)"}};

  &:hover {
    opacity: 0.8;
  }
`;
