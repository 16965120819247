import React, { useEffect, useMemo, useState } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { forgotPass } from "Slices/auth";
import { LoginComponent } from "Components/Authentication/Login.component";
import { ForgotPasswordComponent } from "Components/Authentication/ForgotPassword.component";

import { AuthenticationContainer, LoggedInNotify } from "./styles";

export const AuthenticationPage = () => {
  const dispatch = useDispatch();

  const [showLoggedInNotify, setShowLoggedInNotify] = useState(false);

  useEffect(() => {
    const handleNewJWT = (event: StorageEvent) => {
      if (event.key === "JWT" && event.oldValue === null && event.newValue) {
        setShowLoggedInNotify(true);
      }
    };
    window.addEventListener("storage", handleNewJWT);
    return () => {
      window.removeEventListener("storage", handleNewJWT);
    };
  }, []);

  const forgotPassAction = (data: { email: string; callback: () => void }) => {
    dispatch(forgotPass(data));
  };

  const [authStage, setAuthStage] = useState("INIT");

  const handleCancel = () => {
    setAuthStage("INIT");
  };

  const handleForgotPass = () => {
    setAuthStage("FORGOT");
  };

  const authenticationContent = useMemo(() => {
    const isLoggedIn = Boolean(window.localStorage.getItem("JWT"));
    if (isLoggedIn) {
      return <Navigate to="/" replace={true} />;
    } else if (authStage === "INIT") {
      return <LoginComponent onForgotSelected={handleForgotPass} />;
    } else if (authStage === "FORGOT") {
      return (
        <ForgotPasswordComponent
          onSubmit={forgotPassAction}
          onCancel={handleCancel}
        />
      );
    } else return null;
  }, [authStage]);

  return (
    <AuthenticationContainer>
      {showLoggedInNotify && (
        <LoggedInNotify>
          You signed in with another tab or window. Reload page to refresh your
          session
        </LoggedInNotify>
      )}
      {authenticationContent}
    </AuthenticationContainer>
  );
};
