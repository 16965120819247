import { createAsyncThunk } from "@reduxjs/toolkit";
import { updateVehiclesStateData } from ".";
import { RootState } from "../..";

export const updateVehicle = createAsyncThunk(
  "vehiclesState/updateVehicle",
  async (vehicleState: VehicleState, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const currentData = { ...state.vehiclesState.data };
    const { vehicle_id } = vehicleState;

    if (vehicle_id) {
      currentData[vehicle_id] = vehicleState;
      thunkAPI.dispatch(updateVehiclesStateData(currentData));
    }
  }
);
